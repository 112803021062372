<app-form-error-summary [controls]="controls">
    <app-form-error [control]="controls?.subscriber" name='subscriber' type='required'>
        Subscriber / Customer Number is required.
    </app-form-error>
</app-form-error-summary>
<div class="subscriber-number-credentials">
    <label for="subscriber">Subscriber / Customer Number</label>
    <input name="subscriber" id="subscriber" type="text" [ngrxFormControlState]="controls.subscriber"
        placeholder="Subscriber / Customer Number" [tabIndex]="2" />
    <app-form-error [control]="controls?.subscriber" name='subscriber' type='required'>
        Subscriber / Customer Number is required.
    </app-form-error>
</div>