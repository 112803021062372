<header role="banner">
  <div
    #container
    id="header-wrapper"
    [ngClass]="{'no-menu': !(showMenu$ | async), 'app-header': !(isImproveUserNavigation$ | async), 'app-header-iun': (isImproveUserNavigation$ | async)}">
    <ng-container *ngIf="(canCancel$ | async) && !(hasAvailableCall$ | async)">
      <app-back-button></app-back-button>
    </ng-container>
    <div class="full-logo"
      *ngIf="showMenu$ | async"
      [ngClass]="{
      'left-aligned-logo': (canCancel$ | async) && !(isImproveUserNavigation$ | async),
      'center-aligned-logo': (canCancel$ | async) && (isImproveUserNavigation$ | async)
      }">
      <app-img
        *ngIf="showLogo && !isCancelExpanded"
        src="assets/images/{{appIdLogo.fileName}}.png"
        classNames="{{appIdLogo.className}}"
        alt="{{appIdLogo.alt}}"
        id="{{appIdLogo.className}}"
        width="{{appIdLogo.width}}"
        height="{{appIdLogo.height}}"
      ></app-img>
      <span class="roadside-request show-desktop">Roadside Request</span>
    </div>
    <app-button-cancel-request
      tabindex="{{ (canCancel$ | async) ? 999 : -1 }}"
      [attr.aria-hidden]="!(canCancel$ | async)"
      (cancelExpanded)="cancelExpanded($event)"
    ></app-button-cancel-request>
  </div>
</header>
