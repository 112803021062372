<div class="dialog not-a-member">
  <h1 mat-dialog-title>Not a Member?</h1>

  <div mat-dialog-content>
    <h3>We can still help.</h3>
    <p>
      Give us a call, and we’ll connect you to a customer service
      representative.
    </p>
  </div>
  <div class="not-a-member_secondary-content">
    <app-call-aaa-button
      (buttonClick)="onClose()">
    </app-call-aaa-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
