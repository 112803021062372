import { Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { selectIsRapEligible, selectIsSignedIn } from 'src/app/modules/auth/auth.selectors'
import { selectHasAvailableCall } from 'src/app/modules/dashboard/calls-statuses/call-status.selectors'
import { StepTypes } from 'src/app/modules/ui/ui.types'
import { AAAStore } from 'src/app/store/root-reducer'
import { selectIsEligible } from '../../modules/member/member.selectors';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService {
  constructor(private store$: Store<AAAStore>, private router: Router) {}

  auth$: Observable<[boolean, boolean, boolean, boolean]> = this.store$.pipe(
    select(selectIsSignedIn),
    withLatestFrom(
      this.store$.pipe(select(selectIsEligible)),
      this.store$.pipe(select(selectIsRapEligible)),
      this.store$.pipe(select(selectHasAvailableCall)),
    )
  )

  hasAvailableCall$: Observable<boolean> = this.store$.pipe(
    select(selectHasAvailableCall)
  )

  isPathAllowed(): Observable<boolean> {
    return this.auth$.pipe(
      map(([isSignedIn, isEligible, isRapEligible, hasActiveCall]) => hasActiveCall || isSignedIn && (isEligible || isRapEligible))
    )
  }

  redirectToLogin(queryParams: Params = {}) {
    this.router.navigate(['auth', 'signin'], { queryParams })
  }

  redirectToFirstStep(queryParams: Params = {}) {
    this.router.navigate(['steps'], {
      queryParams: { ...queryParams, step: StepTypes.BREAKDOWN_LOCATION },
      replaceUrl: true,
    })
  }

  redirectToServiceTracker() {
    return this.hasAvailableCall$.pipe(
      map((hasAvailableCall) =>
        hasAvailableCall
          ? this.router.navigate(['dashboard'])
          : this.router.navigate(['steps'])
      )
    )
  }
}
