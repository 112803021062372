export enum EVENT_TYPES {
  DRR = 'DRR',
  DRRRAP = 'DRRRAP',
}

export enum EVENT_ACTIONS {
  LINKCLICK = 'LINKCLICK',
  ITEMVIEW = 'ITEMVIEW',
  AUTOMATED = 'AUTOMATED',
}

export interface ExtraParams {
  action: string,
  pageType: string,
  params?: object,
  rapAppId?: string,
  channel?: string,
}

export interface GoogleAnalyticsParams extends ExtraParams {
  eventAction: EVENT_ACTIONS,
  eventType: EVENT_TYPES,
  category: string,
  appId: string,
  subCategory: EVENT_TYPES,
  'Event Detail': string,
}

export enum CTAEventTypes {
  CLICK = 'cta_click'
}

export interface CTAValue {
  cta_value: string
}
export interface AdobeAnalyticsParams {
  event: CTAEventTypes,
  cta_click?: CTAValue,
}

export enum HotjarHitTypes {
  EVENT = 'event',
  IDENTIFY = 'identify'
}

export interface HotjarParams {
  eventActionOrSessionId: string,
  hitType?: HotjarHitTypes,
  membershipNumber?: string,
}
