import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'
import { TruckPinTypes } from 'src/app/modules/ui/ui.types'

@Component({
  selector: 'icon-truck-location-pin',
  templateUrl: './truck-location-pin.icon.html',
  styleUrls: ['truck-location-pin.icon.scss']
})
export class TruckLocationPinIconComponent extends AbstractIconComponent {
  @Input() type: TruckPinTypes = TruckPinTypes.DEFAULT
}
