<fieldset>
  <legend> Membership </legend>

<section class="minimal-credentials no-shadow">
  <ng-container *ngIf="(isMobileNumberAuth$ | async); else defaultForm">
    <div class="name-auth">
      <div>
        <label for="firstName"
               id="membership-name">First Name</label>
        <input name="firstName"
               id="firstName"
               type="text"
               [ngrxFormControlState]="controls.firstName"
               placeholder="Enter Your First Name"
               aria-required="true"
               class="first-focusable-element"
               autocomplete="given-name"
               required/>

        <app-form-error [control]="controls?.firstName"
                        name='firstName'
                        type='required'> First Name is required.
        </app-form-error>
      </div>
      <div>
        <label id="last-name-label"
               for="lastName">Last Name</label>
        <input name="lastName"
               id="lastName"
               type="text"
               [ngrxFormControlState]="controls.lastName"
               placeholder="Enter Your Last Name"
               aria-required="true"
               autocomplete="family-name"
               required/>

        <app-form-error [control]="controls?.lastName"
                        name='lastName'
                        type='required'> Last Name is required.
        </app-form-error>
      </div>
    </div>
  </ng-container>
  <ng-template #defaultForm>
    <div class="minimal-credentials_membership-line"
         role="navigation"
         aria-labelledby="membership-name">
      <label for="firstName"
             id="membership-name">First Name</label>
      <aria-link-button elementId="use-member-number"
                        class="minimal-credentials_no-membership color-black  link-use-member-number"
                        (clickLink)="setUseMembershipNumber($event)"
                        *ngIf="!(isMobileNumberAuth$ | async)">
        Use Member Number
      </aria-link-button>
    </div>
    <input name="firstName"
           id="firstName"
           type="text"
           [ngrxFormControlState]="controls.firstName"
           placeholder="Enter Your First Name"
           class="first-focusable-element"
           aria-required="true"
           autocomplete="given-name"
           required/>

    <app-form-error [control]="controls?.firstName"
                    name='firstName'
                    type='required'> First Name is required.
    </app-form-error>

    <label id="last-name-label"
           for="lastName">Last Name</label>
    <input name="lastName"
           id="lastName"
           type="text"
           [ngrxFormControlState]="controls.lastName"
           placeholder="Enter Your Last Name"
           aria-required="true"
           autocomplete="family-name"
           required/>

    <app-form-error [control]="controls?.lastName"
                    name='lastName'
                    type='required'> Last Name is required.
    </app-form-error>
  </ng-template>

  <div class="minimal-credentials_postal-code-line">
    <label for="zipCode">Home Zip Code</label>
    <input name="zipCode"
           id="zipCode"
           type="tel"
           placeholder="Enter Your Zip Code"
           minLength="5"
           [textMask]="{ mask: zipCodeMask, keepCharPositions: true }"
           [ngrxFormControlState]="controls.zipCode"
           aria-required="true"
           autocomplete="postal-code"
           required/>

    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='required'> Zip Code is required.
    </app-form-error>
    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='minLength'> Zip Code must be 5 characters long.
    </app-form-error>
    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='maxLength'> Zip Code must be 5 characters long.
    </app-form-error>
  </div>
</section>
</fieldset>
