import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { cancelRequest } from '../../actions/shared.actions'
import { Store, select } from '@ngrx/store'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import { Observable, combineLatest } from 'rxjs'
import { selectIsSignedIn } from 'src/app/modules/auth/auth.selectors'
import { selectRouterStep } from 'src/app/store/router.selectors'
import { map } from 'rxjs/operators'
import { ADD_CALL, CALL_CANCEL } from 'src/app/modules/dashboard/calls.actions'
import { selectIsLoading } from 'src/app/modules/ui/loading/loading.selectors'
import {
  selectCanCancelActiveCall,
  selectHasAvailableCall
} from 'src/app/modules/dashboard/calls-statuses/call-status.selectors'
import events from 'src/app/modules/tagging/events'
import { AbstractResponsiveComponent } from '../../abstract-responsive.component'
import { MessageDialogTypes, PromptDialogTypes } from '../../../modules/ui/ui.types'
import { openMessageDialog, openPromptDialog } from 'src/app/modules/ui/ui.actions'
import { selectIsStepCancelable } from '../../../modules/ui/ui.selectors'
import { RapService } from '../../../modules/rap/rap.service'

@Component({
  selector: 'app-button-cancel-request',
  templateUrl: './cancel-request.component.html',
  styleUrls: ['./cancel-request.component.scss'],
})
export class CancelRequestButtonComponent extends AbstractResponsiveComponent implements OnInit {
  constructor(
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
    private rapService: RapService,
  ) {
    super()
  }

  @Input() bottom: Boolean = false
  @Input() tabIndex = 0
  @Input() defaultColor = false

  @Output() cancelExpanded: EventEmitter<Boolean> = new EventEmitter<Boolean>()

  cancelling = false
  isSignedIn$: Observable<boolean> = this.store$.pipe(select(selectIsSignedIn))

  loading$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(CALL_CANCEL.ACTION))
  )

  isCallRequesting$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(ADD_CALL.ACTION))
  )

  hasAvailableCall = false
  hasAvailableCall$: Observable<boolean> = this.store$.pipe(
    select(selectHasAvailableCall)
  )

  // TODO: Pending refactor.
  // Remove observable from header.ts, separate Call AAA button from cancel button,
  // create a control/options.ts component that wraps both. Only use cancel for header,
  // options with call AAA + cancel for mobile/tablet.
  canCancel$: Observable<boolean> = combineLatest([
    this.isSignedIn$,
    this.store$.select(selectRouterStep),
    this.store$.select(selectCanCancelActiveCall),
    this.store$.select(selectIsStepCancelable)
  ]).pipe(
    map(([isSignedIn, step, canCancelActiveCall, isStepCancelable]) => {
      if (!isSignedIn) {
        return false
      }
      if (step) {
        return isStepCancelable
      } else {
        return canCancelActiveCall
      }
    })
  )

  ngOnInit(): void {
    this.subscriptions.push(
      this.hasAvailableCall$.subscribe((hasCall) => {
        this.hasAvailableCall = hasCall
      })
    )
  }

  // Trigger button click action
  cancelCall() {
    this.cancelling = true
    this.cancelExpanded.emit(this.cancelling)
  }

  confirmCancel() {
    this.store$.dispatch(cancelRequest())
    this.cancelling = false
    this.cancelExpanded.emit(this.cancelling)
    this.tagging.setClickEvent(
      this.hasAvailableCall ? events.dashboard.CALL_CANCEL_REQUEST : events.dashboard.PARTIAL_CALL_CANCELED,
      events.dashboard.CALL_STATUS_PAGE_TYPE
    )

    if (this.hasAvailableCall) {
      return
    }
    if (this.rapService.isRapUser()) {
      this.store$.dispatch(openMessageDialog({
        payload: {
          type: MessageDialogTypes.PARTIAL_CALL_CANCELED
        }
      }))
    } else {
      this.store$.dispatch(openPromptDialog({
        payload: {
          type: PromptDialogTypes.CANCEL_FEEDBACK_PROMPT,
          params: { hasAvailableCall: this.hasAvailableCall }
        }
      }))
    }
  }

  cancelCancel() {
    this.cancelling = false
    this.cancelExpanded.emit(this.cancelling)
  }

  tagEvent() {
    this.tagging.setClickEvent(
      events.shared.CALL_AAA_CLICK,
      events.shared.MENU_PAGE_TYPE
    )
  }

  cancelRequestLabel() {
    return this.isMobile ? 'Cancel' : 'Cancel Request'
  }
}
