import {Injectable} from '@angular/core';
import { isMobileApp } from '../../../shared/utils/app-detect';
import { isSafari } from '../../../shared/utils/browser-detect';

const MEMBER_NAME = 'MEMBER NAME';
const MEMBER_NUMBER = 'MEMBER NUMBER';

@Injectable({
  providedIn: 'root'
})
export class CredentialsManagementService {
  credentialsManagementApi
  constructor() {
    if (!isMobileApp()
      && !isSafari()
      && 'credentials' in navigator
      && typeof PasswordCredential === 'function') {

      this.credentialsManagementApi = navigator.credentials
    }
  }

  isSupported = (): boolean => Boolean(typeof PasswordCredential === 'function');

  storeCredentials(credentials: any) {
    if (!this.credentialsManagementApi) {
      console.error('Credential Management API is not supported');
      return
    }
    const credential = new PasswordCredential({
      type: 'password',
      name: credentials.firstName ? MEMBER_NAME: MEMBER_NUMBER,
      id: credentials.firstName ?
        `${credentials.firstName}@${credentials.lastName}@${credentials.zipCode}` :
        `${credentials.memberNumber}@${credentials.zipCode}`,
      password: credentials.zipCode,
    });
    this.credentialsManagementApi.store(credential)
  }

  getCredentials(): Promise<any> {
    if (!this.credentialsManagementApi) {
      console.error('Credential Management API is not supported');
      return
    }
    return this.credentialsManagementApi.get({password: true})
      .then((credential: any) => {
        if (credential) {
          if (credential.name === MEMBER_NAME) {
            const [firstName, lastName, zipCode] = credential.id.split('@')
            return {
              firstName,
              lastName,
              zipCode
            }
          } else if (credential.name === MEMBER_NUMBER) {
            const [memberNumber, zipCode] = credential.id.split('@')
            return {
              memberNumber,
              zipCode
            }
          }
        }
      })
  }

}
