<ng-container *ngIf="location">
  <ng-container *ngIf="showAaaDiscount && type === 'full'">
    <button
      type="button"
      class="borderless-button aar-discount"
      (click)="handleSummaryClick($event)"
      [tabIndex]="tabIndex"
    >
      <ng-template [ngTemplateOutlet]="aaaDiscount">
      </ng-template>
    </button>
  </ng-container>
  <button
    type="button"
    class="borderless-button shop-details"
    [ngClass]="{'borderless-button-summary': type === 'summary'}"
    (click)="handleSummaryClick($event)"
    [tabIndex]="tabIndex"
  >
    <div style="display: flex; flex-direction: row; width: 100%;">
      <div class="aar-discount-summary" *ngIf="showAaaDiscount && type === 'summary'">
        <ng-template [ngTemplateOutlet]="aaaDiscount">
        </ng-template>
      </div>
      <div style="width: 100%;">
        <div class="name-address">
          <div class="normal-text bold">{{ location.name }}</div>
        </div>

        <ng-container *ngIf="type === 'full' && location.ratingSummary?.reviewCount > 0">
          <div class="rating-summary">
            <span class="small-text">{{ location.ratingSummary.ratingAvg | number:'1.0-1' }}</span>
            <div class="rating">
              <app-rating [rate]="location.ratingSummary.ratingAvg"></app-rating>
            </div>
            <span class="small-text">({{ location.ratingSummary.reviewCount }})️</span>
          </div>
        </ng-container>

        <ng-container *ngIf="type === 'full' && location['priority']">
          <div class="available">
            <div class="small-text">Immediately Available</div>
          </div>
        </ng-container>

        <div class="address-distance">
          <div class="line1">
            {{location.address?.addressLine}}
          </div>
          <div class="line2-distance">
            <div class="line2">
              {{location.address?.cityName}}, {{location.address?.stateProv.code}} {{location.address?.postalCode}}
            </div>
            <div class="small-text distance">{{ location.distanceTo | number:'1.0-1' }} miles away</div>
          </div>
        </div>

        <icon-close-rounded
          class="clear-icon"
          *ngIf="closable"
          (click)="handleClearSelectedShop()">
        </icon-close-rounded>
      </div>
    </div>
  </button>
</ng-container>

<ng-template #aaaDiscount>
  <ng-container *ngIf="showAaaDiscount">
    <ng-container *ngIf="type === 'full' && location.ratingSummary?.reviewCount > 0">
      <app-img
        src="assets/images/logo.png"
        classNames="aaa-logo"
        elementId="aaa-logo-promo"
        alt="Approved Auto Repair">
      </app-img>
    </ng-container>
    <div class="member-discount">
      <icon-member-discount></icon-member-discount>
      <div>
        Member
        <br/>
        Discount
        <br/>
        Available
      </div>
    </div>
  </ng-container>
</ng-template>
