import { array, object, string, boolean, number } from 'yup'
import {
  AAACallStatus,
  AAACallStatuses,
  CallResponse,
  ClubSupport,
  ServiceDeliveryCallStatus,
  ServiceDeliveryCallStatusHistory,
  ServiceDeliveryPhone,
} from './calls.types'
import { DriverImage, DriverInfo } from './calls-statuses/call-status.types'

export const CallResponseSchema = object<CallResponse>().shape({
  callId: string(),
  callDate: string(),
  servicingClub: string(),
  callStatus: string(),
  callToken: string(),
  updateToken: string(),
  trackToken: string(),
  pta: string(),
  ptaInLocalTime: string(),
})

const CallDetailsSchema = object<AAACallStatus>().shape({
  callId: string().required('CALL ID not found in CallDetails'),
  callDate: string().required('CALL DATE not found in CallDetails'),
  callStatus: string().required('CALL STATUS not found in CallDetails'),
  servicingClub: string(),
  callToken: string(),
  updateToken: string(),
  trackToken: string(),
  pta: string(),
  pacesetterCode: string(),
  serviceProvider: string(),
  vehicle: object({
    year: number(),
    make: string(),
    model: string(),
    color: string(),
  }),
  breakdownLocation: object({
    location: string(),
    locationDescription: string(),
    streetNumber: string(),
    streetName: string(),
    crossStreetName: string(),
    city: string(),
    state: string(),
    postalCode: string(),
    latitude: number(),
    longitude: number(),
  }),
  towDestination: object({
    location: string(),
    latitude: number(),
    longitude: number(),
    streetNumber: string(),
    streetName: string(),
    city: string(),
    state: string(),
  }),
  driverData: object({
    id: string(),
    facilityId: string(),
    name: string(),
    code: string(),
    latitude: number(),
    longitude: number(),
    eta: string(),
    gpsAccuracy: number(),
    gpsTimeTime: string(),
  }),
  tracking: object({
    supported: boolean(),
    provider: string(),
    url: string(),
  }),
})

const CallHistorySchema = object<AAACallStatus>().shape({
  callId: string().required('CALL ID not found in CallHistory'),
  callDate: string().required('CALL DATE not found in CallHistory'),
  callStatus: string().required('CALL STATUS not found in CallHistory'),
  servicingClub: string(),
})

export const CallStatusesSchema = object<AAACallStatuses>().shape({
  callStatuses: array().of(CallDetailsSchema),
  callHistory: array().of(CallHistorySchema),
})

export const ClubSupportSchema = object<ClubSupport>().shape({
  supported: boolean().required(),
})

export const DriverImageSchema = object<DriverImage>().shape({
  width: number(),
  height: number(),
  url: string(),
})

export const DriverInfoSchema = object<DriverInfo>().shape({
  firstName: string().required(),
  lastName: string().required(),
  initial: string().required(),
  driverId: string(),
  facilityName: string(),
  facilityPhone: string(),
  images: array().of(DriverImageSchema),
})

export const PhoneSchema = object<ServiceDeliveryPhone>().shape({
  number: string().required(),
  primary: boolean().required(),
  sms: boolean().required(),
})

export const CallStatusHistorySchema =
  object<ServiceDeliveryCallStatusHistory>().shape({
    timestamp: string().required(),
    stat: string().required(),
    updateStat: string().required(),
    reason: string().required(),
    facility: string().nullable(),
  })

export const CallStatusSchema = object<ServiceDeliveryCallStatus>()
  .shape({
    callDate: string().required(),
    callId: number().required(),
    pta: string().nullable(),
    phone: array().of(PhoneSchema).nullable(),
    callStatusHist: array().of(CallStatusHistorySchema).nullable(),
  })
  .nullable()
