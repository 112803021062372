<div class="what-happened_primary-title">
  <div class="pre-title-wrapper">
    <ng-container *ngIf="((memberData$ | async)?.firstName || (eligibility$ | async)?.contractIdentityData.contact?.firstName) as firstName">
      <div class="member-accordion">
        <mat-accordion tabindex="100">
          <mat-expansion-panel class="mat-elevation-z0"
                               [ngClass]="{'darker': darkerMemberBg}"
                               (opened)="darkerMemberBg=true"
                               (closed)="darkerMemberBg=false" 
                               [disabled]="(eligibility$ | async)">
            <mat-expansion-panel-header>
              <mat-panel-description>
                <h1 aria-level="1" class="members-name">Welcome, <b>{{firstName | titlecase}}</b>!</h1>
                <mat-icon *ngIf="!(eligibility$ | async)">account_circle</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="(memberData$ | async) as memberData">
              <div>
                <p class="membership-years" *ngIf="memberData?.joinDate">{{ _getMemberGreetings(memberData?.joinDate) }}</p>
                <p class="membership-type">Membership Type: {{getPlusIndicatorName(memberData.plusIndicator)}} </p>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</div>
