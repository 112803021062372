import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { SubscriberNumberAuthFormState } from 'src/app/modules/ui/forms/forms.reducer';
import { selectSubscriberNumberAuthForm } from 'src/app/modules/ui/forms/forms.selectors';
import { AAAStore } from 'src/app/store/root-reducer';
import { ValidationFormComponent } from '../../types';

@Component({
  selector: 'app-subscriber-number-credentials',
  templateUrl: './subscriber-number-credentials.component.html',
  styleUrls: ['./subscriber-number-credentials.component.scss']
})
export class SubscriberNumberCredentialsComponent implements ValidationFormComponent,OnInit {
  isSecure$: Observable<boolean>;

  constructor(private store$: Store<AAAStore>) {
  }

  subscriberNumberAuthForm: FormGroupState<SubscriberNumberAuthFormState>;
  subscriberNumberAuthForm$: Observable<FormGroupState<SubscriberNumberAuthFormState>> = this.store$.pipe(
    select(selectSubscriberNumberAuthForm)
  );

  get formState(): FormGroupState<any> {
    return this.subscriberNumberAuthForm
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  ngOnInit(): void {
    this.subscriberNumberAuthForm$.subscribe((subscriberNumberAuthForm) => {
      this.subscriberNumberAuthForm = subscriberNumberAuthForm;
    })
  }
}
