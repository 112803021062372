import { createAction, props } from '@ngrx/store'
import { createAsyncConstants } from 'src/app/shared/utils'
import { Advisory } from './advisories.types'

export const SET_ADVISORY_CLUB_SEEN = 'SET_ADVISORY_CLUB_SEEN'
export const RESET_ADVISORIES = 'RESET_ADVISORIES'
export const ADVISORIES = createAsyncConstants('ADVISORIES')

export const setAdvisoryClubSeen = createAction(SET_ADVISORY_CLUB_SEEN)

export const advisoriesRequest = createAction(
  ADVISORIES.REQUEST,
  props<{ payload: {
    association: string,
    club: string,
    zipcode?: string
  } }>()
)

export const advisoriesSuccess = createAction(
  ADVISORIES.SUCCESS,
  props<{
    payload: {
      advisories: Array<Advisory>,
      club: string
    }
  }>()
)

export const notifyAdvisoriesFailure = createAction(ADVISORIES.FAILURE)

export const resetAdvisories = createAction(RESET_ADVISORIES)
