import { Component, EventEmitter, Input, Output } from '@angular/core'
import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import events from '../../tagging/events'
import { AAR_LOAD } from '../aar/aar.actions'
import { Store, select } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { TaggingService } from '../../tagging/tagging.service'
import { selectSortedFacilitiesDisplay } from '../aar/aar.selectors'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { GoogleLocationMarker } from '../location.types'
import { FacilitiesDisplay } from '../aar/aar.types'
import { selectTowLocationPreviewAddress } from '../tow-location/tow-location.selectors'
import { isAddressComplete } from '../location.utils'

export const BANNER_TIMEOUT = 9000
export enum DiscountBannerText {
  WANT_DISCOUNT = '<strong>Want a discount?</strong> Select a AAA Approved Facility to receive <strong>Up to $75 Off Labor.</strong>',
  NO_FACILITIES = 'No Facilities Found Within a 2-mile Radius of your Destination.',
  FACILITIES_FOUND = '<strong>Awesome!</strong><br>Please select a AAA Facility below:'
}

export interface SeeFacilitiesBanner {
  text: DiscountBannerText,
  isLoading: boolean
  hasFacilities: boolean
  isSeeButtonVisible: boolean
}

function getText(
  facilitiesDisplay: FacilitiesDisplay,
  isLoading: boolean,
  isSeeClicked: boolean,
  isAddressIncomplete: boolean
) {
  let text = DiscountBannerText.WANT_DISCOUNT
  if (facilitiesDisplay.aarData.length > 0 && (isSeeClicked || isAddressIncomplete)) {
    text = DiscountBannerText.FACILITIES_FOUND
  } else if (facilitiesDisplay.aarData.length === 0 && isSeeClicked && !isLoading) {
    text = DiscountBannerText.NO_FACILITIES
  }

  return text
}

@Component({
  selector: 'app-tow-location-banner',
  templateUrl: './tow-location-banner.component.html',
  styleUrls: ['./tow-location-banner.component.scss']
})
export class TowLocationBannerComponent {

  @Input() towLocation: GoogleLocationMarker
  @Output() seeFacilitiesClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false)

  isBannerVisible = true

  facilitiesDisplay$ = this.store$.pipe(select(selectSortedFacilitiesDisplay))
  isLoading$ = this.store$.pipe(select(selectIsLoading(AAR_LOAD.ACTION)))
  isSeeFacilitiesClicked$ = new BehaviorSubject<boolean>(false)
  isAddressIncomplete$: Observable<boolean> = this.store$.pipe(
    select(selectTowLocationPreviewAddress),
    map((preview) => preview?.address && !isAddressComplete(preview))
  )

  seeFacilitiesBanner$: Observable<SeeFacilitiesBanner> = combineLatest([
    this.facilitiesDisplay$,
    this.isLoading$,
    this.isSeeFacilitiesClicked$,
    this.isAddressIncomplete$
  ])
    .pipe(
      map(([facilitiesDisplay, isLoading, isSeeClicked, isAddressIncomplete]) => {
        let text = getText(facilitiesDisplay, isLoading, isSeeClicked, isAddressIncomplete)
        const isSeeButtonVisible = text === DiscountBannerText.WANT_DISCOUNT
        this.isBannerVisible = !isLoading

        if (!isSeeButtonVisible) {
          setTimeout(() => this.isBannerVisible = false, BANNER_TIMEOUT)
        }

        return {
          hasFacilities: facilitiesDisplay.aarData.length > 0,
          isSeeButtonVisible,
          text,
          isLoading,
        }
      })
    )

  constructor(
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
  ) { }

  seeFacilities() {
    this.isSeeFacilitiesClicked$.next(true)
    this.seeFacilitiesClicked.emit(true)
    this.tagging.setClickEvent(
      events.towTo.DESTINATION_DISCOUNT_APPLIED,
      events.towTo.DESTINATION_PAGE_TYPE
    )
  }
}
