import { RouterNavigatedAction, RouterRequestAction } from '@ngrx/router-store'
import { Params } from '@angular/router'

interface DefaultParamObjectType {
  [id: string]: string
}

export const getURLQueryParams =
  (defaultObject: DefaultParamObjectType) => (params: Params) =>
    Object.keys(params).reduce(
      (result, paramKey) => ({
        ...result,
        [paramKey.toLowerCase()]: Array.isArray(params[paramKey])
          ? params[paramKey][0]
          : params[paramKey],
      }),
      defaultObject
    )

export const getRouterActionQueryParams = (
  action: RouterRequestAction | RouterNavigatedAction
): Params => (action ? action.payload.routerState.root.queryParams : null)
export const getRouterActionQueryParam = (
  action: RouterRequestAction | RouterNavigatedAction,
  queryParam: string
): String => {
  const params = getRouterActionQueryParams(action)
  return params ? params[queryParam] : null
}
