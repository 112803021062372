export const phoneMask = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const zipCodeMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const membershipMask = [
  /\d/,
  /\d/,
  /\d/,
  ' ',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  ' ',
  /\d/,
]
