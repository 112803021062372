<div class="dialog">
  <h1 mat-dialog-title>Alert</h1>

  <div class="dialog-content">
    <p class="text-message">{{message}} Please contact us so that we may assist you.</p>
    <div class="dialog-actions">
      <app-call-aaa-button
        [clickEventPage]="pageType">
      </app-call-aaa-button>
    </div>
  </div>

  <button mat-dialog-close class="mat-dialog-close" (click)="onClose()">
    <icon-close></icon-close>
  </button>
</div>
