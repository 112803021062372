<ng-container [ngSwitch]="promoView">
  <ng-container *ngSwitchCase="'DEFAULT'">
    <div class="promotion promotion_container">
      <div class="promotion_content">
        <span class="promotion_highlight">
          <span class="promotion_highlight_amount">
            10%
          </span>
          <span class="promotion_highlight_amount_details">
            off labor
            <span class="promotion_highlight_decorator">
              <icon-check></icon-check>
            </span>
          </span>
        </span>
        <span class="promotion_highlight_subtitle">
          For members at AAA Approved Facilities.
        </span>
      </div>
      <div class="promotion_logo">
        <app-img src="assets/images/logo.png"
          classNames="aaa-logo"
          elementId="aaa-logo-promo"
          alt="Approved Auto Repair">
        </app-img>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'DOLLAR'">
    <div class="promotion">
      <div class="promotion_content">
        <span class="promotion_highlight">
          <span class="promotion_highlight_text">
            Up to <br>
            <span class="promotion_highlight_amount">
              $75 off labor
            </span>
            <span class="promotion_highlight_decorator">
              <icon-check></icon-check>
            </span>
          </span>
        </span>
      </div>
      <div class="promotion_logo">
        <app-img src="assets/images/logo.png"
          classNames="aaa-logo"
          elementId="aaa-logo-promo"
          alt="Approved Auto Repair">
        </app-img>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'COUPON'">
    <div (click)="applyCoupon(true)" class="promotion dashed-border">
      <div class="promotion_header">
        <div class="promotion_content">
          <span class="promotion_highlight">
            <span class="promotion_highlight_text">
              Up to
            </span>
            <br>
            <span class="promotion_highlight_amount">
              $75
            </span>
            <span class="promotion_highlight_amount_details">
              off labor
            </span>
            <span class="promotion_highlight_decorator">
              <icon-check></icon-check>
            </span>
          </span>
        </div>
        <div class="promotion_logo">
          <app-img src="assets/images/logo.png"
            classNames="aaa-logo"
            elementId="aaa-logo-promo"
            alt="Approved Auto Repair">
          </app-img>
        </div>
      </div>
      <div class="promotion_highlight_disclaimer">
        AAA Members receive 10% off labor (up to $75) at AAA Repair facilities
      </div>
    </div>
    <div class="promotion_button">
      <ng-container *ngIf="!(isCouponApplied$ | async); else isCouponAppliedTemplate">
        <app-button
          (buttonClick)="applyCoupon(true)"
          [loading]="isLoading"
          [disabled]="isLoading"
          [color]="'primary-yellow'"
          type="button">
          <i class="fa-solid fa-tag left-icon-button"
            alt="Apply Coupon"></i>
          Apply Coupon
        </app-button>
      </ng-container>
      <ng-template #isCouponAppliedTemplate>
        <app-button
          class="promotion_button_coupon-applied"
          [disabled]="true"
          [color]="'primary-yellow'"
          type="button">
          <i class="fa-solid fa-check left-icon-button check"
            alt="Coupon Applied"></i>
          <i class="fa-solid fa-tag left-icon-button"
            alt="Apply Coupon"></i>
          Coupon Applied
        </app-button>
        <p class="promotion_highlight_text blue" >
          -- Select a shop below --
        </p>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
