<section
  id="credentials-entry"
  class="minimal-credentials"
  [ngClass]="{
    entry: !(isSecure$ | async),
    placeholder: isSecure$ | async
  }"
>

<!-- Adding second form: loginFailAssistant -->
<ng-container *ngIf="!(isMobileNumberAuth$ | async)">
  <form id="signon-form"
    (ngSubmit)="formState.isValid && handleSubmit()"
    class="minimal-credentials_form"
    [ngClass]="{'error': isErrorBoxDisplayed }"
    novalidate
    [ngrxFormState]="formState">
    <ng-container *ngIf="!(isSecure$ | async)">
      <ng-container *ngIf="allowNameAuth; else membershipLines">
        <app-member-name [controls]="controls" (useMembershipNumberEmitter)="useMembershipNumberClicked($event)"></app-member-name>
      </ng-container>

      <ng-template #membershipLines>
        <app-membership-number [controls]="controls" (useMemberNameEmitter)="useMemberNameClicked($event)"></app-membership-number>
      </ng-template>

      <ng-container *ngIf="isPasswordCredentialSupported()">
        <fieldset>
          <div class="checkboxes-container">
            <app-checkbox
              name="remember-me"
              (checkedChange)="handleRememberMe($event)"
              [checked]="rememberMe">
              Remember me
            </app-checkbox>
          </div>
        </fieldset>
      </ng-container>

      <app-login-fail-assistant (hasError)="setHasError($event)"></app-login-fail-assistant>

      <ng-container *ngIf="displayCaptcha">
        <ngx-recaptcha2
          #captchaElementSimple
          [siteKey]="siteKey"
          (ready)="handleCaptchaReady()"
          (success)="handleCaptchaSuccessSimple($event)"
          (error)="handleCaptchaError()"
          [useGlobalDomain]="false"
          type="checkbox"
          [ngModel]="captcha"
          [ngModelOptions]="{ standalone: true }"
        >
        </ngx-recaptcha2>
      </ng-container>
      <div
      class="form-error text-error small-text"
      *ngIf="captchaError"
      >
      You must confirm you are not a robot.
      </div>
      <app-button
        id="btn-confirm-default"
        [disabled]="(isSigningIn$ | async)"
        type="submit"
        [loading]="(isSigningIn$ | async) === true"
        isImproveUserNavigation
      >
        Confirm
      </app-button>

    </ng-container>
  </form>
  </ng-container>

  <!-- Adding third AB testing form -->
  <ng-container *ngIf="(isMobileNumberAuth$ | async)">
    <form id="signon-form"
      (ngSubmit)="formState.isValid && handleSubmit()"
      class="minimal-credentials_form"
      [ngClass]="{'error': isErrorBoxDisplayed }"
      novalidate
      style="margin-bottom: 15px;"
      [ngrxFormState]="formState">
      <ng-container *ngIf="!(isSecure$ | async)">

        <!-- Button for name login -->
        <ng-container *ngIf="!isNameFormActive || !isAuthMethodChangeClicked">
          <app-button (buttonClick)="useMemberName()"
            [color]="isAuthMethodChangeClicked ? 'primary-darker-inverted' : 'primary-darker'"
            type="submit">
            First & Last Name
          </app-button>
        </ng-container>

        <!-- Name Login -->
        <ng-container *ngIf="isNameFormActive && isAuthMethodChangeClicked">
          <app-member-name [controls]="controls"></app-member-name>
          <app-login-fail-assistant (hasError)="setHasError($event)"></app-login-fail-assistant>

          <ng-container *ngIf="displayCaptcha">
            <ngx-recaptcha2 style="margin-top:30px"
              #captchaElementSimple
              [siteKey]="siteKey"
              (ready)="handleCaptchaReady()"
              (success)="handleCaptchaSuccessSimple($event)"
              (error)="handleCaptchaError()"
              [useGlobalDomain]="false"
              type="checkbox"
              [ngModel]="captcha"
              [ngModelOptions]="{ standalone: true }">
            </ngx-recaptcha2>
          </ng-container>
          <div class="form-error text-error small-text"
            *ngIf="captchaError">
            You must confirm you are not a robot.
          </div>
          <app-button [disabled]="(isSigningIn$ | async)"
            id="btn-confirm-mna-name"
            type="submit"
            [loading]="(isSigningIn$ | async) === true"
            isImproveUserNavigation>
            Confirm
          </app-button>
        </ng-container>

        <div class="auth-divider">
          <div class="line">
            <mat-divider></mat-divider>
          </div>
          <div class="text mat-typography"> OR </div>
          <div class="line">
            <mat-divider></mat-divider>
          </div>
        </div>

        <!-- Button for Mobile Number Login -->
        <ng-container *ngIf="!isMobileNumberFormActive">
          <app-button (buttonClick)="usePhoneNumber($event)"
            [color]="isAuthMethodChangeClicked ? 'primary-inverted' : 'primary'"
            type="button">
            Phone Number
          </app-button>
        </ng-container>

        <!-- Mobile Number Login -->
        <ng-container *ngIf="isMobileNumberFormActive">
          <app-phone-number [controls]="controls"></app-phone-number>
          <app-login-fail-assistant (hasError)="setHasError($event)"></app-login-fail-assistant>

          <ng-container *ngIf="displayCaptcha">
            <ngx-recaptcha2 style="margin-top:30px"
              #captchaElementSimple
              [siteKey]="siteKey"
              (ready)="handleCaptchaReady()"
              (success)="handleCaptchaSuccessSimple($event)"
              (error)="handleCaptchaError()"
              [useGlobalDomain]="false"
              type="checkbox"
              [ngModel]="captcha"
              [ngModelOptions]="{ standalone: true }">
            </ngx-recaptcha2>
          </ng-container>
          <div class="form-error text-error small-text"
            *ngIf="captchaError">
            You must confirm you are not a robot.
          </div>
          <app-button [disabled]="(isSigningIn$ | async)"
            id="btn-confirm-mna-phone"
            type="submit"
            [loading]="(isSigningIn$ | async) === true"
            isImproveUserNavigation>
            Confirm
          </app-button>
        </ng-container>

        <div class="auth-divider">
          <div class="line">
            <mat-divider></mat-divider>
          </div>
          <div class="text mat-typography"> OR </div>
          <div class="line">
            <mat-divider></mat-divider>
          </div>
        </div>

        <!-- Button for Membership Number Login -->
        <ng-container *ngIf="!isMembershipNumberFormActive">
          <app-button (buttonClick)="useMemberNumber($event)"
            [color]="isAuthMethodChangeClicked ? 'primary-lighter-inverted' : 'primary-lighter'"
            type="button">
            Membership Number
          </app-button>
        </ng-container>

        <!-- Membership Number Login -->
        <ng-container *ngIf="isMembershipNumberFormActive">
          <app-membership-number [controls]="controls" (useMemberNameEmitter)="useMemberNameClicked($event)"></app-membership-number>
          <app-login-fail-assistant (hasError)="setHasError($event)"></app-login-fail-assistant>

          <ng-container *ngIf="displayCaptcha">
            <ngx-recaptcha2 style="margin-top:30px"
              #captchaElementSimple
              [siteKey]="siteKey"
              (ready)="handleCaptchaReady()"
              (success)="handleCaptchaSuccessSimple($event)"
              (error)="handleCaptchaError()"
              [useGlobalDomain]="false"
              type="checkbox"
              [ngModel]="captcha"
              [ngModelOptions]="{ standalone: true }">
            </ngx-recaptcha2>
          </ng-container>
          <div class="form-error text-error small-text"
            *ngIf="captchaError">
            You must confirm you are not a robot.
          </div>
          <app-button [disabled]="(isSigningIn$ | async)"
            id="btn-confirm-mna-number"
            type="submit"
            [loading]="(isSigningIn$ | async) === true"
            isImproveUserNavigation>
            Confirm
          </app-button>
        </ng-container>

      </ng-container>
    </form>
  </ng-container>

  <ng-container *ngIf="displayNotMember">
    <div
      *ngIf="!(isSecure$ | async)"
      class="minimal-credentials_not-a-member color-black "
      role="navigation"
      aria-labelledby="login-help"
    >
      <div id="login-help">
        <ng-container *ngIf="!allowNameAuth">
          <aria-link-button
            class="link-forgot-member-number"
            (clickLink)="openCannotRemember($event)"
            elementId="forgot-member-number"
            role="button">
            Forgot Member Number
          </aria-link-button>
          |
        </ng-container>
        <aria-link-button
          (clickLink)="openNotMember($event)"
          class="minimal-credentials_not-a-member-dialog"
          elementId="not-a-member"
          role="button">
          Not a Member?
        </aria-link-button>
      </div>
    </div>

  </ng-container>

  <div class="minimal-credentials_terms">
    By selecting Confirm, you accept the
  </div>

  <div class="minimal-credentials_terms" role="navigation" aria-labelledby="terms">
    <aria-link-button
      elementId="terms"
      (clickLink)="openTerms()"
      class="terms-and-conditions__link">
      Terms and Conditions
    </aria-link-button>
  </div>

  <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
    <div class="privacy-policy" id="privacy-policy" [ngClass]="{'privacy-policy__banner-closed': (isInstallBannerClosed$ | async) }"  role="navigation" aria-labelledby="privacy-policy">
      <aria-link-button
        (clickLink)="openPrivacyPolicy()"
        class="privacy-policy__link"
        elementId="privacy-policy-link"
      >
        Privacy Policy
      </aria-link-button>
    </div>
  </ng-container>
</section>
