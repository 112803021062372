import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { setABTestParam } from '../../modules/ui/ui.actions'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { ABTestParams } from 'src/app/modules/ui/ui.types'

interface ABTestItem {
  code: ABTestParams;
  value: ABTestParamsKeys;
}

type ABTestParamsKeys = keyof typeof ABTestParams;

@Component({
  selector: 'app-ab-test-switcher',
  templateUrl: './ab-test-switcher.component.html',
  styleUrls: ['./ab-test-switcher.component.scss'],
})
export class AbTestSwitcherComponent {
  abTests$ = new Observable<ABTestItem[]>((obs) => obs.next(
    Object.keys(ABTestParams).map((value: ABTestParamsKeys) => ({
      code: ABTestParams[value],
      value
    }))
  ))

  constructor(private store$: Store<AAAStore>) { }

  changeABTest(abTestParam: ABTestParams) {
    this.store$.dispatch(setABTestParam({ payload: [abTestParam] }))
  }
}
