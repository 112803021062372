<div class="dialog">
  <h1 mat-dialog-title id="dialog-title">{{ title }}</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="!(isEnabled$ | async)">
      <p>Your request cannot be made at this time.</p>
    </ng-container>
    <p *ngFor="let advisory of (advisories$ | async)">
      {{ advisory.msg }}
    </p>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngIf="isEnabled$ | async; else callAAA">
      <button
        name="dialogCustomDismissSubmit"
        class="btn primary btn-sm custom-message-close"
        aria-label="dismiss this message"
        title="DISMISS THIS MESSAGE"
        (click)="onClose()"
      >
        Close
      </button>
    </ng-container>
    <ng-template #callAAA>
      <app-call-aaa-button useDefault="true" [ariaLabel]="'Contact us'"></app-call-aaa-button>
    </ng-template>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
