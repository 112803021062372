import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { ROUTER_NAVIGATION } from '@ngrx/router-store'
import {
  map,
  filter,
  withLatestFrom,
  tap,
  switchMap,
} from 'rxjs/operators'
import { Params, Router } from '@angular/router'
import { PayloadedAction } from 'src/app/shared/types'
import { selectNeedsTow } from '../issue/issue.selectors'
import { SET_TOW_DESTINATION } from '../location/tow-location/tow-location.actions'
import { WizardService } from '../wizard/wizard.service'
import { currentEditStepLocation } from '../wizard/wizard.selectors'
import { StepTypes, SubmitSections, TowSections } from '../ui/ui.types'
import { of } from 'rxjs'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'
import { TaggingService } from '../tagging/tagging.service';
import { Title } from '@angular/platform-browser';
import { RapService } from '../rap/rap.service';

@Injectable()
export class SubmitEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private router: Router,
    private wizardService: WizardService,
  ) {}

  // We're about to submit; do we need a tow, or can we just go straight to passengers?
  beforeNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        switchMap((action) =>
          of(action).pipe(
            map(
              (payloadedAction: PayloadedAction) =>
                payloadedAction.payload.routerState.root.queryParams
            ),
            filter(
              (params: Params) =>
                params.step === StepTypes.SUBMIT && !params.section
            ),
            withLatestFrom(
              this.store$.pipe(select(selectNeedsTow)),
              this.store$.pipe(select(currentEditStepLocation))
            ),
            tap(
              ([params, needsTow, currentStepUrl]: [
                Params,
                boolean,
                string
              ]) => {
                // if just added a new vehicle or edited some data
                // that not need a towing action , just go back to last step url
                if (currentStepUrl && !needsTow) {
                  this.wizardService.backToEditUrl(currentStepUrl)
                  return true
                }
                const section = SubmitSections.SUMMARY
                this.router.navigate([], {
                  queryParams: { ...params, section },
                })
              }
            )
          )
        )
      ),
    { dispatch: false }
  )

  handleSetTowDestination = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_TOW_DESTINATION.SUCCESS),
        withLatestFrom(this.store$.select(selectActiveCallStatus)),
        filter(([_, activeCallStatus]) => activeCallStatus === null),
        map(() =>
          this.router.navigate([], {
            queryParams: {
              step: StepTypes.TOWING,
              section: TowSections.PASSENGERS,
            },
          })
        )
      ),
    { dispatch: false }
  )

}
