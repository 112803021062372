import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'

enum ButtonSizes {
  CONDENSED = 'condensed',
  COZY = 'cozy',
  FIT_CONTENT = 'fit-content',
}

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LinkButtonComponent {
  @Output() clickLink: EventEmitter<any> = new EventEmitter<any>()

  @Input() href = ''
  @Input() inverted = false
  @Input() target = ''
  @Input() tabIndex = 0
  @Input() size: ButtonSizes = ButtonSizes.COZY
  @Input() ariaLabel = null
  @Input() elementId = null
  @Input() role = null
  @Input() fontSize = null

  handleClick($event: any) {
    if (this.clickLink) {
      if (this.href === '' || this.href === undefined) {
        $event.preventDefault()
        $event.stopPropagation()
      }

      this.clickLink.emit($event)
    }
  }

}
