import { createFeatureSelector, createSelector } from '@ngrx/store'

import { AAACallState } from './calls.reducer'
import { BasicAddressInfo, UserInfo } from '../../shared/types'
import { BreakdownLocation, TowLocation, } from '../location/location.types'
import { AAACallData, AgentSettings, ServiceDeliveryCallStatus, } from './calls.types'
import { selectAgentSettings, selectAuthClub, selectEligibility } from '../auth/auth.selectors'

import {
  selectMemberActiveVehicle,
  selectMemberActiveVehicleNotes,
  selectMemberData,
} from '../member/member.selectors'
import { concatAddress } from '../../shared/utils'
import { selectActivePaceSetterCode, selectPaceSetterNotesAndComments, } from '../issue/issue.selectors'
import { selectTowLocation } from '../location/tow-location/tow-location.selectors'
import {
  selectBreakdownLocationDetails,
  selectBreakdownLocationParams,
  selectHighwayExit,
} from '../location/location.selectors'
import {
  selectCommentSummary,
  selectMergedPassengersComments,
  selectUserContactInfo,
} from '../submit/submit.selectors'
import { CHARACTER_LIMIT_FACILITY, CHARACTER_LIMIT_VEHICLE_COLOR, } from '../location/location.constants'
import { VehicleDriveTypes } from '../vehicle/vehicle.types'
import { Vehicle } from '../member/member.types'

const stateKey = 'call'

export const selectCallState = createFeatureSelector<AAACallState>(stateKey)

export const selectCallBreakdownLocation = createSelector(
  selectCallState,
  (state: AAACallState): BreakdownLocation => state.breakdownLocation
)

export const selectCallBreakdownLocationAddress = createSelector(
  selectCallState,
  (state: AAACallState): string => state.breakdownLocationAddress
)

export const selectCallTowing = createSelector(
  selectCallState,
  (state: AAACallState): TowLocation => state.towing
)

export const selectTowingDescription = createSelector(
  selectCallTowing,
  (towing: TowLocation): string => {
    if (!towing) {
      return ''
    }

    return concatAddress(towing as BasicAddressInfo, towing.name, true)
  }
)

// start ARR:POC - A selector for selecting the service delivery data
export const selectServiceDeliveryStatus = createSelector(
  selectCallState,
  (state: AAACallState): ServiceDeliveryCallStatus => state.callStatus
)
// end ARR:POC

const selectMergedComments = createSelector(
  selectPaceSetterNotesAndComments,
  selectBreakdownLocationDetails,
  selectMemberActiveVehicleNotes,
  selectMergedPassengersComments,
  selectHighwayExit,
  selectCommentSummary,
  (
    paceSetterComments,
    locationDetails,
    vehicleNotes,
    passengerComments,
    highwayExit,
    commentSummary
  ): string => {
    const result = []

    // first place pace setter comments, if it exists
    if (paceSetterComments) {
      result.push(paceSetterComments)
    }

    // second place breakdown location name, if it exists
    result.push(...locationDetails.options.map((detail) => detail.name))

    // third place any breakdown location notes, if it exists
    if (locationDetails.notes) {
      result.push(locationDetails.notes)
    }

    // fourth place any vehicles notes, if it exists
    if (vehicleNotes) {
      result.push(vehicleNotes)
    }

    // fifth place any vehicles comments, if it exists
    if (passengerComments) {
      result.push(passengerComments)
    }

    // Sixth highway exit information
    if (highwayExit) {
      result.push(`LOCATION: ${highwayExit}`)
    }
    // Seventh comment summary, if it exists
    if (commentSummary) {
      result.push(commentSummary)
    }

    return result.join(' ')
  }
)

// Compacted club + agent selectors due to a limitation of 9 params in createSelectors.
export const selectAgentAndClub = createSelector(
  selectAuthClub,
  selectAgentSettings,
  (club, agentSettings): AgentSettings => {
    if (!agentSettings) {
      return { club }
    }

    return {
      club,
      agentName: agentSettings.agentName,
      agentUsername: agentSettings.agentUsername,
      cashCall: agentSettings.cashCall,
      priorityCode: agentSettings.priorityCode,
      vehicleType: agentSettings.vehicleType,
    }
  }
)

export const selectUserInfo = createSelector(
  selectMemberData,
  selectUserContactInfo,
  selectEligibility,
  (
    memberData,
    userContactInfo,
    eligibility,
  ): UserInfo => {
    if (eligibility) {
      return ({
        ...memberData,
        ...userContactInfo,
      })
    }
    return ({
      ...memberData
    })
  }
)

export const selectVehicleCallData = createSelector(
  selectMemberActiveVehicle,
  selectAgentSettings,
  (activeMemberVehicle, agentSettings): Vehicle => {
    let vehicleColor = activeMemberVehicle?.color;
    if (!vehicleColor) {
      vehicleColor = 'Unknown'
    }
    const color = vehicleColor.substring(0, CHARACTER_LIMIT_VEHICLE_COLOR)
    let driveType = activeMemberVehicle?.driveType
      // TODO: The API only recognizes either AWD or 4WD, despite UI/UX decision to combine them
      ? VehicleDriveTypes.ALL_WHEEL_DRIVE
      : null
    const vehicleType = agentSettings?.vehicleType
    return {
      ...activeMemberVehicle,
      color,
      vehicleType,
      driveType
    }
  }
)

export const selectCallData = createSelector(
  selectUserInfo,
  selectVehicleCallData,
  selectActivePaceSetterCode,
  selectUserContactInfo,
  selectMergedComments,
  selectBreakdownLocationParams,
  selectTowLocation,
  selectAgentAndClub,
  (
    userInfo,
    vehicle,
    activePaceSetter,
    userContactInfo,
    mergedComments,
    breakdownLocationParams,
    towLocation,
    agentSettings
  ): AAACallData => {
    const address = towLocation ? concatAddress(towLocation) : ''

    return {
      club: agentSettings.club,
      contactInfo: {
        enableEmail: false,
        enablePushNotifications: false,
        enableSMS: !!userContactInfo.shouldTextUpdates,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phone: userContactInfo.contactNumber,
      },
      vehicle,
      callTaker: agentSettings.agentName || agentSettings.agentUsername,
      cashRequired: agentSettings.cashCall,
      priorityCode: agentSettings.priorityCode,
      situationCodes: {
        pacesetterCode: activePaceSetter?.paceSetterCode,
      },
      comments: mergedComments,
      breakdownLocation: breakdownLocationParams,
      towing: towLocation
        ? {
            facility: (towLocation.landmark ? towLocation.landmark : towLocation.name).substring(0, CHARACTER_LIMIT_FACILITY),
            serviceProviderCode: towLocation.serviceProviderCode,
            address,
            name: towLocation.name.substring(0, CHARACTER_LIMIT_FACILITY),
            streetNumber: towLocation.streetNumber,
            streetName: towLocation.streetName,
            city: towLocation.city,
            state: towLocation.state,
            latitude: towLocation.latitude,
            longitude: towLocation.longitude,
            aarId: towLocation.aarId,
            landmark: towLocation.landmark,
          }
        : null,
    }
  }
)

export const __TEST__ = {
  selectMergedComments,
}
