import { concatWith } from './concatWith'
import { pipe } from 'rxjs'
import { BasicAddressInfo } from '../types'
import { CHARACTER_LIMIT_FACILITY } from 'src/app/modules/location/location.constants'

export const concatAddressLine1 = (
  streetNumber: string,
  streetName: string,
  prefix: String | null = null
) => {
  // prevent undefined when the streetNumber isn't present
  const adjustedStreetNumber =
    streetNumber && streetNumber !== 'undefined' && streetNumber !== '0'
      ? (streetNumber || '').toString()
      : ''
  const address = adjustedStreetNumber !== ''  ? `${adjustedStreetNumber} ${streetName}` : streetName
  if (prefix && prefix !== address) {
    return `${prefix.substr(0, CHARACTER_LIMIT_FACILITY)} - ${address}`
  }
  return address
}

export const concatAddressLine2 = (
  city: string,
  state: string,
  postalCode: string,
  showPostalCode: Boolean = false
) => {
  // Prevent null reference exceptions
  if (
    postalCode === null ||
    postalCode === undefined ||
    postalCode === 'undefined'
  ) {
    postalCode = ''
  }
  return pipe(
    concatWith(city, ''),
    concatWith(state, ', '),
    concatWith(showPostalCode && postalCode ? postalCode.toString() : '', ', ')
  )('')
}

export const concatAddress = (
  { city, streetName, streetNumber, state, postalCode }: BasicAddressInfo,
  prefix: String | null = null,
  showPostalCode: Boolean = false
) => {
  const addressLine1 = concatAddressLine1(streetNumber, streetName)
  const addressLine2 = concatAddressLine2(city, state, postalCode, showPostalCode)
  const address = addressLine2 !== '' ? `${addressLine1}, ${addressLine2}` : addressLine1
  if (prefix && prefix !== addressLine1) {
    return `${prefix.substr(0, CHARACTER_LIMIT_FACILITY)} - ${address}`
  }
  return address
}
