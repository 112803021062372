import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, TemplateRef, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AUTH, authOemRequest, authRentalRequest, authVasRequest, AUTH_OEM, AUTH_RENTAL, AUTH_RESET, AUTH_VAS, notifyAuthFailure } from 'src/app/modules/auth/auth.actions';
import { selectAuthState, selectIsRapEligible, selectIsSecure, selectModeConfiguration } from 'src/app/modules/auth/auth.selectors';
import { AuthMethods, BaseAuthRequestParams, OemAuthRequestParams, RentalAuthRequestParams, VasAuthRequestParams } from 'src/app/modules/auth/auth.types';
import { loadModeConfiguration } from 'src/app/modules/auth/mode-configuration.actions';
import { VendorConfigurationMode } from 'src/app/modules/auth/mode-configuration.types';
import { VALIDATION_TYPES } from 'src/app/modules/auth/mode.constants';
import { TaggingService } from 'src/app/modules/tagging/tagging.service';
import { CarRentalAuthFormState, SubscriberNumberAuthFormState, VinMileageAuthFormState } from 'src/app/modules/ui/forms/forms.reducer';
import { selectCarRentalAuthForm, selectSubscriberNumberAuthForm, selectVinMileageAuthForm } from 'src/app/modules/ui/forms/forms.selectors';
import { selectIsLoading } from 'src/app/modules/ui/loading/loading.selectors';
import { animateSplashscreenHide, openPromptDialog, setSplashscreenStep, setWatchRecaptchaPosition } from 'src/app/modules/ui/ui.actions';
import { PromptDialogTypes } from 'src/app/modules/ui/ui.types';
import { AAAStore } from 'src/app/store/root-reducer';
import { environment as config } from 'src/environments/environment';
import { AbstractComponent } from '../../abstract.component';
import { ValidationFormComponent } from '../../types';
import { isMobileApp } from '../../utils/app-detect';
import { CarRentalCredentialsComponent } from '../../whitelabel-credentials-modes/car-rental-credentials/car-rental-credentials.component';
import { SubscriberNumberCredentialsComponent } from '../../whitelabel-credentials-modes/subscriber-number-credentials/subscriber-number-credentials.component';
import { VinMileageCredentialsComponent } from '../../whitelabel-credentials-modes/vin-mileage-credentials/vin-mileage-credentials.component';
import { FormModeDirective } from './form-mode.directive';
import { v4 as uuidv4 } from 'uuid';
import { requestVehicleYears } from 'src/app/modules/vehicle/vehicle.actions';
import { selectQueryParamsVehicleData } from 'src/app/modules/ui/ui.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { AuthState } from 'src/app/modules/auth/auth.reducer';
import { ACTIVE_CALL_STATUS } from 'src/app/modules/dashboard/calls-statuses/call-status.actions';


interface ObjectDataInterface {
  auth: Function
  requestData: VasAuthRequestParams | RentalAuthRequestParams | OemAuthRequestParams
  formComponent: Type<ValidationFormComponent>
}

const _pageType = 'Verification Home'
@Component({
  selector: 'app-white-label-credentials',
  templateUrl: './white-label-credentials.component.html',
  styleUrls: ['./white-label-credentials.component.scss']
})
export class WhiteLabelCredentialsComponent extends AbstractComponent implements OnInit {
  @Input() mode
  @Input() tabIndexPosition = 0

  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>
  @ViewChild('captchaElement') captchaElement: ReCaptcha2Component

  @ViewChild(FormModeDirective, { static: true }) formMode!: FormModeDirective;

  form: ComponentRef<ValidationFormComponent>;
  private _formType: string;

  public get formType(): string {
    return this._formType;
  }

  public set formType(value: string) {
    this._formType = value;
  }

  captcha // Required for an Angular reason
  captchaBadge: 'bottomright' | 'bottomleft' | 'inline' = 'inline'
  captchaToken: string
  captchaError = false
  siteKey = config.googleCaptchaKey
  allowNameAuth: boolean
  privacyPolicyLink: string
  termsAndConditionsLink = 'https://www.aaa.com/AAA/common/drrsmsterms/terms.html'

  authParams = new BehaviorSubject<any>({
    referenceId: '',
    captchaToken: null,
    manufacturer: '',
    vin: '',
    odometer: '',
    rentalAgreement: '',
    subscriberId: '',
  })

  oemAuthForm$: Observable<FormGroupState<VinMileageAuthFormState>> = this.store$.pipe(
    select(selectVinMileageAuthForm)
  )

  rentalAuthForm$: Observable<FormGroupState<CarRentalAuthFormState>> = this.store$.pipe(
    select(selectCarRentalAuthForm)
  )

  vasAuthForm$: Observable<FormGroupState<SubscriberNumberAuthFormState>> = this.store$.pipe(
    select(selectSubscriberNumberAuthForm)
  )

  authOemForm: any = {}
  authRentalForm: any = {}
  authVasForm: any = {}

  get formState(): FormGroupState<any> {
    switch (this.formType?.toUpperCase()) {
      case VALIDATION_TYPES.VAS:
        return this.authVasForm
      case VALIDATION_TYPES.RENTAL:
        return this.authRentalForm
      default:
      case VALIDATION_TYPES.OEM:
        return this.authOemForm
    }
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  isSigningIn$: Observable<boolean> = combineLatest([
    this.store$.pipe(select(selectIsLoading(AUTH.ACTION))),
    this.store$.pipe(select(selectIsLoading(AUTH_OEM.ACTION))),
    this.store$.pipe(select(selectIsLoading(AUTH_RENTAL.ACTION))),
    this.store$.pipe(select(selectIsLoading(AUTH_VAS.ACTION))),
    this.store$.pipe(select(selectIsLoading(ACTIVE_CALL_STATUS.ACTION))),
    this.store$.pipe(select(selectIsRapEligible)),
  ]).pipe(map((areActionsLoading) => areActionsLoading.some(Boolean)))

  authState$: Observable<[AuthState, boolean]> = combineLatest([
    this.store$.pipe(select(selectAuthState)),
    this.isSigningIn$,
  ])

  isResetNeeded$: Observable<boolean> = this.actions$.pipe(
    ofType<ReturnType<typeof notifyAuthFailure>>(AUTH_OEM.FAILURE, AUTH_VAS.FAILURE, AUTH_RENTAL.FAILURE, AUTH_RESET),
    map(() => true)
  )

  isSecure$ = this.store$.pipe(select(selectIsSecure))
  modeConfiguration$ = this.store$.pipe(select(selectModeConfiguration))
  queryParamsVehicle$ = this.store$.pipe(select(selectQueryParamsVehicleData))
  captchaInterval = null

  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    public dialog: MatDialog,
    private resolver: ComponentFactoryResolver,
  ) {
    super()
  }

  getObjectDataByType(formType): ObjectDataInterface {
    switch (formType?.toUpperCase()) {
      case VALIDATION_TYPES.VAS:
        return {
          auth: authVasRequest,
          requestData: {
            subscriber: this.formState.value.subscriber,
            referenceId: uuidv4()
          },
          formComponent: SubscriberNumberCredentialsComponent
        }

      case VALIDATION_TYPES.RENTAL:
        return {
          auth: authRentalRequest,
          requestData: {
            refNumber: this.formState.value.refNumber,
            referenceId: uuidv4()
          },
          formComponent: CarRentalCredentialsComponent
        }

      default:
      case VALIDATION_TYPES.OEM:
        return {
          auth: authOemRequest,
          requestData: {
            vin: this.formState.value.vinNumber,
            mileage: this.formState.value.mileage,
            manufacturer: this.mode,
            referenceId: uuidv4()
          },
          formComponent: VinMileageCredentialsComponent
        }
    }
  }

  ngOnInit(): void {
    this.modeConfiguration$.subscribe((configData: VendorConfigurationMode) => {
      if (configData) {
        this.privacyPolicyLink = configData.privacyPolicy
        if(configData.termsConditions) {
          this.termsAndConditionsLink = configData.termsConditions
        }
        this.formType = configData.validation;
        this.subscriptions.push(
          this.oemAuthForm$.subscribe((form) => {
            this.authOemForm = form
          }),
          this.rentalAuthForm$.subscribe((form) => {
            this.authRentalForm = form
          }),
          this.vasAuthForm$.subscribe((form) => {
            this.authVasForm = form
          }),
        )
        this.displayForm();

        const _action = `${this.formType?.toLocaleUpperCase()} Validation Prompt`
        this.taggingService.setAutomatedEvent(_action, _pageType)
      }
    })

    this.store$.dispatch(loadModeConfiguration({ payload: this.mode }));

    this.queryParamsVehicle$.subscribe((vehicle) => {
      if (vehicle?.year) {
        this.store$.dispatch(requestVehicleYears())
      }
    })

    this.subscriptions.push(
      this.authState$.subscribe(([authState]) => {
        this.authParams.next(authState)
      }),
      this.isSecure$.pipe(filter(isSecure => !isSecure)).subscribe(_ => {
        this.store$.dispatch(animateSplashscreenHide())
      }),
      this.isResetNeeded$
        .pipe(filter((isResetNeeded) => isResetNeeded))
        .subscribe(() => {
          this.captchaToken = undefined
          this.captchaElement.reCaptchaApi.reset()
        }),
    )
  }

  displayForm() {
    if(this.formType) {
      const componentFactory = this.resolver.resolveComponentFactory(this.getObjectDataByType(this.formType).formComponent);
      const viewContainerRef = this.formMode.viewContainerRef;
      viewContainerRef.clear();
      this.form = viewContainerRef.createComponent<ValidationFormComponent>(componentFactory);
    }
  }

  doAuth(captchaToken) {
    const { isSecure } = this.authParams.getValue()
    this.store$.dispatch(setSplashscreenStep({ payload: 3 }))
    this.signOn({ isSecure, captchaToken });
    this.stopCapchaWatcher()
  }

  checkFieldsAndSubmit() {
    if (this.controls?.subscriber) {
      if (!this.controls.subscriber.isValid) {
        this.controls.subscriber.isPristine = false;
        this.controls.subscriber.isDirty = true;
        this.controls.subscriber.isTouched = true;
        (<HTMLInputElement>document.getElementById('subscriber'))?.focus();
      }

      this.handleSubmit()
      return;
    }

    if (!this.controls.vinNumber.isValid) {
      this.controls.vinNumber.isPristine = false;
      this.controls.vinNumber.isDirty = true;
      this.controls.vinNumber.isTouched = true;
      (<HTMLInputElement>document.getElementById('vinNumber'))?.focus();
    }

    if (!this.controls.mileage.isValid) {
      this.controls.mileage.isPristine = false;
      this.controls.mileage.isDirty = true;
      this.controls.mileage.isTouched = true;
      (<HTMLInputElement>document.getElementById('mileage'))?.focus();
    }

    this.handleSubmit()
  }

  handleSubmit() {
    this.store$.dispatch(setSplashscreenStep({ payload: 2 }))

    if (this.captchaToken === undefined) {
      this.captchaError = true
      return
    }

    if (this.formState.isValid) {
      this.captchaError = false
      this.doAuth(this.captchaToken)
      return
    }
  }

  handleCaptchaReady() {
    this.watchRecapchaPosition()
  }

  handleExpire() {
    this.captchaToken = undefined
  }

  handleCaptchaSuccess(captchaToken: string) {
    this.captchaToken = captchaToken
    this.captchaError = false
    this.stopCapchaWatcher()
  }

  signOn({ isSecure = false, captchaToken = null }: BaseAuthRequestParams) {
    const requestData = this.getObjectDataByType(this.formType).requestData
    const authRequest = this.getObjectDataByType(this.formType).auth
    const TYPE = this.formType?.toUpperCase();
    const _action = `${TYPE} Validation Request`;
    const payload = {
      isSecure,
      method: AuthMethods[TYPE],
      captchaToken,
      ...requestData
    }

    this.taggingService.setClickEvent(_action, _pageType)
    this.store$.dispatch(
      authRequest({ payload })
    )
  }

  // continuously check captcha position and place it on
  // the middle of the screen
  watchRecapchaPosition() {
    this.store$.dispatch(
      setWatchRecaptchaPosition({ payload: true })
    )
  }

  stopCapchaWatcher() {
    this.store$.dispatch(
      setWatchRecaptchaPosition({ payload: false })
    )
  }

  openPrivacyPolicy() {
    const _action = 'Privacy Policy'
    this.taggingService.setClickEvent(_action, _pageType)

    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.IFRAME_DIALOG,
          panelClass: 'iframe-dialog',
          params: {
            ariaLabel: 'Privacy policy content',
            url: this.privacyPolicyLink,
          },
        },
      })
    )
  }

  openTerms() {
    const _action = 'Terms and Conditions'
    this.taggingService.setClickEvent(_action, _pageType)

    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.IFRAME_DIALOG,
          panelClass: 'iframe-dialog',
          params: {
            ariaLabel: 'Terms and conditions content',
            url: this.termsAndConditionsLink,
          },
        },
      })
    )
  }

  isWebApp() {
    return !isMobileApp()
  }

  ngOnDestroy() {
    this.form?.destroy();
  }

  handleCaptchaError() {
    this.captchaElement.resetCaptcha()
  }

}
