export default {
  VEHICLE_PAGE_TYPE: 'What vehicle needs service?',
  VEHICLE_PAGE_PROMPT: 'Vehicles screen displayed',
  VEHICLE_SUSPICIOUS_PAGE_TYPE: 'Vehicle',
  VEHICLE_YEAR_SELECT: 'Select Year',
  VEHICLE_MAKE_SELECT: 'Select Make',
  VEHICLE_MODEL_SELECT: 'Select Model',
  VEHICLE_OTHER_MODEL_SELECT: 'Select Other Model',
  VEHICLE_PRELOADED_SELECT: 'Select saved vehicle',
  VEHICLE_COMPLETE: 'Vehicle selection complete',
  VEHICLE_EDIT: 'Edit Vehicle',
  VEHICLE_DELETE: 'Delete Vehicle'
}
