<section class="minimal-credentials no-shadow">
  <div class="minimal-credentials_membership-line"
       role="navigation"
       style="margin-top: 15px;"
       aria-labelledby="mobile-number">
    <label for="phoneNumber"
           id="mobile-phone">Phone Number of Primary Account Holder</label>
  </div>
  <input name="phoneNumber"
         id="phoneNumber"
         type="tel"
         [ngrxFormControlState]="controls.phoneNumber"
         placeholder="10 digit phone number"
         [textMask]="{ mask: phoneNumberMask }"
         aria-required="true"
         autocomplete="tel-national"
         required/>
  <app-form-error [control]="controls?.phoneNumber"
                  name='phoneNumber'
                  type='required'>
    Phone number is required.
  </app-form-error>
  <app-form-error [control]="controls?.phoneNumber"
                  name='phoneNumber'
                  type='pattern'>
    Phone number must be exactly 10 characters long.
  </app-form-error>

  <div class="minimal-credentials_postal-code-line">
    <label for="zipCode">Home Zip Code</label>
    <input name="zipCode"
           id="zipCode"
           type="tel"
           placeholder="Enter Your Zip Code"
           minLength="5"
           [textMask]="{ mask: zipCodeMask, keepCharPositions: true }"
           [ngrxFormControlState]="controls.zipCode"
           aria-required="true"
           autocomplete="postal-code"
           required/>

    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='required'> Zip Code is required.
    </app-form-error>
    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='minLength'> Zip Code must be 5 characters long.
    </app-form-error>
    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='maxLength'> Zip Code must be 5 characters long.
    </app-form-error>
  </div>
</section>
