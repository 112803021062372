import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'

import { DialogData } from 'src/app/modules/ui/ui.types'

@Component({
  selector: 'authentication-dialog-component',
  templateUrl: './authentication.component.html',
  styleUrls: [
    '../shared-dialog.component.scss',
    './authentication.component.scss',
  ],
})
export class AuthenticationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog
  ) {}
}
