<div class="dialog">
  <h1 mat-dialog-title>AAA Saves Members Money</h1>
  <app-tow-location-promo-v2 [promoView]="'DOLLAR'"></app-tow-location-promo-v2>
  <div class="dialog-content">
    <p class="text-message">AAA Members receive 10% Off Labor (Up to $50) when selecting a <strong>AAA Approved Facility</strong>.</p>
  </div>
  <div class="dialog-actions full-width-desktop"
    mat-dialog-actions>
    <app-button id="dialog-confirm-submit"
      name="dialogConfirmSubmit"
      type="button"
      [color]="'primary-yellow'"
      (click)="onSubmit()"
      aria-label="Save now with a AAA Approved Facility">
      SAVE NOW<br>with a AAA Approved Facility
    </app-button>
    <aria-link-button (clickLink)="onEnterYourAddress()"
      class="dialog-link-button">
      Enter an Address
    </aria-link-button>
  </div>
  <button mat-dialog-close
    class="mat-dialog-close"
    aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
