import { Injectable } from '@angular/core'
import {
  clickEventAction,
  pageViewAction,
  automatedEventAction,
  AutomatedEventMeta,
  notifyHotjarAction,
} from './tagging.actions'
import { Store } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'

@Injectable({
  providedIn: 'root',
})
export class TaggingService {
  constructor(private store$: Store<AAAStore>) {}

  setClickEvent(action: string, pageType: String, extras?: any, params?: object) {
    const payload = { action, pageType, ...extras }
    if (params) {
      payload['params'] = params
    }
    const _clickEvent = clickEventAction({ payload })
    this.store$.dispatch(_clickEvent)
    this.store$.dispatch(notifyHotjarAction({ payload: action }))
  }

  setPageEvent(action, pageType) {
    const _pageEvent = pageViewAction({ payload: { action, pageType } })
    this.store$.dispatch(_pageEvent)
  }

  setAutomatedEvent(
    action,
    pageType,
    meta?: AutomatedEventMeta,
    params?: object
  ) {
    const _automatedEvent = automatedEventAction({
      payload: { action, pageType, params },
      meta,
    })
    this.store$.dispatch(_automatedEvent)
    this.store$.dispatch(notifyHotjarAction({ payload: action }))
  }
}
