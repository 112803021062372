<div class="dialog">
  <h1 mat-dialog-title>Alert</h1>

  <div id="dialog-content" mat-dialog-content>
    <p>Your tow to destination appears to be the same as your breakdown location. Please update the tow to destination to proceed.</p>
  </div>

  <div mat-dialog-actions>
    <app-button
      [className]="'continue-button'"
      type="button"
      (click)="onContinue()"
      aria-label="Continue"
    >
      Continue
    </app-button>
  </div>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
