import { Component, Input } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectIsImproveUserNavigation } from 'src/app/modules/ui/ui.selectors'
import { Location } from '@angular/common'

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() tabIndex = 0

  isShown$: Observable<boolean> = this.store$.select(selectIsImproveUserNavigation)

  constructor(private store$: Store, private location: Location) {}

  back() {
    this.location.back()
  }
}
