<div class="dialog dialog-error">
  <h1 mat-dialog-title>Notice</h1>
  <div id="dialog-content" mat-dialog-content>
    <p class="font-size-large">
      This session has expired. Please, sign in again.
    </p>
    <p class="font-size-large">Having trouble signing in? Please give us a call.</p>
  </div>
  <div *ngIf="!data.params.hideActions" mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      (buttonClick)="onCallAAAClick()">
    </app-call-aaa-button>

    <button
      *ngIf="!!data.params.showDismissButton"
      id="dialog-dismiss-submit"
      name="dialogGenericErrorDismiss"
      class="btn primary btn-sm"
      aria-label="dismiss this message"
      (click)="onClose()">
      Dismiss
    </button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
