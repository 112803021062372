<div class="dialog dialog-error">
  <h1 mat-dialog-title>Notice</h1>
  <div id="dialog-content" mat-dialog-content>
    <p class="font-size-large" *ngIf="!isRapUser">
      We are having trouble verifying your membership, please try again. If the problem persists please contact us:
    </p>
    <p class="font-size-large" *ngIf="isRapUser">
      To continue your request for Roadside Assistance, please contact us at
    </p>
  </div>
  <div *ngIf="!data.params.hideActions" mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      (buttonClick)="onCallAAAClick()"
      [ariaLabel]="'Contact us'">
    </app-call-aaa-button>
    <button
      *ngIf="!!data.params.showDismissButton"
      id="dialog-dismiss-submit"
      name="dialogGenericErrorDismiss"
      class="btn primary btn-sm"
      aria-label="dismiss this message"
      (click)="onClose()">
      Dismiss
    </button>
  </div>
  <button *ngIf="!hideCloseButton" mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
