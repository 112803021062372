import { Injectable } from '@angular/core'
import { from, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { GenericCoordinates } from 'src/app/modules/location/location.types'
import { RouteDistanceRequest } from 'src/app/modules/route-distance/route-distance.types'
import { delayedRetry } from 'src/app/shared/utils/delayedRetryOperator'

declare let google: any
export const TRAVEL_MODE = 'DRIVING'

@Injectable({ providedIn: 'root' })
export class GoogleDirectionsService {

  retryDelay = 1000
  retries = 2

  _calculateDistance(origin: GenericCoordinates, destination: GenericCoordinates) {
    if (!origin.latitude || !origin.longitude
      || !destination.latitude || !destination.longitude) {
      return of([])
    }

    const directionsService = new google.maps.DirectionsService()
    const request = {
      origin: new google.maps.LatLng(origin.latitude, origin.longitude),
      destination: new google.maps.LatLng(destination.latitude, destination.longitude),
      travelMode: TRAVEL_MODE
    };

    return from(
      new Promise((fulfill, reject) => {
        directionsService.route(request, function(result, status) {
          if ('OK' !== status) {
            reject(status)
            return
          }
          fulfill(
            result.routes[0].legs[0].distance.value * 0.000621371192
          )
        });
      })
    )
  }

  calculateDistance = (routeDistanceRequest: RouteDistanceRequest): Promise<number> =>
    of(routeDistanceRequest)
      .pipe(
        switchMap((routeDistanceRequest) =>
          this._calculateDistance(routeDistanceRequest.origin, routeDistanceRequest.destination)),
        delayedRetry(this.retryDelay, this.retries)
      )
      .toPromise()

}
