import { Component, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  template: ''
})
export class AbstractComponent implements OnDestroy {
  subscriptions: Array<Subscription> = []

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  addSubscription = (subscription: Subscription) => this.subscriptions.push(subscription)

}
