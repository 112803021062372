export enum APIids {
  ERS = 'ERS',
}

export enum AppConfigParamsKeys {
  APP_ID = 'appid',
  DEFAULT_LATITUDE = 'lat',
  DEFAULT_LONGITUDE = 'lng',
  PREPROD = 'preprod',
  ZIP_CODE = 'memberzip',
  AGENT_ID = 'agentid',
  AGENT_FIRST_NAME = 'agent-first-name',
  AGENT_LAST_NAME = 'agent-last-name',
  AGENT_VER = 'agentver',
  MAP_DEBUG = 'mapdebug',
  TELEMATICS = 'telematics',
  MAKE = 'make',
  MODEL = 'model',
  YEAR = 'year',
  FIRST_TIME_ACCESS = 'ft',
  // parameters for integration tests tweaks
  CALL_STATUS_INTERVAL = 'csi',
  SESSION_TIMEOUT_INTERVAL = 'sti',
  CHECK_SESSION_TIMEOUT_INTERVAL = 'csti',
  ALERT_SESSION_TIMEOUT_INTERVAL = 'asti',
  FIRST_CALL_STATUS_TIMEOUT = 'fcst',
  CREATE_CALL_TIMEOUT = 'cct',
  GET_ACTIVE_CALLS_TIMEOUT = 'gact',
  MOCK_CAPTCHA = 'mock-captcha',
  CHANNEL = 'channel',
}

// TODO remove all ABTest parameters from UIConfigParamsKeys after the single abTest parameter is in use
export enum UIConfigParamsKeys {
  MENU = 'menu',
  AB_TEST = 'abtest',
  SHOW_AUTH_OPTION_BUTTON = 'aob',
}

export enum AuthConfigParamsKeys {
  NAME_VALIDATE = 'namevalidate',
  MEM_ID = 'memid',
  ENCRYPTED_MEMBER_NUMBER = 'membernumber',
  ETS = 'ets',
  TOKEN = 'token',
  RAP_TOKEN = 'raptoken',
  CLUB = 'club',
}
