import { Component, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AbstractComponent } from 'src/app/shared/abstract.component'
import { PlusIndicatorValues } from '../types'
import { AAAStore } from '../../store/root-reducer'
import { selectMemberData } from '../../modules/member/member.selectors'
import { MemberInfo } from '../../modules/member/member.types'
import { MatAccordion } from '@angular/material/expansion'
import { getMemberGreetings } from 'src/app/shared/utils/entitlement.utils'
import { selectEligibility } from '../../modules/auth/auth.selectors'
import { Eligibility } from '../../modules/auth/eligibility.types'

@Component({
  selector: 'app-welcome-header',
  templateUrl: './welcome-header.component.html',
  styleUrls: ['./welcome-header.component.scss'],
})
export class WelcomeHeaderComponent extends AbstractComponent {

  @ViewChild( MatAccordion ) accordion: MatAccordion;

  memberYears: number

  darkerMemberBg = false

  memberData$: Observable<MemberInfo> = this.store$.pipe(select(selectMemberData))

  eligibility$: Observable<Eligibility> = this.store$.pipe(select(selectEligibility));

  constructor(
    private store$: Store<AAAStore>,
    protected titleService: Title,
  ) {
    super()
  }

  getPlusIndicatorName(plusIndicator: string) {
    return PlusIndicatorValues[plusIndicator] || plusIndicator
  }

  _getMemberGreetings(joinYearDate) {
    return getMemberGreetings(joinYearDate)
  }
}
