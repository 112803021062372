import { PayloadedAction } from 'src/app/shared/types'
import { ClubSettingsGroup } from './servicing-club.types'
import { SET_SERVICING_CLUB_CONFIGS } from './servicing-club.actions'

export interface ServicingClubState {
  club: ClubSettingsGroup
}

export const initialState = {
  club: {},
}

export function reducer(state = initialState, action: PayloadedAction) {
  switch (action.type) {
    case SET_SERVICING_CLUB_CONFIGS.SUCCESS:
      return {
        ...state,
        club: {
          ...state.club,
          [action.payload.club]: action.payload.config,
        },
      }
    default:
      return state
  }
}
