<div class="dialog">
  <h1 mat-dialog-title class="feedback-title">
    Why Are You Canceling?
  </h1>
  <div mat-dialog-content class="modal-form">

    <div class="feedback-description">
      Please help us understand why you'd like to cancel your roadside request:
    </div>

    <div class="feedback-options">
      <ng-container *ngIf="!hasAvailableCall; else postSubmissionCancel">
        <app-button (buttonClick)="handleSubmitLater()"
          [size]="'medium'">I Will Submit My Request Later</app-button>
        <app-button (buttonClick)="handleAnotherProvider()"
          [size]="'medium'">Requested Service with Another Provider</app-button>
        <app-button (buttonClick)="handleMyVehicleStarted()"
          [size]="'medium'">My Vehicle Started</app-button>
        <app-button (buttonClick)="handleNotWithMyVehicle()"
          [size]="'medium'">Not with my Vehicle or No Photo ID</app-button>
      </ng-container>
      <ng-template #postSubmissionCancel>
        <app-button (buttonClick)="handleMyVehicleStarted()"
          [size]="'medium'">My Vehicle Started</app-button>
        <app-button (buttonClick)="handleNotWithMyVehicle()"
          [size]="'medium'">Not with my Vehicle or No Photo ID</app-button>
        <app-button (buttonClick)="handleLongEta()"
          [size]="'medium'">Long ETA/Wait Time</app-button>
        <app-button (buttonClick)="handleFamilyAssist()"
          [size]="'medium'">Friend or Family Member Assisted Me</app-button>
        <app-button (buttonClick)="handleAnotherProvider()"
          [size]="'medium'">Requested Service with Another Provider</app-button>
      </ng-template>
      <div class="feedback-other">
        <form name="form"
          [ngClass]="{'error': !form.isValid }"
          novalidate
          [ngrxFormState]="form">
          <label for="otherComment"
            class="other-label">OTHER:</label>
          <div class="other-comment">
            <textarea class="note"
              name="otherComment"
              id="otherComment"
              placeholder="Tell us why you're canceling ..."
              [ngrxFormControlState]="form.controls.reason"
              aria-required="true"
              [maxLength]="256"
              required></textarea>
            <app-form-error [control]="form.controls.reason"
              name='otherComment'
              type='required'>Please, add a feedback comment.</app-form-error>
          </div>
        </form>
      </div>
      <app-button (buttonClick)="handleOther()"
        [disabled]="!form.isValid"> Submit Feedback </app-button>
    </div>

  </div>

  <button class="mat-dialog-close"
    aria-label="dismiss this message"
    (click)="onClose()">
    <icon-close></icon-close>
  </button>
</div>
