import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { environment as config } from 'src/environments/environment'
import { selectPreProdEnv } from '../ui/ui.selectors'

export const PREPROD_BASE_URL = 'https://gatewayers-preprod.national.aaa.com'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: any

  constructor(private store$: Store) {
    this.baseUrl$.subscribe((isPreProd) => {
      this.config = {
        ...config,
        baseURL: isPreProd ? PREPROD_BASE_URL : config.baseURL,
      }
    })
  }

  baseUrl$: Observable<boolean> = this.store$.pipe(select(selectPreProdEnv));

  getConfig() {
    return this.config
  }
}
