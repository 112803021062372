import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, Subscription, timer } from 'rxjs'
import { AAAStore } from 'src/app/store/root-reducer'
import { setClearIssue } from '../issue/issue.actions'
import { checkSessionTimeout } from './session.actions'
import { selectCheckSessionTimeoutInterval } from '../ui/timeout/timeout.selectors';

export enum TimerStatus {
  Stopped = 0,
  Running = 1,
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  sessionTimeoutTimer = null
  timerStatus = TimerStatus.Stopped
  sessionTimeoutTimerSubscription: Subscription = null
  checkSessionTimeoutInterval: number
  checkSessionTimeoutInterval$: Observable<number> = this.store$.select(selectCheckSessionTimeoutInterval)

  constructor(
    private store$: Store<AAAStore>,
  ) {
    this.checkSessionTimeoutInterval$.subscribe(interval => this.checkSessionTimeoutInterval = interval)
  }

  startSessionTimeout() {
    if (this.timerStatus === TimerStatus.Running) {
      return
    }

    this.sessionTimeoutTimer = timer(0, this.checkSessionTimeoutInterval)

    if (this.sessionTimeoutTimerSubscription) {
      this.sessionTimeoutTimerSubscription.unsubscribe()
    }

    this.sessionTimeoutTimerSubscription = this.sessionTimeoutTimer.subscribe(
      () => {
        this.store$.dispatch(checkSessionTimeout())
      }
    )

    this.timerStatus = TimerStatus.Running
  }

  stopSessionTimeout() {
    // Should be safe anyway, but don't repeat the stop operation twice.
    if (this.timerStatus === TimerStatus.Stopped) {
      return
    }

    if (this.sessionTimeoutTimerSubscription) {
      this.sessionTimeoutTimerSubscription.unsubscribe()
    }

    this.sessionTimeoutTimer = null

    this.timerStatus = TimerStatus.Stopped
  }

  clearStateSessionTimeOut() {
    this.store$.dispatch(setClearIssue());
    (window as any).aaa_gtm_prod?.push({path: null})
  }
}
