import 'hammerjs'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.optimize) {
  enableProdMode()
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      loadGA();
      loadHotjar()
    })
    .catch((err) => console.error(err))
})

function loadGA() {
  const dataLayer = window['dataLayer']
  if (dataLayer && dataLayer.length) {
    const loaded = dataLayer.find((events) => events.event === 'gtm.load')
    if (loaded) {
      dataLayer.push({ event: 'optimize.activate' })
    } else {
      sleep(loadGA, 0)
    }
  } else {
    sleep(loadGA, 0)
  }
}

const loadHotjar = () => ((h, o, t, j, a, r) => {
  h.hj = h.hj ||
    function() {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: environment.hotjar, hjsv: 6 };
  a = o.getElementsByTagName('head')[0];
  r = o.createElement('script');
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);

  const scripts = document.getElementsByTagName('script');
  const hotjarSourceUrl = 'https://static.hotjar.com/c/hotjar-2789441.js?sv=6'
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === hotjarSourceUrl && environment.configTools) {
      console.log('HotJar loaded.');
    }
  }
})(window as any, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

function sleep(action, time) {
  setTimeout(() => action(), time)
}
