import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../ui.types'
import { Store, select } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { Observable } from 'rxjs/internal/Observable'
import { VehicleData } from 'src/app/modules/member/member.types'
import { selectCallVehicle } from 'src/app/modules/dashboard/calls-statuses/call-status.selectors'
import { selectActiveBatteryQuotes } from 'src/app/modules/quotes/quotes.selectors'
@Component({
  selector: 'app-battery-quotes-dialog',
  templateUrl: './battery-quotes-dialog.component.html',
  styleUrls: ['./battery-quotes-dialog.component.scss'],
})
export class BatteryQuotesDialogComponent {
  batteryQuotes$ = this.store$.pipe(select(selectActiveBatteryQuotes))

  vehicle$: Observable<VehicleData> = this.store$.pipe(
    select(selectCallVehicle)
  )

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>
  ) {}
}
