import { RESET_LOADING } from './loading.actions';

const initialState = {}

export interface LoadingState {
  [action: string]: boolean
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case '@ngrx/effects/init':
      return {}
    case RESET_LOADING:
      return {...initialState}
    default:
      return { ...state }
  }
}
