import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { CallStatusService } from '../../dashboard/calls-statuses/call-status.service'
import { selectIsAnyRequestLoading } from '../../ui/loading/loading.selectors'
import { openErrorDialog } from '../../ui/ui.actions'
import { selectIsAnyDialogActive } from '../../ui/ui.selectors'
import { ErrorDialogTypes } from '../../ui/ui.types'
import { resetAuth } from '../auth.actions'
import { selectIsRapTokenAccess, selectIsSignedIn } from '../auth.selectors'
import { buildTitle } from '../../../shared/utils/title'
import { Title } from '@angular/platform-browser'
import { resetLoading } from '../../ui/loading/loading.actions'
import { MemberTypesFromMessage } from '../../member/member.types'
import { resetBreakdownLocation, resetLastSearchLocation } from '../../location/location.actions'
import { cancelEditingRequest } from '../../../shared/actions/shared.actions'
import { resetCallStatus } from '../../dashboard/calls-statuses/call-status.actions'

@Injectable({
  providedIn: 'root',
})
export class AuthSecurityWrapperService {
  isSignedIn: boolean
  isAnyDialogActive: boolean
  isAnyRequestLoading: boolean
  isRapTokenAccess: boolean

  selectisSignedIn$: Observable<boolean> = this.store$.pipe(
    select(selectIsSignedIn)
  )
  selectIsAnyDialogActive$: Observable<boolean> = this.store$.pipe(
    select(selectIsAnyDialogActive)
  )
  selectIsAnyRequestLoading$: Observable<boolean> = this.store$.pipe(
    select(selectIsAnyRequestLoading)
  )

  selectIsRapTokenAccess$: Observable<boolean> = this.store$.pipe(
    select(selectIsRapTokenAccess)
  )

  constructor(
    private store$: Store,
    private router: Router,
    private callStatusService: CallStatusService,
    private titleService: Title
  ) {
    this.selectisSignedIn$.subscribe(
      (isSignedIn) => (this.isSignedIn = isSignedIn)
    )
    this.selectIsAnyDialogActive$.subscribe(
      (isAnyDialogActive) => (this.isAnyDialogActive = isAnyDialogActive)
    )
    this.selectIsAnyRequestLoading$.subscribe(
      (isAnyRequestLoading) => (this.isAnyRequestLoading = isAnyRequestLoading)
    )
    this.selectIsRapTokenAccess$.subscribe(
      (isRapTokenAccess) => (this.isRapTokenAccess = isRapTokenAccess)
    )
  }

  logout(): void {
    this.callStatusService.stopCallStatusesUpdater()
    this.store$.dispatch(resetAuth())
    this.router.navigate(['auth/signin'], { queryParams: {} })
    this.titleService.setTitle(buildTitle('Sign In', this.isRapTokenAccess))
  }

  forceLogout(reason?: string): void {
    const isSignedIn = this.isSignedIn
    this.logout()

    let disableClose
    if (this.isRapTokenAccess) {
      disableClose = {
        disableClose: this.isRapTokenAccess,
        params: {
          hideCloseButton: this.isRapTokenAccess,
        }
      }
    }

    if (isSignedIn) {
      this.store$.dispatch(
        openErrorDialog({
          payload: {
            type: ErrorDialogTypes.GENERIC,
            content: 'We are having trouble verifying your account, please attempt to re-verify. If the problem persists please contact us:',
            ...disableClose,
          },
        })
      )
    } else if (!this.isAnyDialogActive && !this.isAnyRequestLoading && (!reason || reason.toLowerCase() !== MemberTypesFromMessage.INVALID_TOKEN)) {
      this.store$.dispatch(
        openErrorDialog({
          payload: {
            type: ErrorDialogTypes.AUTH,
            ...disableClose,
          }
        })
      )
    }
    this.store$.dispatch(resetCallStatus())
    this.store$.dispatch(resetLoading())
    this.store$.dispatch(resetBreakdownLocation())
    this.store$.dispatch(resetLastSearchLocation())
    this.store$.dispatch(cancelEditingRequest({ redirect: false }))
  }
}
