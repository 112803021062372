import { PayloadedAction } from 'src/app/shared/types'
import { Uuid } from 'src/app/shared/utils/uuid'

export interface SessionState {
  lastInteraction: number,
  userSessionId: string,
}

const uuid = new Uuid()

export const initialState = {
  lastInteraction: null,
  userSessionId: uuid.getV4() as string
}

export function reducer(state = initialState, action: PayloadedAction) {
  switch (action.type) {
    default:
      return state
  }
}
