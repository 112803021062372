import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { RouterNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store'
import { Action, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import {
  catchError,
  debounceTime,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { selectIsVehicleChangeAllowed } from '../modules/auth/auth.selectors'
import {
  CALL_STATUS,
  notifyCallStatusFailure,
} from '../modules/dashboard/calls-statuses/call-status.actions'
import { selectCallsStatusesData } from '../modules/dashboard/calls-statuses/call-status.selectors'
import {
  ANIMATE_SPLASHSCREEN_HIDE,
  DIALOG_CLOSE,
  hideSplashscreen,
  notifyTransitionEnded,
  removeVehicleStep,
  requestRouterNavigate,
  REQUEST_ROUTER_NAVIGATE,
  setSplashscreenStep,
  TRANSITION,
  transitionStart,
} from '../modules/ui/ui.actions'
import {
  selectIsTransitioning,
  selectSplashscreenIsAnimatingOut,
  selectSplashscreenIsVisible,
} from '../modules/ui/ui.selectors'
import { ErrorDialogTypes, MessageDialogTypes, PromptDialogTypes, StepTypes } from '../modules/ui/ui.types'
import { AAAStore } from '../store/root-reducer'
import { selectUrl } from '../store/router.selectors'
import {
  cancelEditingRequest,
  CANCEL_EDITING_REQUEST,
} from './actions/shared.actions'
import { ErrorReportingService } from './services/error-reporting.service'
import {
  NavigationEvent,
  PayloadedAction,
  SIGNIN_URL,
  TransitionEvent,
  TRANSITION_EVENT_DISPATCH_DELAY,
} from './types'

@Injectable()
export class SharedEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store<AAAStore>,
    private errorReportingService: ErrorReportingService
  ) {}

  private IGNORE_DIALOGS_TYPES = [
    ErrorDialogTypes.GENERIC,
    MessageDialogTypes.CUSTOM,
    MessageDialogTypes.LOCATION_SERVICES_REQUIRED,
    MessageDialogTypes.TOWING_DISTANCE_LIMIT,
    MessageDialogTypes.TOW_BOUNDARY_MESSAGE,
    MessageDialogTypes.RAP_TOW_MILEAGE_MESSAGE,
    MessageDialogTypes.ADVISORY,
    PromptDialogTypes.CONFIRM_VEHICLE_DELETE,
    PromptDialogTypes.CONFIRM_CANCEL_CALL,
    PromptDialogTypes.ADDITIONAL_AUTH_SEARCH,
    PromptDialogTypes.ADDITIONAL_LOCATION_INFO,
    PromptDialogTypes.SHOW_BATTERY_QUOTES,
    PromptDialogTypes.BATTERY_QUOTES,
    PromptDialogTypes.TOWING_DISTANCE_WARN,
    PromptDialogTypes.AUTH_PHONE_NUMBER_DIALOG
  ]

  cancelNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof cancelEditingRequest>>(CANCEL_EDITING_REQUEST),
        switchMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectCallsStatusesData)),
            filter(([{ payload: { redirect } }, data]) => redirect && Object.keys(data).length === 0),
            tap(() =>
              this.router.navigate(['steps'], {
                queryParams: { step: StepTypes.BREAKDOWN_LOCATION, cancel: 'true' },
              })
            ),
            catchError((error) =>
              from(this.errorReportingService.notifyError(error))
            ),
            withLatestFrom(this.store$.select(selectIsVehicleChangeAllowed)),
            filter(([_, isVehicleChangeAllowed]) => !isVehicleChangeAllowed),
            tap(() => this.store$.dispatch(removeVehicleStep()))
          )
        )
      ),
    { dispatch: false }
  )

  leadToDashboardOnActiveCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CALL_STATUS.SUCCESS),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectUrl)),
          filter(
            ([payloadedAction, currentUrl]: [PayloadedAction, string]) =>
              currentUrl.indexOf('dashboard') === -1 &&
              !!Object.keys(payloadedAction.payload.data).length
          ),
          map(
            () =>
              transitionStart({
                payload: {
                  dispatch: [
                    requestRouterNavigate({ payload: { to: ['dashboard'] } }),
                  ],
                },
              }),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                notifyCallStatusFailure
              )
            )
          )
        )
      )
    )
  )

  dispatchActionsOnTransitionStart$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TRANSITION.REQUEST),
        filter(
          (action: PayloadedAction<TransitionEvent>) =>
            !!action.payload.dispatch && action.payload.dispatch.length > 0
        ),
        mergeMap((action: PayloadedAction<TransitionEvent>) =>
          of(action.payload.dispatch).pipe(
            delay(
              !!action.payload.delay
                ? action.payload.delay
                : TRANSITION_EVENT_DISPATCH_DELAY
            )
          )
        ),
        mergeMap((list) => list)
      )
  )

  switchOffTransitioningOnRouteEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
      withLatestFrom(this.store$.select(selectIsTransitioning)),
      filter(([_, isTransitioning]: [never, boolean]) => isTransitioning),
      map(() => notifyTransitionEnded())
    )
  )

  hideSplashscreenForOtherRoutes$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
        withLatestFrom(
          this.store$.select(selectSplashscreenIsVisible),
          this.store$.select(selectSplashscreenIsAnimatingOut)
        ),
        filter(
          ([action, visible, animatingToHide]: [
            RouterNavigatedAction,
            boolean,
            boolean
          ]) =>
            !action.payload.routerState.url.startsWith(`/${SIGNIN_URL}`) &&
            visible &&
            !animatingToHide
        ),
        map(() => hideSplashscreen())
      )
  )

  requestRouterNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(REQUEST_ROUTER_NAVIGATE),
        tap((action: PayloadedAction<NavigationEvent>) =>
          this.router.navigate(action.payload.to, action.payload.extras)
        )
      ),
    { dispatch: false }
  )

  hideSplashscreenAnimating$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ANIMATE_SPLASHSCREEN_HIDE),
        withLatestFrom(
          this.store$.select(selectSplashscreenIsVisible),
          this.store$.select(selectSplashscreenIsAnimatingOut)
        ),
        filter(
          ([_, visible, animatingToHide]: [never, boolean, boolean]) =>
            visible && animatingToHide
        ),
        map(() => {
          this.animateHideSplashscreen()
          return setSplashscreenStep({ payload: 2 })
        })
      )
  )

  scrollToTop$ = createEffect(
    () => this.actions$.pipe(
      ofType(DIALOG_CLOSE),
      filter((action: PayloadedAction) => {
        const _type = action.payload.type
        const scrollToTopOnClose = action.payload.scrollToTopOnClose
        return this.IGNORE_DIALOGS_TYPES.indexOf(_type) === -1 || scrollToTopOnClose
      }),
      debounceTime(10),
      tap(() => window.scrollTo(0, 0))
    ),
    { dispatch: false }
  )

  animateHideSplashscreen(miliseconds = 300) {
    setTimeout(() => {
      this.store$.dispatch(setSplashscreenStep({ payload: 3 }))
    }, miliseconds / 2)
    setTimeout(() => {
      this.store$.dispatch(hideSplashscreen())
    }, miliseconds)
  }
}
