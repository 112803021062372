import {
  createFormGroupState,
  formGroupReducer,
  FormGroupState,
  ResetAction,
  updateGroup,
  validate,
} from 'ngrx-forms'
import { required, minLength, maxLength, pattern, greaterThan } from 'ngrx-forms/validation'
import { Action, createAction } from '@ngrx/store'
import { AUTH_RESET } from '../../auth/auth.actions'
import { CANCEL_EDITING_REQUEST } from 'src/app/shared/actions/shared.actions'

export interface AuthFormsState {
  auth: FormGroupState<MembershipNumberAuthFormState>
  nameAuth: FormGroupState<NameAuthFormState>
  vinMileageAuth: FormGroupState<VinMileageAuthFormState>
  carRentalAuth: FormGroupState<CarRentalAuthFormState>
  subscriberNumberAuth: FormGroupState<SubscriberNumberAuthFormState>
  mobilePhoneAuth: FormGroupState<MobilePhoneAuthFormState>
  phoneNumberAuth: FormGroupState<PhoneNumberFormState>
  cancelFeedbackOthers: FormGroupState<CancelFeedbackOthersFormState>
}
export interface CallRequestFormsState {
  additionalLocationInfo: FormGroupState<AdditionalLocationInfoFormState>
}
export type FormsState = AuthFormsState & CallRequestFormsState

export type CancelFeedbackOthersFormInitialState = Pick<AuthFormsState, 'cancelFeedbackOthers'>;


export const AUTH_FORM = 'form.auth'
export const NAME_AUTH_FORM = 'form.nameauth'
export const MOBILE_PHONE_AUTH_FORM = 'form.mobilePhoneAuth'
export const ADDITIONAL_LOCATION_INFO_FORM = 'form.additionalLocationInfo'
// TODO: ngrx-forms' main benefit is that they are part of the state.
// ngrx-forms handles state.forms automatically, you'll find the above 3 forms there.
// Somehow, these forms are hacked within state.submit. Must be moved here with the rest.
export const USER_CONTACT_INFO_FORM = 'form.contactInfo'
export const PASSENGER_INFO_FORM = 'form.passengers'
export const VIN_MILEAGE_FORM = 'form.vinMileage'
export const CAR_RENTAL_FORM = 'form.carRental'
export const SUBSCRIBER_NUMBER_FORM = 'form.subscriberNumber'
export const PHONE_NUMBER_AUTH_FORM = 'form.phoneNumberAuth'
export const CANCEL_FEEDBACK_OTHERS_FORM = 'form.cancelFeedbackOthers'
export const RESET_CANCEL_FEEDBACK_OTHERS_FORM = 'RESET_CANCEL_FEEDBACK_OTHERS_FORM'

export const resetOthersForm = () => new ResetAction(CANCEL_FEEDBACK_OTHERS_FORM)
export const setResetCancelFeedbackOthersForm = createAction(RESET_CANCEL_FEEDBACK_OTHERS_FORM)

export interface MembershipNumberAuthFormState {
  membershipNumber: string
  zipCode: string
}

export interface NameAuthFormState {
  firstName: string
  lastName: string
  zipCode: string
}

export interface MobilePhoneAuthFormState {
  phoneNumber: string
  zipCode: string
}

export interface PhoneNumberFormState {
  phoneNumber: string
}

export interface CancelFeedbackOthersFormState {
  reason: string
}

export interface VinMileageAuthFormState {
  vinNumber: string
  mileage: number
}

export interface CarRentalAuthFormState {
  refNumber: number
}

  export interface SubscriberNumberAuthFormState {
  subscriber: number
}

export interface AdditionalLocationInfoFormState {
  breakdownComment: string
}

const validateAuthForm = updateGroup<MembershipNumberAuthFormState>({
  membershipNumber: validate(
    required,
    pattern(/^\d{3}\s{2}\d{3}\s{2}\d{9}\s{2}\d$/)
  ),
  zipCode: validate(required, minLength(5), maxLength(5)),
})

const validateNameAuthForm = updateGroup<NameAuthFormState>({
  firstName: validate(required),
  lastName: validate(required),
  zipCode: validate(required, minLength(5), maxLength(5)),
})

const validateMobilePhoneAuthForm = updateGroup<MobilePhoneAuthFormState>({
  phoneNumber: validate(required),
  zipCode: validate(required, minLength(5), maxLength(5)),
})

const validatePhoneNumberAuthForm = updateGroup<PhoneNumberFormState>({
  phoneNumber: validate(required, pattern(/^\(\d{3}\)\s{2}\d{3}\s{2}\d{4}$/)),
})

const validateVinMileageAuthForm = updateGroup<VinMileageAuthFormState>({
  vinNumber: validate(required, pattern(/^[a-zA-Z0-9]{17}$/)),
  mileage: validate(required, greaterThan(0)),
})

const validateAdditionalLocationInfoForm =
  updateGroup<AdditionalLocationInfoFormState>({
    breakdownComment: validate(required),
  })

const validateCarRentalAuthForm = updateGroup<CarRentalAuthFormState>({
  refNumber: validate(required)
})

const validateSubscriberNumberAuthForm = updateGroup<SubscriberNumberAuthFormState>({
  subscriber: validate(required)
})

const validateCancelFeedbackOthersForm = updateGroup<CancelFeedbackOthersFormState>({
  reason: validate(required)
})

export function createAuthFormInitialState(): AuthFormsState {
  return {
    auth: createFormGroupState<MembershipNumberAuthFormState>(AUTH_FORM, {
      membershipNumber: '',
      zipCode: '',
    }),
    nameAuth: createFormGroupState<NameAuthFormState>(NAME_AUTH_FORM, {
      firstName: '',
      lastName: '',
      zipCode: '',
    }),
    vinMileageAuth: createFormGroupState<VinMileageAuthFormState>(VIN_MILEAGE_FORM, {
      vinNumber: '',
      mileage: 0,
    }),
    carRentalAuth: createFormGroupState<CarRentalAuthFormState>(CAR_RENTAL_FORM, {
      refNumber: null,
    }),
    subscriberNumberAuth: createFormGroupState<SubscriberNumberAuthFormState>(SUBSCRIBER_NUMBER_FORM, {
      subscriber: null,
    }),
    mobilePhoneAuth: createFormGroupState<MobilePhoneAuthFormState>(MOBILE_PHONE_AUTH_FORM, {
      phoneNumber: '',
      zipCode: ''
    }),
    phoneNumberAuth: createFormGroupState<PhoneNumberFormState>(PHONE_NUMBER_AUTH_FORM, {
      phoneNumber: ''
    }),
    cancelFeedbackOthers: createFormGroupState<CancelFeedbackOthersFormState>(CANCEL_FEEDBACK_OTHERS_FORM, {
      reason: ''
    }),
  }
}

export function createCallFormInitialState(): CallRequestFormsState {
  return {
    additionalLocationInfo:
      createFormGroupState<AdditionalLocationInfoFormState>(
        ADDITIONAL_LOCATION_INFO_FORM,
        {
          breakdownComment: '',
        }
      ),
  }
}

export function createOthersFormInitialState(): CancelFeedbackOthersFormInitialState {
  return {
    cancelFeedbackOthers:
      createFormGroupState<CancelFeedbackOthersFormState>(
        CANCEL_FEEDBACK_OTHERS_FORM,
        { reason: '' }
      ),
  }
}

export function createInitialState(): FormsState {
  return {
    ...createAuthFormInitialState(),
    ...createCallFormInitialState(),
  }
}

export function reducer(
  state: FormsState = createInitialState(),
  action: Action
): FormsState {
  if (action.type === AUTH_RESET) {
    state = {
      ...state,
      ...createAuthFormInitialState(),
    }
  }

  if (action.type === CANCEL_EDITING_REQUEST) {
    state = {
      ...state,
      ...createCallFormInitialState(),
    }
  }

  const auth = validateAuthForm(formGroupReducer(state.auth, action))
  if (auth !== state.auth) {
    state = {
      ...state,
      auth,
    }
  }

  const nameAuth = validateNameAuthForm(
    formGroupReducer(state.nameAuth, action)
  )
  if (nameAuth !== state.nameAuth) {
    state = {
      ...state,
      nameAuth,
    }
  }

  const mobilePhoneAuth = validateMobilePhoneAuthForm(
    formGroupReducer(state.mobilePhoneAuth, action)
  )
  if (mobilePhoneAuth !== state.mobilePhoneAuth) {
    state = {
      ...state,
      mobilePhoneAuth,
    }
  }

  const phoneNumberAuth = validatePhoneNumberAuthForm(
    formGroupReducer(state.phoneNumberAuth, action)
  )
  if (mobilePhoneAuth !== state.phoneNumberAuth) {
    state = {
      ...state,
      phoneNumberAuth,
    }
  }

  const vinMileageAuth = validateVinMileageAuthForm(formGroupReducer(state.vinMileageAuth, action))
  if (vinMileageAuth !== state.vinMileageAuth) {
    state = {
      ...state,
      vinMileageAuth,
    }
  }

  const carRentalAuth = validateCarRentalAuthForm(formGroupReducer(state.carRentalAuth, action))
  if (carRentalAuth !== state.carRentalAuth) {
    state = {
      ...state,
      carRentalAuth
    }
  }

  const subscriberNumberAuth = validateSubscriberNumberAuthForm(formGroupReducer(state.subscriberNumberAuth, action))
  if (subscriberNumberAuth !== state.subscriberNumberAuth) {
    state = {
      ...state,
      subscriberNumberAuth
    }
  }

  const additionalLocationInfo = validateAdditionalLocationInfoForm(
    formGroupReducer(state.additionalLocationInfo, action)
  )
  if (additionalLocationInfo !== state.additionalLocationInfo) {
    state = {
      ...state,
      additionalLocationInfo,
    }
  }

  const cancelFeedbackOthers = validateCancelFeedbackOthersForm(
    formGroupReducer(state.cancelFeedbackOthers, action)
  )
  if (cancelFeedbackOthers !== state.cancelFeedbackOthers) {
    state = {
      ...state,
      cancelFeedbackOthers,
    }
  }
  if (action.type === RESET_CANCEL_FEEDBACK_OTHERS_FORM) {
    state = {
      ...state,
      ...createOthersFormInitialState()
    }
  }

  return state
}
