import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { EffectsModule } from '@ngrx/effects'
import { NgModule, ErrorHandler } from '@angular/core'
import { State, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '../environments/environment'

import { AppComponent } from './app.component'
import { AuthEffects } from './modules/auth/auth.effects'
import { ModeConfigurationEffects } from './modules/auth/mode-configuration.effects'
import { HeaderComponent } from './shared/layouts/header/header.component'
import { NotFoundComponent } from './not-found.component'
import { metaReducers } from './store/meta'
import { reducers } from './store/root-reducer'
import { HttpClientModule } from '@angular/common/http'

import { SharedModule } from './shared'
import { IssueEffects } from './modules/issue/issue.effects'
import { AppRoutingModule } from './app-routing.module'
import { WizardEffects } from './modules/wizard/wizard.effects'
import { VehicleEffects } from './modules/vehicle/vehicle.effects'
import { MemberEffects } from './modules/member/member.effects'
import { SubmitEffects } from './modules/submit/submit.effects'
import { AarEffects } from './modules/location/aar/aar.effects'
import { SharedEffects } from './shared/shared.effects'
import { CallEffects } from './modules/dashboard/calls.effects'
import { CallsStatusesEffect } from './modules/dashboard/calls-statuses/call-status.effects'
import { ClubEffects } from './modules/dashboard/clubs/clubs.effects'
import { TaggingEffects } from './modules/tagging/tagging.effects'
import { UIEffects } from './modules/ui/ui.effects'
import { QuotesEffects } from './modules/quotes/quotes.effects'
import { ServicingClubEffects } from './modules/servicing-club/servicing-club.effects'
import { SessionEffects } from './modules/session/session.effects'
import { ConfigEffects } from './modules/config/config.effects'
import { AdvisoriesEffects } from './modules/advisories/advisories.effects'
import { RouteDistanceEffects } from './modules/route-distance/route-distance.effects'
import { ReviewEffects } from './modules/location/aar/review/review.effects'
import { LocationEffects } from './modules/location/location.effects';

const providersArray = []

export class DefaultErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    throw err
  }
}

providersArray.push({
  provide: ErrorHandler,
  useClass: DefaultErrorHandler,
})

providersArray.push({ provide: State, useValue: null })


export const APP_ID = 'drr-v2'

@NgModule({
  declarations: [HeaderComponent, AppComponent, NotFoundComponent],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: APP_ID }),
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: false,
      },
    }),
    EffectsModule.forRoot([
      SessionEffects,
      AarEffects,
      ReviewEffects,
      LocationEffects,
      AuthEffects,
      ModeConfigurationEffects,
      IssueEffects,
      MemberEffects,
      SubmitEffects,
      SharedEffects,
      TaggingEffects,
      VehicleEffects,
      WizardEffects,
      CallEffects,
      CallsStatusesEffect,
      ClubEffects,
      UIEffects,
      QuotesEffects,
      ServicingClubEffects,
      ConfigEffects,
      AdvisoriesEffects,
      RouteDistanceEffects,
    ]),
    SharedModule,
    StoreDevtoolsModule.instrument({
      maxAge: 30,
      logOnly: environment.log,
    }),
  ],
  exports: [AppRoutingModule],
  providers: [...providersArray],
  bootstrap: [AppComponent],
})
export class AppModule {}
