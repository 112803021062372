import { Store, select } from '@ngrx/store'
import { Component, OnInit } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { combineLatest, Observable } from 'rxjs'
import { AbstractComponent } from 'src/app/shared/abstract.component'
import {
  selectIsImproveUserNavigation,
  selectIsStepCancelable,
  selectShowMenu,
} from 'src/app/modules/ui/ui.selectors'
import { selectIsSignedIn } from 'src/app/modules/auth/auth.selectors'
import { selectRouterStep } from 'src/app/store/router.selectors'
import {
  selectCanCancelActiveCall,
  selectHasAvailableCall
} from 'src/app/modules/dashboard/calls-statuses/call-status.selectors'
import { filter, map } from 'rxjs/operators'
import { Event, NavigationEnd, Router } from '@angular/router'
import { OEMLogoData } from '../../utils/branding'
import { RapService } from 'src/app/modules/rap/rap.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends AbstractComponent implements OnInit {
  toggleOpenClass = false

  isVisible = true
  canCancelLiveCall = false

  showLogo = false
  isCancelExpanded = false
  appIdLogo;

  constructor(
    private store$: Store<AAAStore>,
    private router: Router,
    private rapService: RapService,
  ) {
    super()
  }

  navigationEndEvent$: Observable<Event> = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd)
  )

  ngOnInit(): void {
    this.navigationEndEvent$.subscribe(() => {
      this.showLogo = true;
      if (this.rapService.isRapUser()) {
        this.appIdLogo = OEMLogoData[this.rapService.getRapAppId()];
      } else {
        this.appIdLogo = {
          width: '47',
          height: '30',
          className: 'aaa-logo',
          fileName: 'logo',
          alt: 'AAA'
        }
      }
    });
  }

  showMenu$: Observable<any> = this.store$.pipe(select(selectShowMenu))

  isImproveUserNavigation$: Observable<boolean> = this.store$.pipe(select(selectIsImproveUserNavigation))

  isSignedIn$: Observable<boolean> = this.store$.pipe(select(selectIsSignedIn))

  hasAvailableCall$: Observable<boolean> = this.store$.pipe(
    select(selectHasAvailableCall)
  )

  canCancel$: Observable<boolean> = combineLatest([
    this.isSignedIn$,
    this.store$.select(selectRouterStep),
    this.store$.select(selectCanCancelActiveCall),
    this.store$.select(selectIsStepCancelable)
  ]).pipe(
    map(([isSignedIn, step, canCancelActiveCall, isStepCancelable]) => {
      if (!isSignedIn) {
        return false
      }
      if (step) {
        return isStepCancelable
      } else {
        this.canCancelLiveCall = false
        this.canCancelLiveCall = canCancelActiveCall

        return this.canCancelLiveCall
      }
    })
  )

  cancelExpanded(expanded: boolean) {
    this.isCancelExpanded = expanded
  }
}
