import { OEMBranding } from 'src/app/modules/auth/auth.types';
import { getCookie } from './cookies';

export const OEMLogoData = {
  [OEMBranding.HYUNDAI]: {
    width: '120',
    className: `${OEMBranding.HYUNDAI.toLowerCase()}-logo`,
    fileName: 'hyundai_logo',
    alt: OEMBranding.HYUNDAI
  },
  [OEMBranding.GENESIS]: {
    width: '120',
    className: `${OEMBranding.GENESIS.toLowerCase()}-logo`,
    fileName: 'genesis_logo',
    alt: OEMBranding.GENESIS
  },
  [OEMBranding.HONDA]: {
    width: '120',
    className: `${OEMBranding.HONDA.toLowerCase()}-logo`,
    fileName: 'honda_logo',
    alt: OEMBranding.HONDA
  },
  [OEMBranding.ACURA]: {
    width: '120',
    className: `${OEMBranding.ACURA.toLowerCase()}-logo`,
    fileName: 'acura_logo',
    alt: OEMBranding.ACURA
  },
  [OEMBranding.BLUESKYNM]: {
    width: '55',
    className: `${OEMBranding.BLUESKYNM.toLowerCase()}-logo`,
    fileName: 'blueskynm_logo',
    alt: OEMBranding.BLUESKYNM
  },
}

export const getOemBrandingId = (url: string): string => {
  let oemBrandingAppId: string

  const appId = getCookie('AAA_AppId');
  if (appId && appId.toUpperCase() in OEMBranding) {
    oemBrandingAppId = appId
  }

  if (!oemBrandingAppId) {
    for (let key in OEMBranding) {
      if (url.toUpperCase().indexOf(key) > -1) {
        oemBrandingAppId = key
      }
    }
  }

  return oemBrandingAppId;
}
