<div class="dialog">
  <h1 mat-dialog-title>Alert</h1>

  <div class="dialog-content">
    <p class="text-message">The tow location you have selected is more than 100 miles away. Please verify your towing
      destination. If the address is accurate and you'd like to proceed with this destination, please give us a
      call.</p>
    <div class="dialog-actions">
      <app-call-aaa-button
        [clickEventPage]="pageType"
        (buttonClick)="onCallAAAClick()">
      </app-call-aaa-button>

      <app-link-button
        class="dismiss-button"
        (clickLink)="onClose()"
        [ariaLabel]="'dismiss this message'"
      >
        Dismiss
      </app-link-button>
    </div>
  </div>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
