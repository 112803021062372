import { Component, Inject, AfterViewInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import { CallStatusService } from 'src/app/modules/dashboard/calls-statuses/call-status.service'
import events from 'src/app/modules/tagging/events'
import { RapService } from 'src/app/modules/rap/rap.service'

@Component({
  selector: 'app-call-duplicate-dialog-component',
  templateUrl: './call-duplicate-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class CallDuplicateDialogComponent implements AfterViewInit {
  pageType = events.submit.SUMMARY_PAGE_TYPE

  isRapUser = this._rapService.isRapUser()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _callStatusService: CallStatusService,
    private _taggingService: TaggingService,
    private _rapService: RapService
  ) {}

  ngAfterViewInit() {
    this._taggingService.setClickEvent(
      events.submit.SUBMIT_DUPLICATE_PROMPT,
      events.submit.SUMMARY_PAGE_TYPE
    )
  }

  onSubmit() {
    this._callStatusService.startCallStatusesUpdater({ retry: true })
    this.onClose()
  }

  onClose() {
    this.data.close()
  }

  onCallAAAClick() {
    this.onClose()
  }
}
