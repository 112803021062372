<div class="dialog">
  <h1 mat-dialog-title id="dialog-title">{{ data.title }}</h1>
  <app-safehtml
    id="dialog-content"
    mat-dialog-content
    [content]="data.content"
  ></app-safehtml>
  <div mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="data.params?.tagType"
      (buttonClick)="onClose()"
      [ariaLabel]="'Contact us'">
    </app-call-aaa-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
