import {
  COORDS_LOOKUP,
  ADDRESS_LOOKUP,
  HOME_ADDRESS_LOOKUP,
  RESET_TOW_DESTINATION,
  SET_AAR_ADDRESS,
  SET_TOW_DESTINATION,
  SET_TOW_DESTINATION_MARKER,
  SET_LAST_TOW_TO_SEARCH_LOCATION,
  SET_TOWING_STEP
} from './tow-location.actions'
import { CANCEL_EDITING_REQUEST } from 'src/app/shared/actions/shared.actions'
import { AARData } from '../aar/aar.types'
import { LastSearchLocation, LocationWithMarker, TowLocation } from '../location.types'
import { FSA } from 'src/app/shared/types'

export type TowLocationState = TowLocation & {
  step: string
  preview: LocationWithMarker
  aar?: AARData
  lastSearchLocation?: LastSearchLocation,
}

export const initialState: TowLocationState = {
  step: '',
  preview: null,
  address: '',
  name: '',
  serviceProviderCode: '',
  location: '',
  latitude: '',
  longitude: '',
  streetNumber: '',
  streetName: '',
  city: '',
  state: '',
  landmark: '',
  lastSearchLocation: null
}

export function reducer(
  state: TowLocationState = initialState,
  action: FSA
): TowLocationState {
  switch (action.type) {
    case COORDS_LOOKUP.SUCCESS:
    case ADDRESS_LOOKUP.SUCCESS:
    case HOME_ADDRESS_LOOKUP.SUCCESS:
    case SET_AAR_ADDRESS:
      return {
        ...state,
        preview: action.payload,
      }
    case SET_TOW_DESTINATION.SUCCESS:
      return {
        ...state,
        ...action.payload,
        preview: null,
      }
    case SET_TOW_DESTINATION_MARKER:
      return {
        ...state,
        preview: {
          ...state.preview,
          marker: action.payload,
        },
      }
    case RESET_TOW_DESTINATION:
      return {
        ...initialState,
        lastSearchLocation: state.lastSearchLocation ? state.lastSearchLocation : null
      }
    case CANCEL_EDITING_REQUEST:
      return {
        ...initialState,
      }
    case SET_LAST_TOW_TO_SEARCH_LOCATION:
      return {
        ...state,
        lastSearchLocation: action.payload
      }
    case SET_TOWING_STEP:
      return {
        ...state,
        step: action.payload.step,
      }
    default:
      return state
  }
}
