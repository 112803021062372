export default {
  SERVICE_TRACKING_PAGE_TYPE: 'Service Tracking',
  SERVICE_TRACKING_PAGE_PROMPT: 'Service tracking screen displayed',
  SHOW_BATTERY_QUOTES_CLICK: 'Battery Quote Request',
  CALL_STATUS_PAGE_TYPE: 'DRR Request Received',
  CALL_STATUS_RECEIVED_SUCCESS: 'RSO Request Received',
  CALL_STATUS_RECEIVED_SUCCESS_DUPLICATED: 'RSO Request Received - Duplicated',
  CALL_STATUS_ENROUTE_SUCCESS: 'RSO Request Enroute',
  CALL_STATUS_ONLOCATION_SUCCESS: 'RSO Request on Location',
  CALL_CANCEL_REQUEST: 'RSO Cancel Request',
  CALL_CANCEL_SUCCESS: 'RSO Cancel Success',
  CALL_CANCEL_FAILURE: 'RSO Cancel Failed',
  PARTIAL_CALL_CANCELED: 'Partial Call Canceled',
  BATTERY_QUOTES_SHOW_CLICK: 'Get battery quote selected',
  BATTERY_QUOTES_DECLINE_CLICK: 'Battery Quote Declined',
  BATTERY_QUOTES_REQUEST: 'Battery Quote Request',
  BATTERY_QUOTES_SUCCESS: 'Battery Quote Success',
  BATTERY_QUOTES_FAILURE: 'Battery Quote failed - No Quotes',
  BATTERY_QUOTES_NO_PRICE: 'Battery Quote Failed - N/A Price',
  CALL_STATUS_ERROR_PAGE_TYPE: 'Call Status Error Popup',
  CALL_STATUS_ERROR_PROMPT: 'Call Status Error Popup Prompt',
  PRE_SUBMISSION_CANCEL_SURVEY: 'Pre-Submission Cancel Survey',
  POST_SUBMISSION_CANCEL_SURVEY: 'Post-Submission Cancel Survey',
}
