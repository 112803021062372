import { Component, Input } from '@angular/core';
import { TaggingService } from '../../tagging/tagging.service';
import events from '../../tagging/events';
import { Store, select } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { setBreakdownLocationCenter, setHomeCenter, setShopTabView } from '../../location/aar/aar.actions'
import { selectShopTabView } from '../../location/aar/aar.selectors';
import { Observable } from 'rxjs';
import { ShopsTabLabel } from '../../location/aar/aar.types';

export enum IconColors {
  default = '#525252',
  lightBlue = '#62A5F8',
  lightGreen = '#60CE6E'
}

export interface TabItem {
  label: string;
  icon: string;
  color: string;
}

@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss']
})
export class TabViewComponent {
  iconColor = IconColors;

  @Input() tabItems: TabItem[] = [
    { label: ShopsTabLabel.NEAR_ME, icon: 'icon-car-tilt', color: IconColors.lightBlue },
    { label:  ShopsTabLabel.NEAR_HOME, icon: 'icon-home-solid', color: IconColors.lightGreen }
  ];

  activeTab$: Observable<ShopsTabLabel> = this.store$.pipe(select(selectShopTabView))

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
  ) { }

  changeActiveTab(tabView: ShopsTabLabel) {
    this.store$.dispatch(setShopTabView({payload: tabView}));

    if (tabView === ShopsTabLabel.NEAR_ME) {
      this.store$.dispatch(setBreakdownLocationCenter())
    } else {
      this.store$.dispatch(setHomeCenter())
    }

    this.taggingService.setClickEvent(
      `${events.towTo.DESTINATION_TAB_ITEM} ${tabView}`,
      events.towTo.DESTINATION_PAGE_TYPE
    )
  }

}
