import { Component, Input, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AbstractComponent } from 'src/app/shared/abstract.component'
import { AAAStore } from 'src/app/store/root-reducer'
import { RatingSummary } from '../aar.types'
import { requestReviews } from './review.actions'
import { selectIsLoadingReviews, selectReviews } from './review.selectors'
import { Review } from './review.types'

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss'],
})
export class ReviewListComponent extends AbstractComponent implements OnInit {

  reviews$: Observable<Review[]> = this.store$.pipe(select(selectReviews))
  isLoading$: Observable<boolean> = this.store$.pipe(select(selectIsLoadingReviews))

  @Input() locationName: string
  @Input() ratingSummary: RatingSummary

  constructor(
    private store$: Store<AAAStore>,
  ) {
    super()
  }

  ngOnInit(): void {
    this.store$.dispatch(requestReviews({
      payload: this.ratingSummary.businessId
    }))
  }

}
