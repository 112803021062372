import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getOemBrandingId } from './shared/utils/branding';

@Injectable()
export class GoogleMapsConfig implements LazyMapsAPILoaderConfigLiteral {

  apiKey: string;
  libraries: string[] = ['places'];

  constructor() {
    const rapAppId = getOemBrandingId(window.location.href);
    this.apiKey = rapAppId ? environment.rapGoogleMapsApiKey : environment.googleMapsApiKey
  }
}
