import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { XHRService } from '../../shared/services/xhr.service';
import { VendorConfigurationMode } from './mode-configuration.types';
import { RequestOptions } from 'src/app/shared/services/xhr.utils';



@Injectable({
  providedIn: 'root',
})
export class ModeConfigurationService {

  private requestOptions: RequestOptions = {
    withCredentials: false,
    useCustomAAAheaders: false,
  }

  constructor(private xhr: XHRService) { }

  async loadModeConfiguration(appid: string) {

    const url = `${environment.rapConfigurationBaseUrl}/${appid.toLowerCase()}.json`;

    try {
      const response = await this.xhr.request<VendorConfigurationMode>({
        url
      },
        this.requestOptions)

      return response
    } catch (error) {
      throw error
    }
  }
}
