<div class="card">
  <div class="icon">
    <icon-charging-level volts="{{ volts }}"></icon-charging-level>
  </div>
  <div class="title-description">
    <div class="title" role="heading" aria-level="1">{{ title }}</div>
    <div class="description">{{ description }}</div>
  </div>
  <div class="number-ports">
    <div class="title-number" role="heading" aria-level="1">EVSE Ports</div>
    <div class="number" [style.color]="numberColor">{{ numberOfPorts }}</div>
  </div>
</div>
