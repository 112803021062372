import { Component, ViewEncapsulation, Input } from '@angular/core'
import { Step } from '../../modules/ui/ui.types'
import { IndexedCollection } from '../types'

@Component({
  styleUrls: ['./stepper.component.scss'],
  templateUrl: './stepper.component.html',
  selector: 'app-stepper',
  encapsulation: ViewEncapsulation.None,
})
export class StepperComponent {
  @Input() steps: IndexedCollection<Step>

  constructor() {}
}
