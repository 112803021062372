<div class="dialog">
  <h1 mat-dialog-title>{{ data.title }}</h1>

  <app-safehtml
    mat-dialog-content
    [content]="data.content"
    id="dialog-content"
  ></app-safehtml>

  <div mat-dialog-actions>
    <app-button
      id="dialog-dismiss-submit"
      name="dialogConfirmCancelSubmit"
      inverted="true"
      type="button"
      (click)="onClose()"
      aria-label="dismiss this message"
    >
      Cancel
    </app-button>
    <app-button
      id="dialog-confirm-submit"
      name="dialogConfirmConfirmSubmit"
      type="button"
      (click)="onSubmit()"
      aria-label="Confirm"
    >
      Confirm
    </app-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
