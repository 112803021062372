<a
  [routerLink]="['/steps']"
  [queryParams]="{ step: stepUrl }"
  [tabIndex]="stepStatus === 'PENDING' ? -1 : tabIndex"
  [attr.aria-current]=" stepStatus === 'EDITING' ? 'step' : false"
  [attr.aria-disabled]=" stepStatus === 'PENDING'"
  triggerable-element
>
  <div class="step-bar-left"></div>
  <div class="step-circle">
    <icon-edit *ngIf="stepStatus === 'EDITING'" class="icon"></icon-edit>
    <icon-check *ngIf="stepStatus === 'DONE'" class="icon"></icon-check>
    <span *ngIf="stepStatus === 'PENDING' || stepStatus === 'ACTIVE'">
      {{ stepId }}
    </span>
  </div>
  
  <div class="step-title">
    <span class="visually-hidden" *ngIf="stepStatus === 'DONE' || stepStatus === 'EDITING'">
      {{ stepStatus === 'DONE' ? 'completed': 'current'}}
    </span>
    {{ stepTitle }}
  </div>
  <div class="step-bar-right"></div>
</a>
