import { Directive, ElementRef, Input } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectIsImproveUserNavigation } from 'src/app/modules/ui/ui.selectors'
import { AAAStore } from 'src/app/store/root-reducer'

@Directive({
  selector: '[isImproveUserNavigation]',
})
export class IsImproveUserNavigationDirective {
  @Input() customClassName: string

  isImproveUserNavigation$: Observable<boolean> = this.store$.pipe(
    select(selectIsImproveUserNavigation)
  )

  subscriptions = []

  constructor(private element: ElementRef, private store$: Store<AAAStore>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.isImproveUserNavigation$.subscribe((enabled) =>
        this.updateElement(enabled)
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  updateElement(enabled: boolean) {
    if (!enabled) {
      return
    }

    const parentButton = this.element.nativeElement
    const childButtons = parentButton.getElementsByTagName('button')
    const newClass = this.customClassName || 'test-primary'

    for (let index = 0; index < childButtons.length; index++) {
      const element = childButtons[index];
      element.classList.add(newClass)
    }
  }

}
