<app-select
  [elementId]="fieldName + '-select'"
  [placeholder]="'See other ' + fieldName + 's'"
  [items$]="internalItems$ "
  [searchable]="true"
  [dropdownPosition]="dropdownPosition"
  (selectionMade)="handleChange($event)">
</app-select>

<div [hidden]="!otherSelected" class="select-or-text_other">
  <label [for]="fieldName + '-input'">Enter your {{ fieldName }}</label>
  <input
    #otherInput
    [id]="fieldName + '-input'"
    type="text"
    [placeholder]="'Type ' + fieldName + '...'"
    [(ngModel)]="otherValue"
    [maxLength]="maxLength"
    (keyup)="handleKeyUpText($event)"
  />
</div>
