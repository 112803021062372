import {PACE_SETTER_OVERRIDE_TYPES} from '../servicing-club/servicing-club.types'

export interface PaceSetterCode {
  name: string
  paceSetterCode?: PACE_SETTER_SITUATION_CODES
  paceSetterOverrideKey?: PACE_SETTER_OVERRIDE_TYPES
  requiresTowing?: boolean
  obs?: string
  comment?: string
  alternateLabel?: string
  tagLabel?: string
  overrideType?: string
  needAdditionalRequirement?: boolean
  soundName?: string
}

export interface PaceSetterList {
  members: PaceSetterCode[]
  additional?: PaceSetterCode[]
}

export interface PaceSetterGroup {
  lists: PaceSetterList[]
}

export interface PaceSetterSituation {
  name: string
  label: string
  icon: string
  description?: string
  paceSetterCodes?: Array<PaceSetterCode>
  enabled?: boolean
}

export interface PaceSetterDetails {
  name: string
  defaultPaceSetterCode?: PaceSetterCode
  /* Details are in groups, which are displayed as pages;
   * Each page has one or more sets of options, and there are one or more options in each list.
   * So this array is indexed by group -> list index -> option index
   * Example: Flat Tire:
   * Page 1:
   *   tires:
   *     Front Left
   *     Front Right
   *     Back Left
   *     Back Right
   * Page 2:
   *   Anything else?
   *     I need a spare
   *     My tires need air
   *     etc.
   */
  selectedGroups: Array<PaceSetterGroup>
  notes?: string
}

/* Pace setter categories and other information */

export enum PRIORITY_CODES {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  P6 = 'P6',
}

export enum PACE_SETTER_SITUATION_TYPES {
  ACCIDENT = 'ACCIDENT',
  BATTERY_ISSUE = 'BATTERY TEST OR REPLACE',
  CAR_WONT_START = `CAR WON'T START`,
  FLAT_TIRE = 'FLAT TIRE',
  LOCKED_OUT = 'LOCKED OUT',
  NEED_A_TOW = 'NEED A TOW',
  OUT_OF_FUEL = 'FUEL/EV CHARGE',
  STUCK = 'STUCK',
}

export enum PACE_SETTER_SITUATION_CODES {
  T906 = 'T906',
  T932 = 'T932',
  T931 = 'T931',
  L302 = 'L302',
  L101 = 'L101',
  T180 = 'T180',
  L701 = 'L701',
  L890 = 'L890',
  L801 = 'L801',
  L402 = 'L402',
  L403 = 'L403',
  T483 = 'T483',
  T480 = 'T480',
  T680 = 'T680',
  L301 = 'L301',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  T5 = 'T5',
  T6 = 'T6',
  T7 = 'T7',
  T7A = '7A',
  T7B = '7B',
  T8 = 'T8',
  L102 = 'L102',
  L103 = 'L103',
  L104 = 'L104',
  L105 = 'L105',
  L190 = 'L190',
  L303 = 'L303',
  L304 = 'L304',
  L390 = 'L390',
  L490 = 'L490',
  L404 = 'L404',
  L401 = 'L401',
  T280 = 'T280',
  T380 = 'T380',
  T381 = 'T381',
  T382 = 'T382',
  T481 = 'T481',
  T484 = 'T484',
  T580 = 'T580',
  T581 = 'T581',
  T582 = 'T582',
  T601 = 'T601',
  T690 = 'T690',
  L201 = 'L201',
  L501 = 'L501',
  L505 = 'L505',
  L506 = 'L506',
  L507 = 'L507',
  L508 = 'L508',
  L509 = 'L509',
  L510 = 'L510',
  L590 = 'L590',
  T905 = 'T905',
  T907 = 'T907',
  T980 = 'T980',
  T990 = 'T990',
  T921 = 'T921',
  T922 = 'T922',
  L702 = 'L702',
  L703 = 'L703',
  L704 = 'L704',
  L790 = 'L790',
  L802 = 'L802',
  L803 = 'L803',
  L804 = 'L804',
  L805 = 'L805',
  L806 = 'L806',
  L807 = 'L807',
}

export enum ENTITLEMENT_TYPES {
  TOW = 'TOW',
  BATTERY = 'BATTERY',
  TIRE = 'TIRE',
  GAS = 'GAS',
  LOCK = 'LOCK',
  WINCH = 'WINCH',
  TRIP = 'TRIP',
  TAXI = 'TAXI',
}

export const PACE_SETTER_SITUATION_TYPES_MAP_ANALYTICS = {
  [PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE]: 'BATTERY ISSUE',
}

export const PACE_SETTER_SITUATION_CODE_ENTITLEMENT_TYPE = {
  [PACE_SETTER_SITUATION_CODES.L101]: ENTITLEMENT_TYPES.TIRE,
  [PACE_SETTER_SITUATION_CODES.L102]: ENTITLEMENT_TYPES.TIRE,
  [PACE_SETTER_SITUATION_CODES.L103]: ENTITLEMENT_TYPES.TIRE,
  [PACE_SETTER_SITUATION_CODES.L104]: ENTITLEMENT_TYPES.TIRE,
  [PACE_SETTER_SITUATION_CODES.L105]: ENTITLEMENT_TYPES.TIRE,
  [PACE_SETTER_SITUATION_CODES.L190]: ENTITLEMENT_TYPES.TIRE,
  [PACE_SETTER_SITUATION_CODES.L301]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.L302]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.L303]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.L304]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.L390]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.L401]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.L490]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.L402]: ENTITLEMENT_TYPES.GAS,
  [PACE_SETTER_SITUATION_CODES.L403]: ENTITLEMENT_TYPES.GAS,
  [PACE_SETTER_SITUATION_CODES.T180]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T280]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T380]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T381]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T382]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L404]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T480]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T481]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T483]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T484]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T580]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T581]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T582]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T601]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T680]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T690]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L201]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L501]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L505]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L506]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L507]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L508]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L509]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L510]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L590]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T905]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T906]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T907]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T980]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T990]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T921]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.T922]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.L701]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L702]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L703]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L704]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L790]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L801]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L802]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L803]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L804]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L805]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L806]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L807]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.L890]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.T931]: ENTITLEMENT_TYPES.WINCH,
  [PACE_SETTER_SITUATION_CODES.T932]: ENTITLEMENT_TYPES.WINCH,
}

export interface PaceSetterOptions {
  name: string
  defaultCode?: PaceSetterCode
  detailGroups: PaceSetterDetailGroup[]
  allowNotes?: boolean
}

export interface PaceSetterDetailGroup {
  heading?: string
  subHeading?: string
  isOptional?: boolean
  lists?: PaceSetterDetailCategory[]
  options?: PaceSetterDetailCategory[]
  allowNotes?: boolean
  noteInstructions?: string,
  headingClass?: string,
  subHeadingClass?: string,
  additionalRequirements?: PaceSetterDetailCategory
}

export interface PaceSetterDetailCategory {
  name?: string
  heading?: string
  multiSelect?: boolean
  mandatory?: boolean
  values: PaceSetterCode[] | FlatTireOptions[] | DamagedPartOptions[]
}

export interface FlatTireOptions {
  active?: boolean
  section: string
  side: string
  label: string
}

export interface DamagedPartOptions {
  active?: boolean
  section: string
  side: string
  label: string
  comment?: string
}

export enum PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES {
  LOVED_ONE_LOCKED_IN_CAR = 'A loved one is locked in the car.',
  MEDICATION_LOCKED_IN_CAR = 'My medication is locked in the car.'
}
