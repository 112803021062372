<div class="dialog dialog-error">
  <h1 *ngIf="!data.params.hideTitle" mat-dialog-title>Call Status</h1>

  <div id="dialog-content" mat-dialog-content>
    <p>Sorry, there was a problem checking the status of your call.</p>
    <p>If the problem persists, please call the number below.</p>
  </div>

  <div mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      (buttonClick)="onCallAAAClick()">
    </app-call-aaa-button>

    <button
      *ngIf="!!data.params.showDismissButton"
      id="dialog-dismiss-submit"
      name="dialogGenericErrorDismiss"
      class="btn primary btn-sm"
      aria-label="dismiss this message"
      (click)="onClose()">
      Dismiss
    </button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
