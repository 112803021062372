<div class="dialog">
  <h1 mat-dialog-title>Alert</h1>

  <div id="dialog-content" mat-dialog-content>
    <p>
      The location you selected is more than {{DISTANCE_LIMIT}} miles from your GPS coordinates,
      <b>is this correct?</b>
    </p>
  </div>

  <div mat-dialog-actions class="buttons-row">
    <app-button type="button"
      [inverted]="true"
      mat-dialog-close
      aria-label="dismiss this message"
      [size]="'cozy'">
      No
    </app-button>
    <app-button
      [className]="'continue-button'"
      type="button"
      [size]="'cozy'"
      (buttonClick)="onContinue()"
      aria-label="Continue">
      Yes, Continue
    </app-button>
  </div>
</div>
