import { createAction, props } from '@ngrx/store'
import { IndexedCollection } from 'src/app/shared/types'
import { createAsyncConstants } from 'src/app/shared/utils'
import { BatteryQuote, BatteryQuotePayload } from './quotes.types'

export const BATTERY_QUOTES = createAsyncConstants('BATTERY_QUOTES')
export const BATTERY_QUOTES_NOTIFIED = 'BATTERY_QUOTES_NOTIFIED'

export const batteryQuoteSuccess = createAction(
  BATTERY_QUOTES.SUCCESS,
  props<{ payload: IndexedCollection<Array<BatteryQuote>> }>()
)

export const batteryQuoteFailure = createAction(BATTERY_QUOTES.FAILURE)

export const batteryQuoteRequest = createAction(
  BATTERY_QUOTES.REQUEST,
  props<{ payload: BatteryQuotePayload }>()
)

export const batteryQuoteNotify = createAction(
  BATTERY_QUOTES_NOTIFIED,
  props<{
    payload: {
      [id: string]: boolean
    }
  }>()
)
