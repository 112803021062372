<!-- Dialog Content -->
<div class="dialog not-a-member">
  <h1 mat-dialog-title>
      Additional info required
  </h1>

  <form name="form" #f="ngForm">

    <div mat-dialog-content>
      <div class="modal-form">
        <label for="firstLastName">First Name</label>
        <input
          [(ngModel)]="additionalInfoModel.firstName"
          #firstLastName="ngModel"
          name="firstName"
          id="firstName"
          type="text"
          aria-required="true"
          required />
          <div [ngClass]="{ hidden: !f?.form?.controls?.firstName?.valid && !f?.touched }">
            <div *ngIf="f?.form?.controls?.firstName?.errors?.required" class="text-error membership-validation">
              First Name is required
            </div>
          </div>
      </div>
  
      <div class="modal-form">
        <label for="firstLastName">Last Name</label>
        <input
          [(ngModel)]="additionalInfoModel.lastName"
          #firstLastName="ngModel"
          name="lastName"
          id="lastName"
          type="text"
          aria-required="true"
          required />
          <div [ngClass]="{ hidden: !f?.form?.controls?.lastName?.valid && !f?.touched }">
            <div *ngIf="f?.form?.controls?.lastName?.errors?.required" class="text-error membership-validation">
              Last Name is required
            </div>
          </div>
      </div>
    </div>

    <div mat-dialog-actions class="buttons-row">
      <app-button type="button"
        mat-dialog-close
        aria-label="dismiss this message"
        [inverted]="true">
        Cancel
      </app-button>
      <app-button type="submit"
        (buttonClick)="handleSearchForm(f)"
        [disabled]="(isLoading$ | async) || !f.valid">
        Confirm
      </app-button>
    </div>
  </form>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>

