import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'

import { AbstractComponent } from 'src/app/shared/abstract.component'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from 'src/app/modules/ui/ui.types'
import {
  searchMembersReset,
  MEMBER_INFO,
  MEMBER_BASIC_INFO,
  MEMBERS_SEARCH,
  MEMBERS_SEARCH_INFO,
} from 'src/app/modules/member/member.actions'
import { map } from 'rxjs/operators'
import {
  authNameSearchInfoRequest,
  AUTH_NAME_SEARCH_INFO,
} from 'src/app/modules/auth/auth.actions'
import { ADD_CALL } from 'src/app/modules/dashboard/calls.actions'
import { selectIsLoading } from '../../../loading/loading.selectors'

import { combineLatest } from 'rxjs'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import { NgForm } from '@angular/forms'
import events from 'src/app/modules/tagging/events'
import { MEMBERSHIP_ERRORS } from 'src/app/modules/member/member.types'


@Component({
  selector: 'app-auth-additional-info-search',
  templateUrl: './auth-additional-info-search.component.html',
  styleUrls: ['./auth-additional-info-search.component.scss'],
})
export class AuthAdditionalInfoSearchComponent
  extends AbstractComponent
  implements OnInit {
  isLoading$ = combineLatest([
    this.store$.pipe(select(selectIsLoading(MEMBER_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBERS_SEARCH_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_BASIC_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBERS_SEARCH.ACTION))),
    this.store$.pipe(select(selectIsLoading(ADD_CALL.ACTION))),
  ]).pipe(map((areActionsLoading) => areActionsLoading.find(Boolean)))

  @ViewChild('f') searchForm: NgForm

  additionalInfoModel = {
    firstName: '',
    lastName: ''
  }

  searchId: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService
  ) {
    super()
  }

  ngOnInit() {
    this.searchId = this.data.params?.searchId || null
  }

  handleSearchForm({ value }) {
    const { firstName, lastName } = value
    const searchId = this.searchId

    this.store$.dispatch(
      authNameSearchInfoRequest({
        payload: {
          searchId,
          firstName: firstName.toUpperCase(),
          lastName: lastName.toUpperCase()
        }
      })
    )
  }
}
