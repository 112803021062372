import { Injectable } from '@angular/core'
import { createEffect, ofType, Actions } from '@ngrx/effects'
import {
  withLatestFrom,
  map,
  catchError,
  switchMap,
  filter,
} from 'rxjs/operators'
import { PayloadedAction } from 'src/app/shared/types'
import { AAAStore } from 'src/app/store/root-reducer'
import { Store } from '@ngrx/store'
import { CallsService } from '../calls.service'
import {
  CLUB_SUPPORT,
  notifyClubSupportFailure,
  clubSupportSuccess,
  requestClubSupport,
} from './clubs.actions'
import { from } from 'rxjs'
import { clubSelectorFactory } from './clubs.selectors'
import { Club } from './clubs.reducer'
import { SET_ACTIVE_CALL_STATUS } from '../calls-statuses/call-status.actions'
import { selectActiveCallStatus } from '../calls-statuses/call-status.selectors'
import { AAACallStatus } from '../calls.types'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'

@Injectable()
export class ClubEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _callService: CallsService,
    private errorReportingService: ErrorReportingService
  ) {}

  handleCallSelection = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_ACTIVE_CALL_STATUS),
      withLatestFrom(
        this.store$.select(selectActiveCallStatus),
        this.store$.select(clubSelectorFactory)
      ),
      filter(
        ([_, callStatus, selectClub]: [
          PayloadedAction,
          AAACallStatus,
          (name: string) => Club
        ]) => {
          const club = selectClub(callStatus.servicingClub)
          return (
            !club ||
            (club.hasTrackingSupport !== true &&
              club.hasTrackingSupport !== false)
          )
        }
      ),
      map(([_, callStatus]: [PayloadedAction, AAACallStatus, never]) =>
        requestClubSupport({ payload: callStatus.servicingClub })
      )
    )
  )

  handleNonTracking = createEffect(() =>
    this.actions$.pipe(
      ofType(CLUB_SUPPORT.REQUEST),
      switchMap((action: PayloadedAction) =>
        from(this._callService.getClubSupport(action.payload)).pipe(
          map(
            (response): PayloadedAction =>
              clubSupportSuccess({
                payload: {
                  name: action.payload,
                  hasTrackingSupport: response.supported,
                },
              })
          ),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyClubSupportFailure
            )
          )
        )
      )
    )
  )
}
