import { Component, EventEmitter, Input, Output } from '@angular/core';
import { membershipMask, zipCodeMask } from '../minimal-credentials/minimal-credentails.constants';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectMobileNumberAuth } from '../../ui/ui.selectors';

@Component({
  selector: 'app-membership-number',
  templateUrl: './membership-number.component.html',
  styleUrls: ['../minimal-credentials/minimal-credentials.component.scss'],
})
export class MembershipNumberComponent {
  @Input() controls
  @Output() useMemberNameEmitter: EventEmitter<any> = new EventEmitter<any>()

  mask = membershipMask
  zipCodeMask = zipCodeMask

  isMobileNumberAuth$: Observable<boolean> = this.store$.pipe(
    select(selectMobileNumberAuth)
  )

  constructor(private store$: Store) {}

  setUseMemberName(event) {
    this.useMemberNameEmitter.emit(event)
  }
}
