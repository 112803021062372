import { AfterContentInit, Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Actions } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { TaggingService } from '../../tagging/tagging.service'
import { AbstractComponent } from '../../../shared/abstract.component'
import { selectAuthErrorCount, selectAuthMethod, selectIsUseAnotherFormClickedAfterError } from '../auth.selectors'
import { AuthMethods, LoginFailAssistantError } from '../auth.types'
import { combineLatest } from 'rxjs'
import { setAuthMethod, setClickedUseAnotherForm } from '../auth.actions'
import { openErrorDialog } from '../../ui/ui.actions'
import { ErrorDialogTypes } from '../../ui/ui.types'
import events from '../../tagging/events'
import { animate, style, transition, trigger } from '@angular/animations'
import { selectMobileNumberAuth } from '../../ui/ui.selectors'
import { AAA_DEFAULT_PHONE_NUMBER, selectDefaultPhoneNumber } from '../../servicing-club/servicing-club.selectors'

@Component({
  selector: 'app-login-fail-assistant',
  templateUrl: './login-fail-assistant.component.html',
  styleUrls: ['./login-fail-assistant.component.scss'],
  animations: [
    trigger('slideInAnimation', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({'max-height':0, opacity: 0}),
        animate(500, style({'max-height':100, opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({'max-height':0}))
      ])
    ])
  ]
})
export class LoginFailAssistantComponent extends AbstractComponent implements OnInit, AfterContentInit {
  @Output() hasError: EventEmitter<any> = new EventEmitter<any>()

  authMethod$ = this.store$.pipe(select(selectAuthMethod))
  errorCount$ = this.store$.pipe(select(selectAuthErrorCount))
  isUseAnotherFormClickedAfterError$ = this.store$.pipe(select(selectIsUseAnotherFormClickedAfterError))
  isMobileNumberAuth$ = this.store$.pipe(select(selectMobileNumberAuth))
  assistantInput$ = combineLatest([
    this.authMethod$,
    this.errorCount$,
  ])
  defaultPhoneNumber$ = this.store$.pipe(select(selectDefaultPhoneNumber))

  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
  ) {
    super()
  }
  authMethod: AuthMethods
  show = false
  autoSwitch = false
  useAnotherFormClickedAfterError = false
  isMobileNumberAuth = false
  dialogErrors: LoginFailAssistantError
  previousErrorCount = 0
  defaultPhoneNumber = AAA_DEFAULT_PHONE_NUMBER.replace(/\D/g,'')

  hasChanged = () => this.authMethod === undefined

  ngOnInit(): void {
    this.subscriptions.push(
      this.isUseAnotherFormClickedAfterError$.subscribe((clicked) => this.useAnotherFormClickedAfterError = clicked),
      this.isMobileNumberAuth$.subscribe((isMobileNumberAuth) => this.isMobileNumberAuth = isMobileNumberAuth),
      this.defaultPhoneNumber$.subscribe((number) => this.defaultPhoneNumber = number.replace(/\D/g,''))
    )
  }

  ngAfterContentInit(): void {
    this.subscriptions.push(
      this.assistantInput$.subscribe(([method, count]) => {
        // when a refresh happens the previousErrorCount is reset, but the count value is kept
        // for the first 2 attempts it should keep the error messages in page, even on refresh
        // after the 2 attempt the message is a popup that shouldn't be displayed on refresh
        const clearOnRefreshAfterThirdAttempt = count - this.previousErrorCount > 2
        this.previousErrorCount = count

        this.show = !clearOnRefreshAfterThirdAttempt && count > 0 && (
          (this.autoSwitch && !this.useAnotherFormClickedAfterError) || // show the message on auto switch
          this.authMethod === method || // prevent to show the message when form is changing
          (this.hasChanged() && !this.useAnotherFormClickedAfterError && !this.isMobileNumberAuth) // prevent to show the message when user manually switches
        );

        // keep prompt analytic event for other issues not using LFA
        if (this.show && ![AuthMethods.MEMBERSHIP_NUMBER, AuthMethods.MEMBER_NAME, AuthMethods.MOBILE_PHONE].includes(method)) {
          this.callPromptTag()
          return
        }

        if (this.show) {
          if (count === 1) {
            if (this.isMobileNumberAuth) {
              // analytics event
              this.onEventFailed(events.auth.FIRST_ERROR_ATTEMPT)
              // assistant info
              if (method === AuthMethods.MOBILE_PHONE) {
                this.dialogErrors = this.getFirstPhoneError()
              } else {
                this.dialogErrors = this.getFirstNameOrNumberErrorMNA(method === AuthMethods.MEMBER_NAME ? AuthMethods.MEMBER_NAME : AuthMethods.MEMBERSHIP_NUMBER)
              }
            } else {
              // analytics event
              this.callPromptTag()
              // assistant info
              if (method === AuthMethods.MEMBER_NAME) {
                this.dialogErrors = this.getFirstNameError()
              }
              if (method === AuthMethods.MEMBERSHIP_NUMBER) {
                this.dialogErrors = this.getFirstNumberError()
              }
            }
          } else if (count === 2) {
            if (this.isMobileNumberAuth) {
              // analytics event
              this.onEventFailed(events.auth.SECOND_ERROR_ATTEMPT)
              // assistant info
              if (method === AuthMethods.MOBILE_PHONE) {
                this.dialogErrors = this.getSecondPhoneError()
              } else {
                this.dialogErrors = this.getSecondNameOrNumberErrorMNA(method === AuthMethods.MEMBER_NAME ? AuthMethods.MEMBER_NAME : AuthMethods.MEMBERSHIP_NUMBER)
              }
            } else {
              // analytics event
              !this.autoSwitch && this.callPromptTag() //avoid prompt analytic event for auto switch
              // assistant info
              if (method === AuthMethods.MEMBER_NAME) {
                this.dialogErrors = this.getSecondNameError()
              }
              if (method === AuthMethods.MEMBERSHIP_NUMBER) {
                this.dialogErrors = this.getSecondNumberError()
              }
            }
          } else {
            // analytics event
            if (this.isMobileNumberAuth) {
              this.onEventFailed(events.auth.THIRD_ERROR_ATTEMPT)
            } else {
              this.callPromptTag()
            }
            // assistant info
            this.store$.dispatch(
              openErrorDialog({
                payload: {
                  type:ErrorDialogTypes.MEMBER_NO_MATCHES,
                },
              })
            )
            this.show = false
          }
        }
        // emit flag to parent in order to highlight and clear the inputs on errors
        this.hasError.emit(this.show)

        // special condition to automatically show NUMBER/NAME form after second error at NAME/NUMBER when the user didn't switch after the first error 😅
        if (
          [AuthMethods.MEMBER_NAME, AuthMethods.MEMBERSHIP_NUMBER].includes(this.authMethod)
          && count === 2 && !this.useAnotherFormClickedAfterError && !this.autoSwitch && !this.isMobileNumberAuth
        ) {
          this.store$.dispatch(
            setAuthMethod({
              payload: this.authMethod === AuthMethods.MEMBER_NAME ? AuthMethods.MEMBERSHIP_NUMBER : AuthMethods.MEMBER_NAME
            })
          )
          this.autoSwitch = true
        }
        this.authMethod = method
      }),
    )
  }

  callPromptTag = () => {
    this.taggingService.setPageEvent(
      events.auth.MEMBER_ISSUE_PROMPT,
      events.auth.MEMBER_ISSUE_PAGE_TYPE,
    )
  }

  onEventFailed = (attemptNumber) => {
    this.taggingService.setAutomatedEvent(
      this.authMethod === AuthMethods.MEMBER_NAME ?
        events.auth.FORM_NAME_AUTH_FAILURE :
        this.authMethod === AuthMethods.MOBILE_PHONE ?
          events.auth.FORM_PHONE_NUMBER_AUTH_FAILURE :
          events.auth.FORM_MEMBERSHIP_AUTH_FAILURE,
      attemptNumber
    )
  }
  getFirstNameError = () => ({
    message: `We're sorry. It seems that your login credentials don't match your membership card.<br>
      Please try different variations of your full name, such as the <strong>FIRST</strong> and <strong>LAST NAME ONLY</strong>, without hyphens or middle names.`,
    cta: 'Or try using your',
    link: 'AAA Membership Number',
    secondLink: null,
    event: () => {
      this.store$.dispatch(setClickedUseAnotherForm())
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBERSHIP_NUMBER}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_MEMBERSHIP_CLICK,
        events.auth.PAGE_TYPE
      )
    },
    secondEvent: () => null,
    href: null,
    icon: 'dialog',
    cssClass: 'warn-box',
  })

  getFirstNumberError = () => ({
    message: `Sorry, we're having trouble verifying your AAA Membership. Make sure you are entering the numbers<strong> displayed on your card and home zip code</strong>.`,
    cta: `Or try using your`,
    link: 'Name and Home Zip Code',
    secondLink: null,
    href: null,
    event: () => {
      this.store$.dispatch(setClickedUseAnotherForm())
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBER_NAME}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_NAME_CLICK,
        events.auth.PAGE_TYPE
      )
    },
    secondEvent: () => null,
    icon: 'phone',
    cssClass: 'warn-box',
  })

  getFirstPhoneError = () => ({
    message: `It looks like we are having trouble verifying your information details.`,
    cta: 'Try again, use your',
    link: 'AAA Membership Number',
    secondLink: 'First and Last Name',
    event: () => {
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBERSHIP_NUMBER}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_MEMBERSHIP_CLICK,
        events.auth.PAGE_TYPE
      )
      this.show = false
    },
    secondEvent: () => {
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBER_NAME}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_NAME_CLICK,
        events.auth.PAGE_TYPE
      )
      this.show = false
    },
    href: null,
    icon: 'dialog',
    cssClass: 'warn-box',
  })

  getFirstNameOrNumberErrorMNA = (method: AuthMethods.MEMBER_NAME | AuthMethods.MEMBERSHIP_NUMBER) => ({
    message: `It looks like we are having trouble verifying your information details.`,
    cta: 'Try again, use your',
    link: 'Phone Number',
    secondLink: method === AuthMethods.MEMBER_NAME ? 'AAA Membership Number' : 'First and Last Name',
    event: () => {
      this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MOBILE_PHONE }))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_MOBILE_CLICK,
        events.auth.PAGE_TYPE
      )
      this.show = false
    },
    secondEvent: () => {
      if (method === AuthMethods.MEMBER_NAME) {
        this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBERSHIP_NUMBER }))
        this.taggingService.setClickEvent(
          events.auth.USE_FORM_MEMBERSHIP_CLICK,
          events.auth.PAGE_TYPE
        )
      } else if(method === AuthMethods.MEMBERSHIP_NUMBER) {
        this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBER_NAME }))
        this.taggingService.setClickEvent(
          events.auth.USE_FORM_NAME_CLICK,
          events.auth.PAGE_TYPE
        )
      }
      this.show = false
    },
    href: null,
    icon: 'dialog',
    cssClass: 'warn-box',
  })

  getSecondNameError = () => ({
    message: this.autoSwitch || this.hasChanged() ?
      `It looks like we are having trouble verifying your AAA Membership Number. Please try again using your <strong>primary account holder's</strong> details into the first name, last name, and home zip code.` :
      `It looks like we are having trouble verifying your name and home zip code. Please try again using your <strong>AAA Membership Number and home zip code.</strong>`,
    cta: `Don't have your AAA Membership Number?`,
    link: 'Call AAA',
    secondLink: null,
    href: `tel:+1${this.defaultPhoneNumber}`,
    event: null,
    secondEvent: null,
    icon: 'phone',
    cssClass: 'error-box',
  })

  getSecondNumberError = () => ({
    message: this.autoSwitch || this.hasChanged() ?
      `It looks like we are having trouble verifying your name and home zip code. Please try again using your <strong>AAA Membership Number and home zip code.</strong>` :
      `It looks like we are having trouble verifying your AAA Membership Number. Make sure you are entering the <strong>AAA Membership Number displayed on your card and home zip code</strong>.`,
    cta: `Don't have your AAA Membership Number?`,
    link: 'Call AAA',
    secondLink: null,
    href: `tel:+1${this.defaultPhoneNumber}`,
    event: null,
    secondEvent: null,
    icon: 'phone',
    cssClass: 'error-box',
  })

  getSecondPhoneError = () => ({
    message: `It looks like we are having trouble verifying your information details.`,
    cta: 'If the problem persists,',
    link: 'AAA Membership Number',
    secondLink: 'First and Last Name',
    event: () => {
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBERSHIP_NUMBER}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_MEMBERSHIP_CLICK,
        events.auth.PAGE_TYPE
      )
      this.show = false
    },
    secondEvent: () => {
      this.store$.dispatch(setAuthMethod({payload: AuthMethods.MEMBER_NAME}))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_NAME_CLICK,
        events.auth.PAGE_TYPE
      )
      this.show = false
    },
    href: `tel:+1${this.defaultPhoneNumber}`,
    icon: 'dialog',
    cssClass: 'error-box',
  })

  getSecondNameOrNumberErrorMNA = (method: AuthMethods.MEMBER_NAME | AuthMethods.MEMBERSHIP_NUMBER) => ({
    message: `It looks like we are having trouble verifying your information details.`,
    cta: 'If the problem persists,',
    link: 'Phone Number',
    secondLink: method === AuthMethods.MEMBER_NAME ? 'AAA Membership Number' : 'First and Last Name',
    event: () => {
      this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MOBILE_PHONE }))
      this.taggingService.setClickEvent(
        events.auth.USE_FORM_MOBILE_CLICK,
        events.auth.PAGE_TYPE
      )
      this.show = false
    },
    secondEvent: () => {
      if (method === AuthMethods.MEMBER_NAME) {
        this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBERSHIP_NUMBER }))
        this.taggingService.setClickEvent(
          events.auth.USE_FORM_MEMBERSHIP_CLICK,
          events.auth.PAGE_TYPE
        )
      } else if(method === AuthMethods.MEMBERSHIP_NUMBER) {
        this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBER_NAME }))
        this.taggingService.setClickEvent(
          events.auth.USE_FORM_NAME_CLICK,
          events.auth.PAGE_TYPE
        )
      }
      this.show = false
    },
    href: `tel:+1${this.defaultPhoneNumber}`,
    icon: 'dialog',
    cssClass: 'error-box',
  })
}

