import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import events from 'src/app/modules/tagging/events'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import { DialogData, ErrorDialogTypes} from 'src/app/modules/ui/ui.types'
import { AbstractComponent } from 'src/app/shared/abstract.component'
import { AAAStore } from 'src/app/store/root-reducer'
import { openErrorDialog } from '../../../ui.actions'
import { Observable } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { CancelFeedbackOthersFormState, resetOthersForm, setResetCancelFeedbackOthersForm } from '../../../forms/forms.reducer'
import { selectCancelFeedbackOthersForm } from '../../../forms/forms.selectors'

@Component({
  selector: 'app-cancel-feedback',
  templateUrl: './cancel-feedback.component.html',
  styleUrls: [
    '../../shared-dialog.component.scss',
    './cancel-feedback.component.scss',
  ],
})
export class CancelFeedbackDialogComponent
  extends AbstractComponent
  implements OnInit {

  form$: Observable<FormGroupState<CancelFeedbackOthersFormState>> =
    this.store$.pipe(select(selectCancelFeedbackOthersForm))
  form: FormGroupState<CancelFeedbackOthersFormState>
  hasAvailableCall: boolean

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService
  ) {
    super()
  }

  ngOnInit() {
    this.hasAvailableCall = this?.data?.params.hasAvailableCall
    this.subscriptions.push(
      this.form$.subscribe((form) => {
        this.form = form
      })
    )
  }
  handleOption(option: string) {

    this.taggingService.setClickEvent(
      this.hasAvailableCall
        ? events.dashboard.POST_SUBMISSION_CANCEL_SURVEY
        : events.dashboard.PRE_SUBMISSION_CANCEL_SURVEY,
      events.dashboard.CALL_STATUS_PAGE_TYPE,
      null,
      { 'Event Detail': option }
    )
    return this.data.close()
  }

  handleSubmitLater() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: 'We Will Be Here When You Need Us',
        content: '<div>Please request roadside assistance when you are ready.</div>'
      },
    }))
    this.handleOption('I Will Submit My Request Later')
  }

  handleAnotherProvider() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: 'Thank You for Keeping Us Informed',
        content: '<div>Thank you for notifying us that you’ve requested service from another service provider.</div>'
      },
    }))
    this.handleOption('Requested Service with Another Provider')
  }

  handleMyVehicleStarted() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: 'You’re Back on the Road',
        content:
          '<div>Thank you for notifying us that your vehicle started and that you no longer require roadside assistance.</div>'
      },
    }))
    this.handleOption('My Vehicle Started')
  }

  handleNotWithMyVehicle() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: 'AAA Roadside Service Requires',
        content:
          '<div class="container">' +
          '<div class="item">An active AAA Membership to receive roadside service.</div>' +
          '<div class="item">A valid photo ID at the time of service.</div>' +
          '<div class="item">Members be with the vehicle at the time of service.</div>' +
          '</div>'
      },
    }))
    this.handleOption('Not with my Vehicle or No Photo ID')
  }

  handleLongEta() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: 'We Apologize for the Long Wait',
        content:
          '<div class="container">' +
          '<p>We are currently servicing AAA members around your area.</p>' +
          '</div>'
      },
    }))
    this.handleOption('Long ETA/Wait Time')
  }

  handleFamilyAssist() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: 'Thank you for Keeping us Informed',
        content:
          '<div class="container">' +
          '<p>Thank you for notifying us that a friend or family member has assisted you and that you no longer require AAA assistance.</p>' +
          '</div>'
      },
    }))
    this.handleOption('Friend or Family Member Assisted Me')
  }

  handleOther() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: 'Thank You for Your Feedback.',
        content: '<div>Please contact AAA if you need further assistance.</div>'
      },
    }))
    this.handleOption(`Other: ${this.form.value.reason}`)
    this.store$.dispatch(resetOthersForm())
    this.store$.dispatch(setResetCancelFeedbackOthersForm())
  }

  onClose() {
    return this.handleOption('Pre-submission cancel survey dismissed')
  }
}



