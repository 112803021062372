import { CANCEL_EDITING_REQUEST } from 'src/app/shared/actions/shared.actions'
import { PayloadedAction } from 'src/app/shared/types'
import { REVIEWS_LOAD } from './review.actions'
import { Review } from './review.types'

export interface ReviewState {
  reviews: Review[],
}

export const initialState: ReviewState = {
  reviews: [],
}

export function reducer(
  state: ReviewState = initialState,
  action: PayloadedAction
): ReviewState {
  switch (action.type) {
    case REVIEWS_LOAD.SUCCESS:
      return {
        ...state,
        reviews: action.payload.reviews,
      }
    case CANCEL_EDITING_REQUEST:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
