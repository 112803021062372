export default {
  ISSUE_PAGE_TYPE: 'What Happened?',
  ISSUE_DETAILS_PAGE_TYPE: 'Problem Description',
  ISSUE_PAGE_PROMPT: 'Issue screen displayed',
  ISSUE_TOOLTIP_PROMPT: ' - Tooltip displayed',
  ISSUE_PAGE_NEXT_CLICK: 'ISSUE SELECTED',
  ISSUE_PAGE_NEXT_TOOLTIP_CLICK: ' - TOOLTIP ISSUE SELECTED',
  ISSUE_PAGE_DETAILS_NEXT_CLICK: 'details next',
  ISSUE_HYDROGEN_SELECTED: 'Hydrogen selected',
}
