import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import events from 'src/app/modules/tagging/events'
import { DialogData } from '../../ui.types'

@Component({
  selector: 'member-no-matches.component-dialog',
  templateUrl: './member-no-matches.component.html',
  styleUrls: [
    '../shared-dialog.component.scss',
    './member-no-matches.component.scss',
  ],
})
export class MemberNoMatchesDialogComponent {
  pageType = events.shared.NOT_A_MEMBER_PAGE_TYPE

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onCallAAAClick() {
    this.data.close()
  }
}
