import {
  PACE_SETTER_SITUATION_CODES,
  PACE_SETTER_SITUATION_TYPES,
  PaceSetterDetails,
  PaceSetterOptions,
  PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES,
} from './issue.types'
import { IndexedCollection } from '../../shared/types'
import {PACE_SETTER_OVERRIDE_TYPES} from '../servicing-club/servicing-club.types'

export function constructDetailsSummary(details: PaceSetterDetails, isRapUser: boolean) {
  if (!details) {
    return ''
  }

  let additionalDetails = ''
  const selectedGroups = details.selectedGroups
  if (selectedGroups.length && selectedGroups[0].lists.length) {
    const firstList = selectedGroups[0].lists[0]
    if (firstList.members.length) {
      additionalDetails = firstList.members
        .map((detail) => detail.alternateLabel || detail.name)
        .join(', ')
    }
  }

  const name = isRapUser
    ? details.name.replace('Battery Test or Replace', 'Battery Issue')
    : details.name
  if (additionalDetails) {
    return [name, additionalDetails].join(': ')
  } else {
    return name
  }
}

const paceSetterMap = {
  [PACE_SETTER_SITUATION_CODES.T906]: PACE_SETTER_SITUATION_TYPES.ACCIDENT,
  [PACE_SETTER_SITUATION_CODES.T932]: PACE_SETTER_SITUATION_TYPES.STUCK,
  [PACE_SETTER_SITUATION_CODES.L302]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.T680]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.L701]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.L890]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.L801]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.L402]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.L403]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.T483]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.T480]: PACE_SETTER_SITUATION_TYPES.NEED_A_TOW,
  [PACE_SETTER_SITUATION_CODES.T180]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.L101]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.T2]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.T1]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.T3]: PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE,
  [PACE_SETTER_SITUATION_CODES.T4]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.T5]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.T6]: PACE_SETTER_SITUATION_TYPES.NEED_A_TOW,
  [PACE_SETTER_SITUATION_CODES.T7A]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.T7B]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.T7]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.T8]: PACE_SETTER_SITUATION_TYPES.STUCK,
}

export function getPaceSetterNameByCode(code: PACE_SETTER_SITUATION_CODES) {
  return paceSetterMap[code] || ''
}

export function getPacesetterCodeByValue(value: string) {
  const index = Object.values(PACE_SETTER_SITUATION_CODES).indexOf(value as PACE_SETTER_SITUATION_CODES);
  return PACE_SETTER_SITUATION_CODES[Object.keys(PACE_SETTER_SITUATION_CODES)[index]]
}

export const PACE_SETTER_OPTION_TEMPLATES: IndexedCollection<PaceSetterOptions> =
  {
    [PACE_SETTER_SITUATION_TYPES.ACCIDENT]: {
      name: 'Accident',
      defaultCode: {
        name: 'Accident',
        paceSetterCode: PACE_SETTER_SITUATION_CODES.T906,
        requiresTowing: true,
      },
      detailGroups: [],
    },
    [PACE_SETTER_SITUATION_TYPES.STUCK]: {
      name: 'Vehicle Stuck',
      defaultCode: {
        name: 'Vehicle Stuck',
        paceSetterCode: PACE_SETTER_SITUATION_CODES.T931,
        requiresTowing: true,
      },
      detailGroups: [
        {
          heading: 'We are sorry to hear your vehicle is stuck.',
          subHeading:
            'Will your vehicle require a tow after it has been freed and back on the roadway?',
          subHeadingClass: 'small-title',
          lists: [
            {
              name: 'Vehicle Stuck',
              mandatory: true,
              multiSelect: false,
              values: [
                {
                  name: 'Yes, my vehicle will need to be towed to another location.',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.T932,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.STUCK,
                  requiresTowing: true,
                  comment:
                    'Yes, my vehicle will need to be towed to another location.',
                },
                {
                  name: `No, I don't need a tow`,
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.T931,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.STUCK_NO_TOW,
                  requiresTowing: false,
                  comment: `No, I don't need a tow`,
                },
              ],
            },
          ],
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE]: {
      name: 'Battery Test or Replace',
      defaultCode: {
        name: 'Battery Test or Replace',
        paceSetterCode: PACE_SETTER_SITUATION_CODES.L302,
        requiresTowing: false,
      },
      detailGroups: [],
    },
    [PACE_SETTER_SITUATION_TYPES.FLAT_TIRE]: {
      name: 'Flat Tire',
      detailGroups: [
        {
          heading: 'Do you have spares for each flat tire?',
          allowNotes: false,
          noteInstructions: `Tell us about your issue, and we'll be better prepared when we arrive.`,
          lists: [
            {
              name: 'spares',
              mandatory: true,
              values: [
                {
                  name: 'Yes',
                  tagLabel: 'Spare Tires?',
                  alternateLabel: 'I have a spare',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L101,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.TIRE_SPARE,
                  requiresTowing: false,
                  comment: 'Have spare for each flat tire',
                },
                {
                  name: 'No',
                  tagLabel: 'Spare Tires?',
                  alternateLabel: `I don't have a spare`,
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.T180,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.TIRE_NO_SPARE,
                  requiresTowing: true,
                  comment: 'Don\'t have spare for each flat tire',
                },
              ],
            },
          ],
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.LOCKED_OUT]: {
      name: 'Locked Out',
      defaultCode: {
        name: 'Locked Out',
        paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
      },
      detailGroups: [
        {
          heading: 'Where are the keys?',
          isOptional: false,
          lists: [
            {
              name: 'key location',
              mandatory: true,
              multiSelect: false,
              values: [
                {
                  name: 'Locked in the vehicle',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_INSIDE,
                  requiresTowing: false,
                  comment: 'Locked in the vehicle',
                  needAdditionalRequirement: true,
                },
                {
                  name: 'My key is broken or lost',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L890,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_LOST,
                  requiresTowing: false,
                  comment: 'My key is broken or lost',
                  needAdditionalRequirement: true,
                },
                {
                  name: `My key won't turn in the ignition`,
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L801,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_NO_TURN,
                  requiresTowing: false,
                  comment: `My key won't turn in the ignition`,
                },
                {
                  name: `My key won't unlock the door`,
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L890,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_NO_WORK,
                  requiresTowing: false,
                  comment: `My key won't unlock the door`,
                  needAdditionalRequirement: true,
                },
                {
                  name: `I don't know`,
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.LOCK_UNSURE,
                  requiresTowing: false,
                },
              ],
            },
          ],
          additionalRequirements: {
            name: 'priority situation',
            heading:'I have a high priority situation',
            mandatory: false,
            multiSelect: false,
            values: [
              {
                name: PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES.LOVED_ONE_LOCKED_IN_CAR,
                requiresTowing: false,
                comment: 'A loved one is locked in the car.',
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
              },
              {
                name: PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES.MEDICATION_LOCKED_IN_CAR,
                requiresTowing: false,
                comment: 'My medication is locked in the car.',
                paceSetterCode: PACE_SETTER_SITUATION_CODES.L701,
              },
            ],
          },
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL]: {
      name: 'Fuel/EV Charge',
      detailGroups: [
        {
          heading: 'Please select the fuel type for your vehicle.',
          subHeading:
            'Additional fees may apply depending on membership level.',
          lists: [
            {
              name: 'fuelType',
              mandatory: true,
              values: [
                {
                  name: 'Gasoline',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L402,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.FUEL_GAS,
                  requiresTowing: false,
                  comment: 'Gas Vehicle',
                },
                {
                  name: 'Diesel Fuel',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L403,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.FUEL_DIESEL,
                  requiresTowing: false,
                  comment: 'Diesel Vehicle',
                },
                {
                  name: 'Electric Vehicle',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.T483,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.FUEL_ELECTRIC,
                  requiresTowing: true,
                  comment: 'Electric Vehicle',
                },
                {
                  name: 'Hydrogen/Alternate',
                  comment: 'Hydrogen/Alternate',
                },
              ],
            },
          ],
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.NEED_A_TOW]: {
      name: 'Need a tow',
      defaultCode: {
        name: 'Need a tow',
        paceSetterCode: PACE_SETTER_SITUATION_CODES.T480,
        requiresTowing: true,
      },
      detailGroups: [],
    },
    [PACE_SETTER_SITUATION_TYPES.CAR_WONT_START]: {
      name: `My Car Won't Start`,
      detailGroups: [
        {
          heading: `What's happening?`,
          allowNotes: false,
          noteInstructions:
            'Is the check engine light on?  Can you see anything else that might help us diagnose the issue?',
          lists: [
            {
              name: `Car Won't Start`,
              mandatory: true,
              multiSelect: false,
              values: [
                {
                  name: 'Vehicle died while driving',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.T680,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START_DIED,
                  comment: 'Vehicle died while driving',
                  requiresTowing: true,
                },
                {
                  name: 'I turn the key and nothing happens',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L302,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START,
                  comment: 'I turn the key and nothing happens',
                },
                {
                  name: 'I turn the key and hear a clicking sound',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L302,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START_CLICK,
                  comment: 'I turn the key and hear a clicking sound',
                  soundName: 'assets/sounds/click.mp3',
                },
                {
                  name: 'I turn the key and my car cranks, but does not start',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.T480,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START_CRANK,
                  comment: 'I turn the key and my car cranks, but does not start',
                  requiresTowing: true,
                  soundName: 'assets/sounds/crank.mp3',
                },
                {
                  name: 'Battery issue',
                  paceSetterCode: PACE_SETTER_SITUATION_CODES.L301,
                  paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES.WONT_START_BATT,
                  comment: 'Battery issue',
                },
              ],
            },
          ],
        },
      ],
    },
  }

export const defaultOptions = {
  name: 'Service Request',
  detailGroups: [] as any,
  defaultCode: undefined,
}
