<fieldset>
  <legend> Membership </legend>
<section class="minimal-credentials no-shadow">
  <div class="minimal-credentials_membership-line"
       [style]="(isMobileNumberAuth$ | async) ? 'margin-top: 15px;' : ''"
       role="navigation"
       aria-labelledby="membership-number">
    <label for="membershipNumber"
           id="membership-number">Membership Number</label>
    <aria-link-button elementId="use-member-name"
                      class="minimal-credentials_no-membership color-black  link-use-member-name"
                      (clickLink)="setUseMemberName($event)"
                      *ngIf="!(isMobileNumberAuth$ | async)">
      Use Member Name
    </aria-link-button>
  </div>
  <input name="membershipNumber"
         id="membershipNumber"
         type="tel"
         [textMask]="{ mask: mask, keepCharPositions: true }"
         [ngrxFormControlState]="controls.membershipNumber"
         placeholder="16 digit number on your AAA card"
         pattern="^\d{3}\s{2}\d{3}\s{2}\d{9}\s{2}\d$"
         aria-required="true"
         autocomplete="on"
         required/>
  <app-form-error [control]="controls?.membershipNumber"
                  name='membershipNumber'
                  type='required'>
    Membership number is required.
  </app-form-error>
  <app-form-error [control]="controls?.membershipNumber"
                  name='membershipNumber'
                  type='pattern'>
    Membership number must be exactly 16 characters long.
  </app-form-error>

  <div class="minimal-credentials_postal-code-line">
    <label for="zipCode">Home Zip Code</label>
    <input name="zipCode"
           id="zipCode"
           type="tel"
           placeholder="Enter Your Zip Code"
           [textMask]="{ mask: zipCodeMask, keepCharPositions: true }"
           minLength="5"
           [ngrxFormControlState]="controls.zipCode"
           aria-required="true"
           autocomplete="postal-code"
           required/>

    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='required'> Zip Code is required.
    </app-form-error>
    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='minLength'> Zip Code must be 5 characters long.
    </app-form-error>
    <app-form-error [control]="controls?.zipCode"
                    name='zipCode'
                    type='maxLength'> Zip Code must be 5 characters long.
    </app-form-error>
  </div>
</section>
</fieldset>
