import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { MainComponent } from './main.component'
import { IsAuthorizedRouteGuard } from '../shared/route-guard/is-authorized.route-guard'
import { IsNotAuthorizedRouteGuard } from '../shared/route-guard/is-not-authorized.route-guard'

export enum RouteTypes {
  AUTH = 'auth',
  SIGNIN = 'auth/signin',
  DASHBOARD = 'dashboard',
  STEPS = 'steps',
  RAP = 'rap',
  LOGOUT = 'logout',
}

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: RouteTypes.SIGNIN,
        pathMatch: 'full',
      },
      {
        path: RouteTypes.AUTH,
        loadChildren: () =>
          import('./auth/auth.module').then((m) => m.AuthModule),
        canActivate: [IsNotAuthorizedRouteGuard],
      },
      {
        path: RouteTypes.RAP,
        loadChildren: () =>
          import('./rap/rap.module').then((m) => m.RapModule),
        canActivate: [IsNotAuthorizedRouteGuard],
      },
      {
        path: RouteTypes.STEPS,
        loadChildren: () =>
          import('./wizard/wizard.module').then((m) => m.WizardModule),
        canActivate: [IsAuthorizedRouteGuard],
      },
      {
        path: RouteTypes.DASHBOARD,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [IsAuthorizedRouteGuard],
      },
      {
        path: RouteTypes.LOGOUT,
        loadChildren: () =>
          import('./logout/logout.module').then((m) => m.LogoutModule),
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
