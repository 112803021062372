import { Action } from '@ngrx/store'
import * as MemberActions from '../../modules/member/member.actions'

// TODO remove
export function getActionErrorMessage(action: Action): string {
  let failureContent = null
  if (
    action.type === MemberActions.MEMBER_INFO.FAILURE ||
    action.type === MemberActions.MEMBER_BASIC_INFO.FAILURE
  ) {
    // Action specific error message
    failureContent = `<p>An error occurred while trying to retrieve your member information.<br/>
      Please call AAA for additional assistance:</p>`
  } else if (action.type === MemberActions.VEHICLE_ADD.FAILURE) {
    // Action specific error message
    failureContent = `<p>An error occurred while trying to add a new vehicle to your account.<br/>
      Please call AAA for additional assistance:</p>`
  } else if (action.type === MemberActions.VEHICLE_DELETE.FAILURE) {
    // Action specific error message
    failureContent = `<p>An error occurred while trying to remove this vehicle.<br/>
      Please call AAA for additional assistance:</p>`
  } else if (action.type === MemberActions.VEHICLE_LOAD.FAILURE) {
    // Action specific error message
    failureContent = `<p>An error occurred while trying to load the information for your vehicles.<br/>
      Please call AAA for additional assistance:</p>`
  } else if (action.type === MemberActions.VEHICLE_UPDATE.FAILURE) {
    // Action specific error message
    failureContent = `<p>An error occurred while trying to update your vehicle's information<br/>
        Please call AAA for additional assistance:</p>`
  }

  console.log(action)

  return failureContent
}
