<app-skip-to-content [elementId]="targetElementId">
</app-skip-to-content>

<div class="aaa" [ngClass]="{'hide': (isTransitioning$ | async)}">
  <app-splashscreen [hidden]="(isSplashscreenHidden$ | async)" [step]="(step$ | async)"></app-splashscreen>
  <app-header></app-header>
  <main role="main" class="app" [ngClass]="{'without-header': !(showHeader$ | async) }">
    <router-outlet></router-outlet>
  </main>
</div>
<app-dialog-invoker></app-dialog-invoker>
