import { FSA } from 'src/app/shared/types'
import { NOTIFY_AUTOMATED_ACTION } from './tagging.actions'
import { CALL_STATUS_CODES } from '../dashboard/calls.types'

export interface TaggingState {
  [callIdentifier: string]: CALL_STATUS_CODES
}

export const initialState: TaggingState = {}

export function reducer(state: TaggingState = initialState, action: FSA) {
  switch (action.type) {
    case NOTIFY_AUTOMATED_ACTION:
      if (action.meta) {
        return {
          ...state,
          [action.meta.callIdentifier]: action.meta.callStatus,
        }
      }
      return state
    default:
      return state
  }
}
