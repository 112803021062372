<ng-container *ngIf="dialogErrors?.message">
  <div [class]="dialogErrors.cssClass"
       [@slideInAnimation]
       *ngIf="show && !isMobileNumberAuth">
    <p id="lfaMessage" [innerHTML]="dialogErrors.message"></p>
    <p style="margin-top: 20px;">{{ dialogErrors.cta }}
      <a
        *ngIf="dialogErrors.event"
        style="cursor: pointer;"
        class="link-use-member-number blue-link"
        (click)="dialogErrors.event()">
        {{ dialogErrors.link }}
      </a>
      <a
        *ngIf="dialogErrors.href"
        style="cursor: pointer;"
        class="link-use-member-number blue-link"
        [href]="dialogErrors.href">
        {{ dialogErrors.link }}
      </a>
      .</p>
  </div>

  <div [class]="dialogErrors.cssClass"
       [@slideInAnimation]
       *ngIf="show && isMobileNumberAuth">
    <p [innerHTML]="dialogErrors.message"></p>
    <p style="margin-top: 20px;" *ngIf="dialogErrors.event" > Try again, use your
      <a *ngIf="dialogErrors.event"
         style="cursor: pointer;"
         class="link-use-member-number blue-link"
         (click)="dialogErrors.event()">
        {{ dialogErrors.link }}
      </a>
      <span *ngIf="dialogErrors.secondEvent">or</span>
      <a *ngIf="dialogErrors.secondEvent"
         style="cursor: pointer;"
         class="link-use-member-number blue-link"
         (click)="dialogErrors.secondEvent()">
        {{ dialogErrors.secondLink }}
      </a>
    </p>
    <p style="margin-top: 20px;" *ngIf="dialogErrors.href">{{ dialogErrors.cta }}
      <a style="cursor: pointer;"
         class="link-use-member-number blue-link"
         [href]="dialogErrors.href">
        call AAA
      </a>.
    </p>
  </div>
</ng-container>
