<div
  class="cancel-request"
  [ngClass]="{
    cancelling: cancelling,
    'on-top': !bottom,
    'on-bottom': bottom,
    'default-color': defaultColor
  }"
>
  <ng-container *ngIf="!cancelling && (isSignedIn$ | async)">
  </ng-container>

  <ng-container *ngIf="!cancelling && (canCancel$ | async)">
    <app-button
      type="button"
      (buttonClick)="cancelCall()"
      class="cancel-button"
      [disabled]="(loading$ | async) === true || (isCallRequesting$ | async)"
      inverted="true"
      [loading]="(loading$ | async) === true"
      [tabIndex]="tabIndex + 1"
      color="cancel"
      [size]="bottom ? 'cozy' : 'condensed'"
      [attr.aria-label]="cancelRequestLabel()"
    >
      {{cancelRequestLabel()}}
    </app-button>
  </ng-container>

  <ng-container *ngIf="cancelling && (canCancel$ | async)">
    <span class="normal-text cancel-prompt">
      Cancel
      <span class="cancel-details"> service request</span>
      ?
    </span>
    <app-button
      class="cancel-confirm yes"
      (click)="confirmCancel()"
      [tabIndex]="tabIndex + 2"
      color="cancel"
      [size]="bottom ? 'cozy' : 'condensed'"
    >
      YES
    </app-button>
    <app-button
      class="cancel-confirm no"
      (click)="cancelCancel()"
      [tabIndex]="tabIndex + 3"
      inverted="true"
      color="cancel"
      [size]="bottom ? 'cozy' : 'condensed'"
    >
      NO
    </app-button>
  </ng-container>
</div>
