import { Injectable } from '@angular/core'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, from } from 'rxjs'
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators'
import {
  batteryQuoteSuccess,
  batteryQuoteFailure,
  BATTERY_QUOTES,
  batteryQuoteRequest,
  batteryQuoteNotify,
} from './quotes.action'
import { IndexedCollection } from 'src/app/shared/types'
import { QuotesService } from './quotes.service'
import { BatteryQuote, BatteryQuotePayload } from './quotes.types'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'
import { openPromptDialog } from '../ui/ui.actions'
import { PromptDialogTypes } from '../ui/ui.types'
import { generateVehicleSlug } from 'src/app/shared/utils/generateVehicleSlug'
import { TaggingService } from '../tagging/tagging.service'
import { Store } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { selectQuotesParams } from './quotes.selectors'
import events from '../tagging/events'

@Injectable()
export class QuotesEffects {
  constructor(
    private actions$: Actions,
    private quotesService: QuotesService,
    private errorReportingService: ErrorReportingService,
    private store$: Store<AAAStore>,
    private tagging: TaggingService
  ) { }

  getBatteryQuote$ = createEffect(
    (): Observable<
      | ReturnType<typeof batteryQuoteSuccess>
      | ReturnType<typeof batteryQuoteFailure>
    > =>
      this.actions$.pipe(
        ofType<ReturnType<typeof batteryQuoteRequest>>(BATTERY_QUOTES.REQUEST),
        switchMap((action) =>
          from(this.quotesService.getBatteryQuotes(action.payload)).pipe(
            concatMap(
              (batteryQuotes: IndexedCollection<Array<BatteryQuote>>) => [
                batteryQuoteSuccess({ payload: batteryQuotes }),
                batteryQuoteNotify({
                  payload: {
                    [generateVehicleSlug(
                      {
                        year: action.payload.imCarYear,
                        make: action.payload.smCarMake,
                        model: action.payload.smCarModel,
                      },
                      action.payload.imZipCode
                    )]: true,
                  },
                }),
              ]
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                batteryQuoteFailure
              )
            )
          )
        )
      )
  )

  handleBatteryQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof batteryQuoteSuccess>>(BATTERY_QUOTES.SUCCESS),
      filter((action) => Object.keys(action.payload).length > 0),
      map(() =>
        openPromptDialog({
          payload: {
            type: PromptDialogTypes.BATTERY_QUOTES,
            panelClass: 'battery-quotes-modal',
          },
        })
      )
    )
  )

  handleBatteryQuoteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BATTERY_QUOTES.FAILURE),
      withLatestFrom(
        this.store$.select(selectQuotesParams)
      ),
      map(([_, { vehicle, postalCode }]) => {
        this.tagging.setAutomatedEvent(
          events.dashboard.BATTERY_QUOTES_FAILURE,
          events.dashboard.SERVICE_TRACKING_PAGE_TYPE,
          null,
          {
            vehicleYear: vehicle.year.toString(),
            vehicleMake: vehicle.make,
            vehicleModel: vehicle.model,
            zipCode: postalCode,
          }
        )

        return batteryQuoteNotify({
          payload: {
            [generateVehicleSlug(
              {
                year: vehicle.year.toString(),
                make: vehicle.make,
                model: vehicle.model,
              },
              postalCode
            )]: true,
          },
        })
      })
    )
  )
}
