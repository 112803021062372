import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core'
import { Subject } from 'rxjs'
import { AbstractComponent } from 'src/app/shared/abstract.component'

declare let Hammer: any

@Component({
  selector: 'map-touch-layer',
  templateUrl: './map-touch-layer.component.html',
  styleUrls: ['./map-touch-layer.component.scss'],
})
export class MapTouchLayerComponent extends AbstractComponent {
  @ViewChild('touchLayer') touchLayer: ElementRef
  @Output() stopped: Subject<boolean> = new Subject()
  displayTipOverlay = false

  @Input() preventTouch = true
  @Input() blockOverlay = false

  constructor(private element: ElementRef) {
    super()
  }

  ngOnInit() {
    if (this.preventTouch) {
      this.addLayerListener()
    }
  }

  tblock = (e) => {
    if (e.touches.length > 1) {
      e.preventDefault()
      e.stopPropagation()
      this.touchLayer.nativeElement.classList.add('disable-touch')
      this.stopped.next(true)
      return false
    }

    this.touchLayer.nativeElement.classList.remove('disable-touch')
    this.stopped.next(false)

    return true
  }

  private addLayerListener() {
    this.element.nativeElement.addEventListener('touchstart', this.tblock, false)
    this.element.nativeElement.addEventListener('touchmove', this.tblock, false)
    this.element.nativeElement.addEventListener('touchend', this.tblock, false)
  }
}
