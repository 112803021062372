import {
  AddressWithLandmark,
  GoogleLocationMarker,
  LastSearchLocation,
  LocationWithMarker,
  TowingDistanceCoords,
  TowLocation,
} from '../location.types'
import { props, createAction } from '@ngrx/store'
import { createAsyncConstants } from 'src/app/shared/utils'
import {
  GoogleCoordinates,
  GOOGLE_LOCATION_TYPES,
} from '../google-geocode/types'

export const COORDS_LOOKUP = createAsyncConstants('COORDS_LOOKUP')
export const HOME_ADDRESS_LOOKUP = createAsyncConstants('HOME_ADDRESS_LOOKUP')
export const ADDRESS_LOOKUP = createAsyncConstants('ADDRESS_LOOKUP')
export const SET_TOW_DESTINATION = createAsyncConstants('SET_TOW_DESTINATION')
export const SET_TOW_DESTINATION_MARKER = 'SET_TOW_DESTINATION_MARKER'
export const RESET_TOW_DESTINATION = 'RESET_TOW_DESTINATION'
export const GET_TOW_DESTINATION_COORDINATES = 'GET_TOW_DESTINATION_COORDINATES'
export const CONFIRM_TOW_DISTANCE = 'CONFIRM_TOW_DISTANCE'
export const SET_AAR_ADDRESS = 'SET_AAR_ADDRESS'
export const CHECK_TOW_DISTANCE = 'CHECK_TOW_DISTANCE'
export const SET_LAST_TOW_TO_SEARCH_LOCATION = 'SET_LAST_TOW_TO_SEARCH_LOCATION'
export const OPEN_MAP_MODAL = 'OPEN_MAP_MODAL'
export const SET_IS_FIRST_CLICK_SMM = 'SET_IS_FIRST_CLICK_SMM'
export const SET_ENTER_AN_ADDRESS = 'SET_ENTER_AN_ADDRESS'


export const SET_TOWING_STEP = 'SET_TOWING_STEP'

export enum NON_AAR_TOWING_NAMES {
  CUSTOM = 'Custom Address',
  HOME = 'Home',
}

export const resetTowDestination = createAction(RESET_TOW_DESTINATION)

export const confirmTowDistance = createAction(CONFIRM_TOW_DISTANCE)

export const setOpenMapModal = createAction(
  OPEN_MAP_MODAL,
  props<{ payload: boolean}>()
  )

  export const setEnterAnAddress = createAction(
    SET_ENTER_AN_ADDRESS,
    props<{payload: boolean}>()
  )

  export const setIsFirstClickSMM = createAction(
  SET_IS_FIRST_CLICK_SMM,
  props<{ payload: boolean}>()
  )

export const checkTowDistance = createAction(
  CHECK_TOW_DISTANCE,
  props<{ payload: TowingDistanceCoords }>()
)

export const requestSetTowDestination = createAction(
  SET_TOW_DESTINATION.REQUEST,
  props<{ payload: TowLocation | GoogleLocationMarker }>()
)

export const completeSetTowDestination = createAction(
  SET_TOW_DESTINATION.SUCCESS,
  props<{ payload: TowLocation }>()
)

export const notifySetTowDestinationFailure = createAction(
  SET_TOW_DESTINATION.FAILURE
)

export const getTowDestinationCoordinates = createAction(
  GET_TOW_DESTINATION_COORDINATES,
  props<{ payload: string }>()
)

export const requestCoordsLookup = createAction(
  COORDS_LOOKUP.REQUEST,
  props<{
    payload: GoogleCoordinates
    meta?: { preferredType: GOOGLE_LOCATION_TYPES }
  }>()
)

export const completeCoordsLookup = createAction(
  COORDS_LOOKUP.SUCCESS,
  props<{ payload: LocationWithMarker }>()
)

export const notifyCoordsLookupFailure = createAction(COORDS_LOOKUP.FAILURE)

export const requestHomeAddressLookup = createAction(
  HOME_ADDRESS_LOOKUP.REQUEST
)

export const completeHomeAddressLookup = createAction(
  HOME_ADDRESS_LOOKUP.SUCCESS,
  props<{ payload: LocationWithMarker }>()
)

export const notifyHomeAddressLookupFailure = createAction(
  HOME_ADDRESS_LOOKUP.FAILURE
)

export const requestAddressLookup = createAction(
  ADDRESS_LOOKUP.REQUEST,
  props<{ payload: AddressWithLandmark }>()
)

export const completeAddressLookup = createAction(
  ADDRESS_LOOKUP.SUCCESS,
  props<{ payload: LocationWithMarker }>()
)

export const notifyAddressLookupFailure = createAction(ADDRESS_LOOKUP.FAILURE)

export const setAARAddress = createAction(
  SET_AAR_ADDRESS,
  props<{ payload: LocationWithMarker }>()
)

export const setTowDestinationMarker = createAction(
  SET_TOW_DESTINATION_MARKER,
  props<{ payload: GoogleLocationMarker }>()
)

export const setLastTowToSearchLocation = createAction(
  SET_LAST_TOW_TO_SEARCH_LOCATION,
  props<{ payload: LastSearchLocation }>()
)

export const setTowingStep = createAction(
  SET_TOWING_STEP,
  props<{ payload: { step: string } }>()
)
