import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { InvisibleReCaptchaComponent, ReCaptcha2Component } from 'ngx-captcha'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { selectSearchMembersResults } from 'src/app/modules/member/member.selectors'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import {
  MembershipNumberAuthFormState,
  MobilePhoneAuthFormState,
  NameAuthFormState,
} from 'src/app/modules/ui/forms/forms.reducer'
import {
  selectAuthForm,
  selectMobilePhoneNumberAuth,
  selectNameAuthForm,
} from 'src/app/modules/ui/forms/forms.selectors'
import {
  selectDefaultZipCode,
  selectIsInstallBannerClosed,
  selectMobileNumberAuth,
  selectMockCaptcha,
} from 'src/app/modules/ui/ui.selectors'
import { environment as config } from 'src/environments/environment'
import { AbstractComponent } from '../../../shared/abstract.component'
import { AAAStore } from 'src/app/store/root-reducer'
import {
  AUTH,
  AUTH_RESET,
  notifyAuthFailure,
  requestAuth,
  setAuthMethod,
  setClickedUseAnotherForm,
} from 'src/app/modules/auth/auth.actions'
import { selectAuthMethod, selectAuthState, selectIsAgent, selectIsSecure, } from 'src/app/modules/auth/auth.selectors'
import {
  MEMBER_BASIC_INFO,
  MEMBER_INFO,
  MEMBERS_SEARCH,
  searchMembersInfoRequest,
  searchMembersRequest,
} from 'src/app/modules/member/member.actions'
import { selectIsLoading } from 'src/app/modules/ui/loading/loading.selectors'
import {
  animateSplashscreenHide,
  closeDialog,
  closeInstallBanner,
  openMessageDialog,
  openPromptDialog,
  setWatchRecaptchaPosition,
} from 'src/app/modules/ui/ui.actions'
import { MessageDialogTypes, PromptDialogTypes, } from 'src/app/modules/ui/ui.types'
import { AuthMethods, BaseAuthRequestParams, } from 'src/app/modules/auth/auth.types'
import { AuthState } from 'src/app/modules/auth/auth.reducer'
import { isMobileApp } from '../../../shared/utils/app-detect'
import { CALL_STATUS } from 'src/app/modules/dashboard/calls-statuses/call-status.actions'
import { Actions, ofType } from '@ngrx/effects'
import events from '../../tagging/events'
import { animate, style, transition, trigger } from '@angular/animations'
import { membershipMask, phoneMask } from './minimal-credentails.constants';
import { buildTitle } from '../../../shared/utils/title';
import { Title } from '@angular/platform-browser';
import { RapService } from '../../rap/rap.service';
import { CredentialsManagementService } from '../credential-management/credential-management.service'
import auth from '../../tagging/events/auth'
import { is16DigitNumberString } from '../../../shared/utils/membership-number'

@Component({
  selector: 'app-minimal-credentials',
  templateUrl: './minimal-credentials.component.html',
  styleUrls: ['./minimal-credentials.component.scss'],
  animations: [
    trigger('slideInAnimation', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({'max-height':0, opacity: 0}),
        animate(500, style({'max-height':100, opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({'max-height':0}))
      ])
    ])
  ]
})
export class MinimalCredentialsComponent
  extends AbstractComponent
  implements OnInit, OnDestroy {
  @Input() displayNotMember = true
  @Input() displayCaptcha
  @Input() tabIndexPosition = 0

  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>
  @ViewChild('captchaElement') captchaElement: InvisibleReCaptchaComponent
  @ViewChild('captchaElementSimple') captchaElementSimple: ReCaptcha2Component

  authParams = new BehaviorSubject<any>({
    accessToken: null,
    memId: '',
    ets: '',
    isSecure: false,
  })
  captcha // Required for an Angular reason
  captchaBadge: 'bottomright' | 'bottomleft' | 'inline' = 'inline'
  captchaToken: string
  captchaError = false
  siteKey = config.googleCaptchaKey
  mask = membershipMask
  phoneNumberMask = phoneMask

  authForm: FormGroupState<MembershipNumberAuthFormState> = null
  nameAuthForm: FormGroupState<NameAuthFormState> = null
  mobileNumberAuthForm: FormGroupState<MobilePhoneAuthFormState> = null
  allowNameAuth: boolean
  isNameTabActive = true
  isNumberTabActive = false

  isAgent = false
  streetForm: any = {
    value: '',
  }

  isNameFormActive = false
  isMembershipNumberFormActive = false
  isMobileNumberFormActive = false

  rememberMe = false
  isSecure$ = this.store$.pipe(
    select(selectIsSecure)
  )
  isMobileNumberAuth$: Observable<boolean> = this.store$
  .pipe(select(selectMobileNumberAuth))

  searchResults$ = this.store$.pipe(
    select(selectSearchMembersResults),
    filter((searchItems) => searchItems?.members?.length >= 1)
  )

  authForm$: Observable<FormGroupState<MembershipNumberAuthFormState>> =
    this.store$.pipe(select(selectAuthForm))
  nameAuthForm$: Observable<FormGroupState<NameAuthFormState>> =
    this.store$.pipe(select(selectNameAuthForm))
  mobileNumberAuthForm$: Observable<FormGroupState<MobilePhoneAuthFormState>> =
    this.store$.pipe(select(selectMobilePhoneNumberAuth))
  allowNameAuth$: Observable<AuthMethods> = this.store$.pipe(
    select(selectAuthMethod)
  )

  defaultUserZipCode$: Observable<string> = this.store$.pipe(
    select(selectDefaultZipCode)
  )
  isSigningIn$: Observable<boolean> = combineLatest([
    this.store$.pipe(select(selectIsLoading(AUTH.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_BASIC_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBERS_SEARCH.ACTION))),
    this.store$.pipe(select(selectIsLoading(CALL_STATUS.ACTION))),
  ]).pipe(map((areActionsLoading) => areActionsLoading.some(Boolean)))
  authState$: Observable<[AuthState, boolean]> = combineLatest([
    this.store$.pipe(select(selectAuthState)),
    this.isSigningIn$,
  ])
  isAgent$: Observable<boolean> = this.store$.pipe(select(selectIsAgent))
  isResetNeeded$: Observable<boolean> = this.actions$.pipe(
    ofType<ReturnType<typeof notifyAuthFailure>>(AUTH.FAILURE, AUTH_RESET),
    map(() => true)
  )

  isInstallBannerClosed$: Observable<boolean> = this.store$.pipe(
    select(selectIsInstallBannerClosed)
  )

  mockCaptcha$ = this.store$.pipe(
    select(selectMockCaptcha)
  )

  mockCaptcha = false
  authMethod
  isMobileNumberAuth = false
  isAuthMethodChangeClicked = false
  isErrorBoxDisplayed = false

  get formState(): FormGroupState<any> {
    if (this.allowNameAuth) {
      return this.nameAuthForm
    }
    if (this.isMobileNumberFormActive) {
      return this.mobileNumberAuthForm
    }
    return this.authForm
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  private captchaIntervaZoom = null

  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private titleService: Title,
    private rapService: RapService,
    private credentialsManager: CredentialsManagementService,
  ) {
    super()
    this.titleService.setTitle(buildTitle('Sign In', this.rapService.isRapUser()))
  }

  ngOnDestroy() {
    this.store$.dispatch(
      closeDialog({
        payload: {type: PromptDialogTypes.AUTH_PHONE_NUMBER_DIALOG}
      })
    )
  }

  ngOnInit() {
    this.subscriptions.push(
      // TODO investigate if it's still required
      this.authState$.subscribe(([authState]) => {
        this.authParams.next(authState)
      }),
      // TODO move to effects (unrelated to forms and captcha)
      this.isSecure$.pipe(filter((isSecure) => !isSecure)).subscribe((_) => {
        this.store$.dispatch(animateSplashscreenHide())
      }),
      this.authForm$.subscribe((form) => {
        this.authForm = form
      }),
      this.nameAuthForm$.subscribe((form) => {
        this.nameAuthForm = form
      }),
      this.mobileNumberAuthForm$.subscribe((form) => {
        this.mobileNumberAuthForm = form
      }),
      this.defaultUserZipCode$.subscribe((zipCode) => {
        ;(this.authForm.controls.zipCode as any).value =
          zipCode || (null as any)
        ;(this.nameAuthForm.controls.zipCode as any).value =
          zipCode || (null as any)
      }),
      this.isAgent$.subscribe((agent) => {
        this.isAgent = agent
      }),
      this.isResetNeeded$
        .pipe(filter((isResetNeeded) => isResetNeeded))
        .subscribe(() => {
          if (this.captchaElementSimple) {
            this.captchaElementSimple.reCaptchaApi?.reset()
          }
          if (this.captchaElement) {
            this.captchaElement.resetCaptcha()
          }
        }),
      this.allowNameAuth$.subscribe((authMethod) => {
        this.allowNameAuth = authMethod === AuthMethods.MEMBER_NAME
        this.isNameFormActive = authMethod === AuthMethods.MEMBER_NAME
        this.isMobileNumberFormActive = authMethod === AuthMethods.MOBILE_PHONE
        this.isMembershipNumberFormActive = authMethod === AuthMethods.MEMBERSHIP_NUMBER
        this.isAuthMethodChangeClicked = this.authMethod && this.authMethod !== authMethod && this.isMobileNumberAuth
        this.authMethod = authMethod
      }),
      this.mockCaptcha$.subscribe((mockCaptcha) => {
        this.mockCaptcha = mockCaptcha
      }),
    )

    this.store$.dispatch(closeInstallBanner({payload: false}))

    if (this.allowNameAuth) {
      this.taggingService.setAutomatedEvent(
        events.auth.FORM_NAME_AUTH_PROMPT,
        events.auth.PAGE_TYPE
      )
    }

    if (!this.allowNameAuth) {
      this.taggingService.setAutomatedEvent(
        events.auth.FORM_MEMBERSHIP_AUTH_PROMPT,
        events.auth.PAGE_TYPE
      )
    }

    this.rememberMe = window.localStorage.getItem('remember-credentials') === 'true'
    if (this.rememberMe && this.isPasswordCredentialSupported()) {
      this.credentialsManager?.getCredentials()?.then((credentials) => {
        if (credentials?.firstName) {
          this.setMemberNameForm(credentials.firstName, credentials.lastName, credentials.zipCode)
        } else if (credentials?.memberNumber) {
          this.setMembershipNumberForm(credentials.memberNumber, credentials.zipCode)
        }
      })
    }
  }

  openPrivacyPolicy() {
    this.taggingService.setClickEvent(
      events.shared.PRIVACY_POLICY_CLICK,
      events.auth.PAGE_TYPE
    )

    window.open('https://www.aaa.com/privacy')
  }

  openTerms() {
    this.taggingService.setClickEvent(
      events.shared.TERMS_AND_CONDITIONS_CLICK,
      events.auth.PAGE_TYPE
    )

    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.IFRAME_DIALOG,
          panelClass: 'iframe-dialog',
          params: {
            ariaLabel: 'Terms and conditions content',
            url: 'https://www.aaa.com/AAA/common/drrsmsterms/terms.html',
          },
        },
      })
    )
  }

  openCannotRemember($event) {
    $event.stopPropagation()
    $event.preventDefault()

    this.taggingService.setClickEvent(
      events.auth.LOST_MEMBERSHIP_CLICK,
      events.auth.PAGE_TYPE
    )

    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: MessageDialogTypes.CANT_REMEMBER_MY_ID,
          title: 'Cannot Remember',
        },
      })
    )
  }

  openNotMember($event) {
    $event?.stopPropagation()
    $event?.preventDefault()

    this.taggingService.setClickEvent(
      events.auth.NOT_A_MEMBER_CLICK,
      events.auth.PAGE_TYPE
    )
    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: MessageDialogTypes.NOT_MEMBER,
          title: 'Not a Member?',
        },
      })
    )
  }

  doAuth(captchaToken) {
    const firstName = this.formState.value.firstName
    const lastName = this.formState.value.lastName
    const isMembershipNumberInNames = is16DigitNumberString(firstName) || is16DigitNumberString(lastName)
    const memberNumber = !isMembershipNumberInNames ?
      this.formState.value.membershipNumber && this.formState.value.membershipNumber.replace(/\s/g, '') :
      (is16DigitNumberString(firstName) ? firstName : lastName)

    if(this.isMobileNumberFormActive) {
      this.mobileAuth(captchaToken)
    } else if (this.allowNameAuth && !isMembershipNumberInNames) {
      this.searchForMembers(captchaToken)
    } else {
      const { method } = this.authParams.getValue()
      this.signOn({ captchaToken, method, firstName, lastName, memberNumber, isMembershipNumberInNames })
    }
    this.stopCapchaWatcher()
  }

  handleSubmit() {
    if (this.mockCaptcha) {
      this.captchaToken = 'DUMMY_CAPTCHA_TOKEN'
    }
    if (!this.captchaElement && !this.captchaElementSimple) {
      this.handleCaptchaSuccess(null)
      return
    }
    if (this.captchaElement || this.captchaElementSimple) {
      if (!this.captchaToken) {
        this.taggingService.setAutomatedEvent(auth.CAPTCHA_VALIDATION_FAILURE, auth.PAGE_TYPE)
        this.captchaError = true
        return
      }

      this.captchaError = false
      this.doAuth(this.captchaToken)
      this.captchaToken = null
      return
    }
  }

  handleCaptchaReady() {
    this.watchRecapchaPosition()
    this.handleRecapchaZooming()
  }

  handleCaptchaError() {
     this.captchaElement.resetCaptcha()
  }

  handleCaptchaSuccess(captchaToken: string) {
    this.taggingService.setAutomatedEvent(auth.CAPTCHA_VALIDATION_SUCCESS, auth.PAGE_TYPE)
    this.doAuth(captchaToken)
  }

  handleCaptchaSuccessSimple(captchaToken: string) {
    this.taggingService.setAutomatedEvent(auth.CAPTCHA_VALIDATION_SUCCESS, auth.PAGE_TYPE)
    this.captchaToken = captchaToken
    this.captchaError = false
    this.stopCapchaWatcher()
  }

  signOn({ captchaToken = null, isMembershipNumberInNames = false, firstName, lastName, memberNumber }: BaseAuthRequestParams) {
    const zipCode = this.formState.value.zipCode

    if (
      Boolean(zipCode) &&
      (Boolean(memberNumber) || (Boolean(firstName) && Boolean(lastName)))
    ) {
      this.taggingService.setClickEvent(
        events.auth.FORM_MEMBERSHIP_AUTH_REQUEST,
        events.auth.PAGE_TYPE
      )

      const authMethod = this.allowNameAuth && !isMembershipNumberInNames
        ? AuthMethods.MEMBER_NAME
        : AuthMethods.MEMBERSHIP_NUMBER
      if (isMembershipNumberInNames) {
        this.setMembershipNumberForm(memberNumber, zipCode)
      }
      const identifier = this.allowNameAuth && !isMembershipNumberInNames
        ? { firstName, lastName }
        : { memberNumber }
      const token = captchaToken !== null ? { captchaToken } : {}

      const payload = {
        zipCode: this.formState.value.zipCode,
        isSecure: false,
        method: authMethod,
        isAgent: this.isAgent,
        ...identifier,
        ...token,
        rememberMe: this.rememberMe,
      }
      this.store$.dispatch(
        requestAuth({
          payload,
        })
      )
    }
  }

  useMemberNumber($event?) {
    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }
    this.isNameFormActive = false
    this.isMembershipNumberFormActive = true
    this.isMobileNumberFormActive = false
    this.isAuthMethodChangeClicked = true

    this.store$.dispatch(setClickedUseAnotherForm())
    this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBERSHIP_NUMBER }))

    this.taggingService.setClickEvent(
      events.auth.USE_FORM_MEMBERSHIP_CLICK,
      events.auth.PAGE_TYPE
    )
  }

  usePhoneNumber($event?) {
    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }
    this.isErrorBoxDisplayed = false
    this.isNameFormActive = false
    this.isMembershipNumberFormActive = false
    this.isMobileNumberFormActive = true
    this.isAuthMethodChangeClicked = true

    this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MOBILE_PHONE }))

    this.taggingService.setClickEvent(
      events.auth.USE_FORM_MOBILE_CLICK,
      events.auth.PAGE_TYPE
    )
  }

  useMemberName($event?) {
    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }

    this.isNameFormActive = true
    this.isMembershipNumberFormActive = false
    this.isMobileNumberFormActive = false

    this.store$.dispatch(setClickedUseAnotherForm())
    this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBER_NAME }))
    this.isAuthMethodChangeClicked = true

    this.taggingService.setClickEvent(
      events.auth.USE_FORM_NAME_CLICK,
      events.auth.PAGE_TYPE
    )
  }

  // always scale captcha component to fit
  handleRecapchaZooming() {
    const _wrapper = this.captchaElementSimple?.captchaWrapperElem?.nativeElement;

    if(_wrapper) {
      this.captchaIntervaZoom = setInterval(() => {
        _wrapper.style.transform = 'scale(1)'
        _wrapper.style.transformOrigin = '0 0'

        if (this.captchaToken) {
          clearInterval(this.captchaIntervaZoom)
          return
        }
      }, 300)
    }
  }

  // continuously check captcha position and place it on
  // the middle of the screen
  watchRecapchaPosition() {
    this.store$.dispatch(
      setWatchRecaptchaPosition({ payload: true })
    )
  }

  stopCapchaWatcher() {
    this.store$.dispatch(
      setWatchRecaptchaPosition({ payload: false })
    )
  }

  mobileAuth(captchaToken = null) {
    this.taggingService.setClickEvent(
      events.auth.FORM_PHONE_NUMBER_AUTH_REQUEST,
      events.auth.PAGE_TYPE
    )
    const phoneNumber = this.formState.value.phoneNumber.replace(/\D+/g, '')
    const zipCode = this.formState.value.zipCode

    const payload = {
      captchaToken,
      zipCode,
      phoneNumber
    }

    this.store$.dispatch(searchMembersInfoRequest({ payload }))
  }
  /**
   * dispatch action to request members info
   *
   * @param captchaToken
   */
  searchForMembers(captchaToken = null) {
    this.taggingService.setClickEvent(
      events.auth.FORM_NAME_AUTH_REQUEST,
      events.auth.PAGE_TYPE
    )

    const firstName = this.formState.value.firstName
    const lastName = this.formState.value.lastName
    const zipCode = this.formState.value.zipCode
    const payload = {
      captchaToken,
      zipCode,
      firstName,
      lastName,
      rememberMe: this.rememberMe
    }

    this.store$.dispatch(searchMembersRequest({ payload }))
  }

  isWebApp() {
    return !isMobileApp()
  }

  setHasError(error) {
      this.isErrorBoxDisplayed = error
      this.changeDetector.detectChanges()
  }

  useMemberNameClicked(event) {
    this.useMemberName(event);
    this.isAuthMethodChangeClicked = true;
    this.isErrorBoxDisplayed = false
  }

  useMembershipNumberClicked(event) {
    this.useMemberNumber(event);
    this.isAuthMethodChangeClicked = true;
    this.isErrorBoxDisplayed = false
  }

  handleRememberMe(event) {
    this.rememberMe = event
  }

  isPasswordCredentialSupported() {
    return this.credentialsManager.isSupported()
  }

  setMembershipNumberForm(memberNumber, zipCode) {
    this.store$.dispatch({
      controlId: 'form.auth.membershipNumber',
      value: memberNumber,
      type: 'ngrx/forms/SET_VALUE'
    })
    this.store$.dispatch({
      controlId: 'form.auth.zipCode',
      value: zipCode,
      type: 'ngrx/forms/SET_VALUE'
    })
  }

  setMemberNameForm(firstName, lastName, zipCode) {
    this.store$.dispatch({
      controlId: 'form.nameauth.firstName',
      value: firstName,
      type: 'ngrx/forms/SET_VALUE'
    })
    this.store$.dispatch({
      controlId: 'form.nameauth.lastName',
      value: lastName,
      type: 'ngrx/forms/SET_VALUE'
    })
    this.store$.dispatch({
      controlId: 'form.nameauth.zipCode',
      value: zipCode,
      type: 'ngrx/forms/SET_VALUE'
    })
  }
}
