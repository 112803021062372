import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AxiosRequestConfig } from 'axios';
import { Observable } from 'rxjs';
import { XHRService } from 'src/app/shared/services/xhr.service';
import { IndexedCollection } from 'src/app/shared/types';
import { AAACallDataAdapter } from '../../shared/adapters/AAACallDataAdapter';
import { selectIsEligible } from '../member/member.selectors';
import { RapService } from '../rap/rap.service';
import { openMessageDialog } from '../ui/ui.actions';
import { MessageDialogTypes, StepTypes } from '../ui/ui.types';
import {
  CallDetailParams,
  DriverInfo,
  DriverInfoParams
} from './calls-statuses/call-status.types';
import {
  CallResponseSchema,
  CallStatusesSchema, CallStatusSchema, ClubSupportSchema,
  DriverInfoSchema
} from './calls.model';
import { AAACallCancel, AAACallData, AAACallStatus, CallResponse, ClubSupport, ServiceDeliveryCallStatus } from './calls.types';
import { indexCalls } from './calls.utils';
import { selectCreateCallTimeout, selectGetActiveCallsTimeout } from '../ui/timeout/timeout.selectors'


@Injectable({
  providedIn: 'root',
})
export class CallsService {
  isCallStatusRequestPending = false

  isEligible$: Observable<boolean> = this.store$.pipe(
    select(selectIsEligible)
  )
  eligible = false;

  getActiveCallsTimeout$: Observable<number> = this.store$.pipe(
    select(selectGetActiveCallsTimeout)
  )
  getActiveCallsTimeout;

  createCallTimeout$: Observable<number> = this.store$.pipe(
    select(selectCreateCallTimeout)
  )
  createCallTimeout;

  constructor(
    private xhr: XHRService,
    private store$: Store,
    private callAdapter: AAACallDataAdapter,
    private router: Router,
    private rapService: RapService,
  ) {
    this.isEligible$.subscribe(eligible => {
      this.eligible = eligible
    }),
    this.getActiveCallsTimeout$.subscribe(timeout => {
      this.getActiveCallsTimeout = timeout
    }),
      this.createCallTimeout$.subscribe(timeout => {
        this.createCallTimeout = timeout
      })
  }


  /**
   * @deprecated use getActiveCalls instead
   * @param retry
   * @param timeout
   */
  async getCallsStatuses(
    retry = true,
    timeout?: number
  ): Promise<IndexedCollection<AAACallStatus>> {
    if (this.isCallStatusRequestPending) {
      throw new Error('Duplicate request')
    }

    try {
      const response = await this.getOrRedirectToLocationWithinTimeout('v2/roadservice/calls/status', timeout, false)
      this.isCallStatusRequestPending = true

      if (!response) {
        return {}
      }
      await CallStatusesSchema.validate(response)

      const calls = []
      if (response.callStatuses && response.callStatuses.length) {
        calls.push(...response.callStatuses)
      }
      if (response.callHistory && response.callHistory.length) {
        calls.push(...response.callHistory)
      }

      return indexCalls(calls)
    } catch (error) {
      throw error
    } finally {
      this.isCallStatusRequestPending = false
    }
  }

  async addCall(data: AAACallData): Promise<CallResponse | void> {
    try {
      data = this.callAdapter.adapt(data)

      let response = await this.xhr.clubRequest<CallResponse>({
        url: 'v2/roadservice/calls',
        method: 'POST',
        data,
        timeout: this.createCallTimeout
      })
      response = await CallResponseSchema.validate(response)
      return response
    } catch (error) {
      if (
        (error?.response?.status === 400 || error?.response?.status === 409) &&
        error?.response?.data?.error === 'Duplicate Call'
      ) {
        this.store$.dispatch(
          openMessageDialog({
            payload: { type: MessageDialogTypes.CALL_DUPLICATE },
          })
        )
      }

      throw error
    }
  }

  async addPartialCall(data: AAACallData): Promise<void> {
    try {
      data = this.callAdapter.adapt(data)

      await this.xhr.clubRequest<void>({
        url: 'v2/roadservice/calls/partial',
        method: 'POST',
        data,
      },
        {retryAll: false})
    } catch (error) {
      throw error
    }
  }

  async getClubSupport(club) {
    try {
      const response = await this.xhr.clubRequest<ClubSupport>({
        url: `v2/roadservice/tracking/support/${club}`,
      })
      await ClubSupportSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async cancelCall(data: AAACallCancel): Promise<void> {
    try {
      const cancelCallData: AxiosRequestConfig = {
        url: 'v2/roadservice/calls',
        method: 'PUT',
        data,
      }

      if (this.rapService.isRapUser()) {
        cancelCallData.url = 'rap/calls'
        cancelCallData.method = 'DELETE'

        delete cancelCallData.data;
      }

      await this.xhr.clubRequest(cancelCallData)
    } catch (error) {
      throw error
    }
  }

  async getActiveCalls(
    timeout: number = this.getActiveCallsTimeout,
    addCallFailure: boolean = false
  ) {
    try {
      const response = await this.getOrRedirectToLocationWithinTimeout('v2/roadservice/calls/active', timeout, addCallFailure)
      return response
    } catch (error) {
      throw error
    }
  }

  async getOrRedirectToLocationWithinTimeout(url, timeout, addCallFailure = false) {
    let response
    let isTimeout
    const redirectTo = () => {
      if (timeout && timeout > 0 && !response && !addCallFailure) {
        if (this.eligible) {
          this.router.navigate(['steps'], {
            queryParams: { step: StepTypes.BREAKDOWN_LOCATION },
          })
        } else {
          this.router.navigate(['auth', 'signin'])
        }
      }
    }
    [isTimeout, response] = await Promise.all([
      setTimeout(() => {
        redirectTo()
      }, timeout),
      this.xhr.clubRequest<any>(
        {
          url
        },
        {
          retryAll: false,
        }
      ),
    ])
    return response
  }

  async getCallDetails(
    data: CallDetailParams,
    retry: boolean
  ) {
    try {
      const response = await this.xhr.clubRequest<any>({
        url: 'v2/roadservice/call/detail',
        method: 'POST',
        data
      }, {
        retryAll: retry
      })
      // TODO validate response schema
      // await CallDetails.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async getDriverInfo(params: DriverInfoParams) {
    try {
      const response = await this.xhr.clubRequest<DriverInfo>({
        url: 'v2/club/driver/info',
        params,
      })
      await DriverInfoSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  // start ARR:POC - The service that retrieves the service delivery data from an ARR POC endpoint.
  async getServiceDeliveryStatus(callId: string, callDate: string) {
    try {
      const response = await this.xhr.clubRequest<ServiceDeliveryCallStatus>({
        url: `roadservice/preferred-contact?callId=${callId}&callDate=${callDate}`,
        method: 'GET',
      })

      const validatedData = await CallStatusSchema.validate(response)

      return validatedData
    } catch (error) {
      throw error
    }
  }
  // end ARR:POC
}
