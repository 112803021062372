import { createFeatureSelector, createSelector } from '@ngrx/store'
import { selectLoading } from 'src/app/modules/ui/loading/loading.selectors'
import { REVIEWS_LOAD } from './review.actions'
import { ReviewState } from './review.reducer'
import { Review } from './review.types'

const stateKey = 'review'

export const selectReviewState = createFeatureSelector<ReviewState>(stateKey)

export const selectIsLoadingReviews = createSelector(
  selectLoading,
  (loading: object): boolean => Boolean(loading) && Boolean(loading[REVIEWS_LOAD.ACTION])
)

export const selectReviews = createSelector(
  selectReviewState,
  (state: ReviewState): Review[] => state.reviews
)
