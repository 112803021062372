import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AARData } from '../aar/aar.types'
import { AAAStore } from 'src/app/store/root-reducer'
import { select, Store } from '@ngrx/store'
import { cancelStepsAhead, openPromptDialog } from '../../ui/ui.actions';
import { PromptDialogTypes } from '../../ui/ui.types';
import { selectAarPreviewLocation, selectDestinationType } from '../aar/aar.selectors'
import { DestinationType } from '../../../shared/types'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { resetTowDestination } from '../tow-location/tow-location.actions'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import { setAarPreview } from '../aar/aar.actions'
import { selectIsCustomTowLocationPreview } from '../tow-location/tow-location.selectors'

@Component({
  selector: 'app-tow-location-selected',
  templateUrl: './tow-location-selected.component.html',
})
export class TowLocationSelectedComponent extends AbstractResponsiveComponent implements OnInit {
  @Input() tabIndex = 0
  locationId: number | null
  @Output() applyDiscountBanner: EventEmitter<void> = new EventEmitter()

  isAAR$: Observable<boolean> = this.store$.pipe(
    select(selectDestinationType),
    map(type => type === DestinationType.AAR)
  )

  details$: Observable<AARData> = this.store$.pipe(select(selectAarPreviewLocation))

  isCustomTowDestination$: Observable<boolean> = this.store$.pipe(select(selectIsCustomTowLocationPreview))
  isCustomTowDestination: boolean

  constructor(private store$: Store<AAAStore>) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.details$.subscribe((details) => {
        this.locationId = details?.id
      }),
      this.isCustomTowDestination$.subscribe((isCustom) => {
        this.isCustomTowDestination = isCustom
      })
    )
  }

  handleSummaryClick() {
    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.SHOP_DETAILS,
          params: { id: this.locationId }
        }
      })
    )
    this.store$.dispatch(setAarPreview({ payload: { id: this.locationId } }))
  }

  handleClearSelectedShop() {
    if (!this.isCustomTowDestination) {
      this.store$.dispatch(resetTowDestination())
      this.applyDiscountBanner.emit()
    }
    this.store$.dispatch(setAarPreview({ payload: { id: null } }))
    this.store$.dispatch(cancelStepsAhead())
  }

}
