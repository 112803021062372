<app-form-error-summary [controls]="controls">
    <app-form-error [control]="controls?.refNumber" name='refNumber' type='required'>
        Car Rental Agreement / Ref Number is required.
    </app-form-error>
</app-form-error-summary>
<div class="car-rental-credentials">
    <label for="refNumber">Car Rental Agreement / Ref Number</label>
    <input name="refNumber" id="refNumber" type="text" [ngrxFormControlState]="controls.refNumber"
        placeholder="Car Rental Agreement / Ref Number" [tabIndex]="2" />
    <app-form-error [control]="controls?.refNumber" name='refNumber' type='required'>
        Car Rental Agreement / Ref Number is required.
    </app-form-error>
</div>