import { Pipe, PipeTransform } from '@angular/core'
import { VehicleData } from 'src/app/modules/member/member.types'
import { TitleCasePipe } from '@angular/common'
import { isAWD } from '../../modules/vehicle/vehicle.utils'

const hiddenMembers = ['UNKNOWN']

export function formatVehicle(
  vehicle: VehicleData,
  ...additionalMembers: string[]
) {
  if (!vehicle) {
    return ''
  }

  const casePipe = new TitleCasePipe()
  const additional =
    additionalMembers &&
    additionalMembers
      .filter((key) => vehicle[key] && !hiddenMembers.includes(vehicle[key].toUpperCase()))
      .map((key) => `(${casePipe.transform(vehicle[key])}) `)
      .join('')


  const vehicleModel = vehicle.driveType
    ? `${vehicle.model} (${isAWD(vehicle) ? 'AWD/4WD' : vehicle.driveType})`
    : vehicle.model

  return `${vehicle.year} ${vehicle.make} ${vehicleModel} ${additional}`.trim()
}

@Pipe({ name: 'vehicle' })
export class VehiclePipe implements PipeTransform {
  transform(vehicle: VehicleData, ...args: any[]) {
    return formatVehicle(vehicle, ...args)
  }
}
