import {ABTestParams, Dialog, Step, StepStatus, StepTypes, VehicleSections,} from './ui.types'
import {
  ACTIVE_STEP,
  ADD_TOWING_STEP,
  ANIMATE_SPLASHSCREEN_HIDE,
  DIALOG_CLOSE,
  DIALOG_OPEN,
  DIALOG_RESET,
  DONE_STEP,
  EDIT_STEP,
  HIDE_SPLASHSCREEN,
  PENDING_STEP,
  REMOVE_VEHICLE_STEP,
  RESET_SKIP_ISSUE,
  SET_CHANNEL,
  SET_CLOSE_INSTALL_BANNER,
  SET_FIRST_TIME_ACCESS,
  SET_MAP_DEBUG,
  SET_MOCK_CAPTCHA,
  SET_PREPROD_ENV,
  SET_PROBLEM_TOOLTIP,
  SET_QUERY_PARAMS_VEHICLE_DATA,
  SET_REMOVE_CALL_AAA,
  SET_SHOW_MENU,
  SET_SPLASHSCREEN_STEP,
  SET_TELEMATICS,
  SET_USER_DEFAULT_COORDS,
  SET_USER_DEFAULT_ZIPCODE,
  SET_WATCH_RECAPTCHA_POSITION,
  SHOW_SPLASHSCREEN,
  TRANSITION,
  SET_IS_COUPON_APPLIED,
  SET_AB_TEST_PARAM,
  SET_FULL_MAP_BREAKDOWN_LOCATION,
} from './ui.actions'
import {IndexedCollection, PayloadedAction} from '../../shared/types'
import {CANCEL_EDITING_REQUEST} from 'src/app/shared/actions/shared.actions'
import {DEFAULT_LAT, DEFAULT_LNG} from '../location/location.constants'
import {GoogleAddress} from '../location/google-geocode/types'
import {Vehicle} from '../member/member.types'
import {PaceSetterSituation} from '../issue/issue.types'
import {
  automatedVehicleAnalytics,
  CREATE_NEW_VEHICLE,
  SET_VEHICLE_MAKE,
  SET_VEHICLE_MODEL_AND_TYPE,
  SET_VEHICLE_STEP,
  SET_VEHICLE_YEAR
} from '../vehicle/vehicle.actions'
import events from '../tagging/events'
import {RESET_BREAKDOWN_LOCATION, SET_BREAKDOWN_LOCATION} from '../location/location.actions'
import {SET_PACE_SETTER_OPTIONS, SET_PACE_SETTER_SITUATION} from '../issue/issue.actions'
import {ASSIGN_EXISTING_VEHICLE} from '../member/member.actions'
import {ADDRESS_LOOKUP, SET_IS_FIRST_CLICK_SMM, OPEN_MAP_MODAL, RESET_TOW_DESTINATION, SET_AAR_ADDRESS, SET_ENTER_AN_ADDRESS} from '../location/tow-location/tow-location.actions'

export type ABTestsState = {
  [type in ABTestParams]: boolean
}

export interface SplashscreenState {
  visible: boolean
  step: 0 | 1 | 2 | 3
  animating: boolean
}
export interface SaveMembersMoneyDialog {
  isFirstClick: boolean
  isMapModalOpen: boolean
  isEnterAnAddress: boolean
}

export interface UIState {
  steps: IndexedCollection<Step>
  dialog: {
    [type: string]: Dialog
  }
  vehicleDetailsRedirect: boolean
  initialLatLng: GoogleAddress
  splashscreen: SplashscreenState
  isCouponApplied: boolean
  saveMembersMoneyDialog: SaveMembersMoneyDialog
  showMenu: boolean
  transitioning: boolean
  defaultUserCoords?: GoogleAddress
  skipIssue?: boolean
  preProdEnv: boolean
  defaultZipCode?: string
  mapDebug: boolean
  mockCaptcha: boolean
  telematics: string[]
  queryParamsVehicle: Vehicle
  isFirstTimeAccess: boolean,
  channel: string,
  isInstallBannerClosed: boolean,
  problemTooltip?: PaceSetterSituation,
  watchRecaptchaPosition?: boolean,
  removeCallAaa: boolean,
  showFullMapBreakdownLocation: boolean,
  abTestsState: ABTestsState
}

export const initialAbTestsState = Object.values(ABTestParams).reduce((curr, key) => {
  curr[key] = false;
  return curr;
}, {} as ABTestsState)

export const initialSteps = {
  1: {
    id: 1,
    url: StepTypes.BREAKDOWN_LOCATION,
    status: StepStatus.EDITING,
    title: 'Location',
    pageType: events.location.LOCATION_PAGE_TYPE,
    progress: [false, false]
  },
  2: {
    id: 2,
    url: StepTypes.ISSUE,
    status: StepStatus.PENDING,
    title: 'Issue',
    afterEach: [
      automatedVehicleAnalytics(),
    ],
    pageType: events.issue.ISSUE_PAGE_TYPE,
    progress: [false, false]
  },
  3: {
    id: 3,
    url: StepTypes.VEHICLE,
    status: StepStatus.PENDING,
    title: 'Vehicle',
    sections: [
      VehicleSections.MAKES,
      VehicleSections.MODELS,
      VehicleSections.DETAILS,
    ],
    pageType: events.vehicle.VEHICLE_PAGE_TYPE,
    progress: [false, false, false, false, false]
  },
  5: {
    id: 5,
    url: StepTypes.SUBMIT,
    status: StepStatus.PENDING,
    title: 'Submit',
    pageType: events.submit.SUMMARY_PAGE_TYPE,
    progress: [false, false, false]
  },
}

export const towingStep = {
  id: 4,
  url: StepTypes.TOWING,
  status: StepStatus.PENDING,
  title: 'Tow To',
  pageType: events.towTo.DESTINATION_PAGE_TYPE,
  progress: [false, false, false],
}

export const initialState: UIState = {
  steps: initialSteps,
  dialog: {},
  vehicleDetailsRedirect: false,
  initialLatLng: {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    address: {},
  },
  splashscreen: {
    visible: false,
    step: 1,
    animating: false,
  },
  isCouponApplied: false,
  saveMembersMoneyDialog: {
    isFirstClick: true,
    isMapModalOpen: false,
    isEnterAnAddress: false
  },
  showMenu: true,
  transitioning: false,
  skipIssue: false,
  preProdEnv: false,
  defaultZipCode: null,
  mapDebug: false,
  defaultUserCoords: null,
  mockCaptcha: false,
  telematics: [],
  queryParamsVehicle: null,
  isFirstTimeAccess: false,
  channel: '',
  isInstallBannerClosed: false,
  problemTooltip: null,
  watchRecaptchaPosition: false,
  removeCallAaa: false,
  showFullMapBreakdownLocation: true,
  abTestsState: initialAbTestsState
}

const stepChangeMap = {
  [PENDING_STEP]: StepStatus.PENDING,
  [EDIT_STEP]: StepStatus.EDITING,
  [ACTIVE_STEP]: StepStatus.ACTIVE,
  [DONE_STEP]: StepStatus.DONE,
}

function getProgressForStepState(state: UIState, action: PayloadedAction<any>) {
  return state.steps[action.payload.id]?.progress?.map(
    (progress, index) => {
      if (action.type === DONE_STEP) {
        // if step is done set all sub-steps to true
        return action.payload.url === StepTypes.SUBMIT && action.payload.status === StepStatus.EDITING ? progress : true
      } else if (
        action.type === EDIT_STEP &&
        action.payload.url !== StepTypes.TOWING &&
        action.payload.url !== StepTypes.VEHICLE
      ) {
        // reset progress on edit except for tow and vehicle
        return false
      } else {
        return progress
      }
    }
  )
}

function getProgressForSetVehicleStep(state: UIState, action: PayloadedAction<any>) {
  if (action.type === SET_VEHICLE_STEP) {
    if (action.payload.step === VehicleSections.MAKES) {
      return [true, true, false, false, false]
    } else if (action.payload.step === VehicleSections.MODELS) {
      return [true, true, true, false, false]
    } else if (action.payload.step === VehicleSections.DETAILS) {
      return [true, true, true, true, false]
    } else if (action.payload.step === '') {
      return [false, false, false, false, false]
    }
  }
}

export function reducer(
  state: UIState = initialState,
  action: PayloadedAction
) {
  switch (action.type) {
    case PENDING_STEP:
    case EDIT_STEP:
    case ACTIVE_STEP:
    case DONE_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.id]: {
            ...action.payload,
            status: stepChangeMap[action.type],
            progress: getProgressForStepState(state, action)
          },
        },
      }
    case DIALOG_OPEN.MESSAGE:
    case DIALOG_OPEN.ERROR:
    case DIALOG_OPEN.PROMPT:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          [action.payload.type]: {
            ...action.payload,
            displayedAt: new Date(),
          },
        },
      }
    case DIALOG_CLOSE: {
      delete state.dialog[action.payload.type]
      return {
        ...state,
        dialog: {
          ...state.dialog,
        },
      }
    }
    case DIALOG_RESET: {
      return {
        ...state,
        dialog: {
          ...initialState.dialog,
        },
      }
    }
    case CANCEL_EDITING_REQUEST:
      return {
        ...state,
        steps: initialState.steps,
        isCouponApplied: initialState.isCouponApplied,
      }
    case SET_CLOSE_INSTALL_BANNER: {
      return {
        ...state,
        isInstallBannerClosed: action.payload
      }
    }
    case SHOW_SPLASHSCREEN:
    case HIDE_SPLASHSCREEN:
      if (state.splashscreen.step === 0) {
        return state
      }

      return {
        ...state,
        splashscreen: {
          visible: action.type === SHOW_SPLASHSCREEN,
          step: action.type === SHOW_SPLASHSCREEN ? 1 : state.splashscreen.step,
          animating: false,
        },
      }
    case ANIMATE_SPLASHSCREEN_HIDE:
      if (state.splashscreen.step === 0) {
        return state
      }

      return {
        ...state,
        splashscreen: {
          ...state.splashscreen,
          animating: true,
        },
      }

    case SET_IS_COUPON_APPLIED:
      return {
        ...state,
        isCouponApplied: action.payload
      }

    case SET_SPLASHSCREEN_STEP:
      if (state.splashscreen.step === 0) {
        return state
      }

      return {
        ...state,
        splashscreen: {
          ...state.splashscreen,
          step: action.payload,
        },
      }
    case TRANSITION.REQUEST:
    case TRANSITION.SUCCESS:
      return {
        ...state,
        transitioning: action.payload,
      }
    case SET_SHOW_MENU:
      return {
        ...state,
        showMenu: action.payload,
      }
    case SET_USER_DEFAULT_COORDS:
      return {
        ...state,
        defaultUserCoords: { ...action.payload, address: {} },
      }
    case RESET_SKIP_ISSUE:
      return {
        ...state,
        skipIssue: false,
      }
    case SET_MAP_DEBUG:
      return {
        ...state,
        mapDebug: action.payload,
      }
    case OPEN_MAP_MODAL:
      return  {
        ...state,
        saveMembersMoneyDialog: {
          ...state.saveMembersMoneyDialog,
          isMapModalOpen: action.payload
        } as SaveMembersMoneyDialog
      }
    case SET_IS_FIRST_CLICK_SMM:
      return  {
        ...state,
        saveMembersMoneyDialog: {
          ...state.saveMembersMoneyDialog,
          isFirstClick: action.payload
        } as SaveMembersMoneyDialog
      }
      case SET_ENTER_AN_ADDRESS:
        return  {
          ...state,
          saveMembersMoneyDialog: {
            ...state.saveMembersMoneyDialog,
            isEnterAnAddress: action.payload
          } as SaveMembersMoneyDialog
        }
    case ADD_TOWING_STEP:
      {
        // TODO refactor required. Move logic to an effect.

        // if towing is required reset steps and add the addtional step
        // if not towing step will be removed if is already set
        if (action.payload === true) {
          state = {
            ...state,
            steps: {
              ...state.steps,
              4: {
                ...towingStep,
                ...state.steps[4]
              },
              5: {
                ...initialSteps[5],
                progress: [false, false, false]
              },
            },
          }
        } else if (state.steps[4]) {
          delete state.steps[4]

          return {
            ...state,
            steps: {
              ...state.steps,
              5: {
                ...state.steps[5],
                progress: [false, false, false]
              }
            },
          }
        }
      }
      return state

    case REMOVE_VEHICLE_STEP:
      const vehicleStep = Object.values(state.steps).find((step) => step.url === StepTypes.VEHICLE)
      if (vehicleStep) {
        delete state.steps[vehicleStep.id]
        return {
          ...state,
          steps: {
            ...state.steps,
          },
        }
      }
      return state

    case SET_PREPROD_ENV:
      return {
        ...state,
        preProdEnv: true,
      }
    case SET_USER_DEFAULT_ZIPCODE:
      return {
        ...state,
        defaultZipCode: action.payload,
      }
    case SET_MOCK_CAPTCHA:
      return {
        ...state,
        mockCaptcha: action.payload,
      }
    case SET_TELEMATICS:
      return {
        ...state,
        telematics: action.payload ? action.payload.split(',') : [],
      }
    case SET_QUERY_PARAMS_VEHICLE_DATA:
      return {
        ...state,
        queryParamsVehicle: action.payload,
      }
    case SET_FIRST_TIME_ACCESS:
      return {
        ...state,
        isFirstTimeAccess: action.payload,
      }
    case SET_CHANNEL:
      return {
        ...state,
        channel: action.payload,
      }
    case SET_PROBLEM_TOOLTIP:
      return {
        ...state,
        problemTooltip: action.payload,
      }
    case SET_REMOVE_CALL_AAA:
      return {
        ...state,
        removeCallAaa: action.payload,
      }
    case SET_WATCH_RECAPTCHA_POSITION:
      return {
        ...state,
        watchRecaptchaPosition: action.payload
      }
    case SET_FULL_MAP_BREAKDOWN_LOCATION:
      return {
        ...state,
        showFullMapBreakdownLocation: action.payload
      }
    case SET_AB_TEST_PARAM:
      const [payloadKey, payloadValue] = action.payload
      return {
        ...state,
        abTestsState: {
          ...state.abTestsState,
          [payloadKey]: payloadValue ?? true
        }
      }
    case SET_BREAKDOWN_LOCATION.SUCCESS:
        return {
          ...state,
          steps: {
            ...state.steps,
            ['1']: {
              ...state.steps['1'],
              progress: [true, false]
            }
          },
        }
    case RESET_BREAKDOWN_LOCATION:
        return {
          ...state,
          steps: {
            ...state.steps,
            ['1']: {
              ...state.steps['1'],
              progress: [false, false]
            }
          },
        }
    case SET_PACE_SETTER_SITUATION:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['2']: {
            ...state.steps['2'],
            progress: [true, false]
          }
        },
      }
    case SET_PACE_SETTER_OPTIONS:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['2']: {
            ...state.steps['2'],
            progress: [true, true, false]
          }
        },
      }
    case ASSIGN_EXISTING_VEHICLE:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['3']: {
            ...state.steps['3'],
            progress: [true, true, true, true, false]
          }
        },
      }
    case CREATE_NEW_VEHICLE:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['3']: {
            ...state.steps['3'],
            progress: [true, false, false, false, false]
          }
        },
      }
    case SET_VEHICLE_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['3']: {
            ...state.steps['3'],
            progress: getProgressForSetVehicleStep(state, action)
          }
        },
      }
    case SET_VEHICLE_YEAR:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['3']: {
            ...state.steps['3'],
            progress: [true, true, false, false, false]
          }
        },
      }
    case SET_VEHICLE_MAKE:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['3']: {
            ...state.steps['3'],
            progress: [true, true, true, false, false]
          }
        },
      }
    case SET_VEHICLE_MODEL_AND_TYPE:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['3']: {
            ...state.steps['3'],
            progress: [true, true, true, true, false]
          }
        },
      }
    case RESET_TOW_DESTINATION:
      if (state.steps['4']) {
        state.steps['4'].progress = [false, false, false]
      }
      return state
    case SET_AAR_ADDRESS:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['4']: {
            ...state.steps['4'],
            progress: [true, false, false]
          }
        },
      }
    case ADDRESS_LOOKUP.SUCCESS:
      return {
        ...state,
        steps: {
          ...state.steps,
          ['4']: {
            ...state.steps['4'],
            progress: [true, false, false]
          }
        },
      }
    case 'ngrx/forms/SET_VALUE':
      if ((action as any).controlId === 'form.passengers.quantity') {
        state = {
          ...state,
          steps: {
            ...state.steps,
            ['4']: {
              ...state.steps['4'],
              progress: [true, true, false]
            }
          },
        }
      } else if((action as any).controlId === 'form.contact.contactNumber') {
        state = {
          ...state,
          steps: {
            ...state.steps,
            ['5']: {
              ...state.steps['5'],
              progress: state.steps['5'].progress
                .map((progress, index) => index === 0 ? (action as any).value.length === 10 : progress)
            }
          },
        }
      } else if ((action as any).controlId === 'form.contact.displayId') {
        state = {
          ...state,
          steps: {
            ...state.steps,
            ['5']: {
              ...state.steps['5'],
              progress: state.steps['5'].progress
                .map((progress, index) => index === 1 ? Boolean((action as any).value) : progress)
            }
          },
        }
      }
      return state
    default:
      return state
  }
}
