<div class="situation-summary">
  <!-- Location -->
  <ng-container>
    <div
      class="situation-piece"
      [ngClass]="{ disabled: !(selectBreakdownLocationAddress$ | async) }"
    >
      <div class="issue-icon">
        <app-img
          src="assets/images/icons/location-pin.svg"
          classNames="icon"
          alt="breakdown location"
        ></app-img>
      </div>
      <div class="piece-title small-text bold uppercase">
        Location:
      </div>
      <div class="piece-heading location-label">
        <p class="small-text" *ngIf="selectDisplayLandmark$ | async">
          {{ selectBreakdownLocationLandmark$ | async }}
        </p>
        <p class="small-text">
          {{
            (selectBreakdownLocationAddress$ | async) ||
              'Where is your vehicle?'
          }}
        </p>
        <p class="small-text exit-info" *ngIf="highWayExit$ | async">
          {{ highWayExit$ | async }}
        </p>
      </div>

      <ng-container
        *ngIf="
          !(currentEditing$ | async) &&
          (selectBreakdownLocationAddress$ | async)
        "
      >
        <div class="piece-edit">
          <aria-link-button
          [tabIndex]="902"
          ariaLabel="Edit breakdown location"
          (clickLink)="editService$.doEdit('location')">
            <icon-edit-outlined></icon-edit-outlined>
          </aria-link-button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Issue -->
  <ng-container>
    <div
      class="situation-piece issue-block"
      [ngClass]="{ disabled: !(isLocationClubRequested$ | async) }"
    >
      <div class="issue-icon" *ngIf="(activeSituationDetails$ | async)?.icon">
        <app-img
          [src]="(activeSituationDetails$ | async)?.icon"
          classNames="icon"
          [alt]="(activeSituationDetails$ | async)?.name || 'What is the issue?'"
        ></app-img>
      </div>
      <div class="piece-title small-text bold uppercase">
        Issue:
      </div>
      <div class="piece-heading small-text issue-label">
        {{ (activeSituationDetails$ | async)?.name || 'What is the issue?' }}
      </div>

      <ng-container
        *ngIf="!(currentEditing$ | async) && (isLocationClubRequested$ | async)"
      >
        <div class="piece-edit edit-issue-button">
          <aria-link-button
            [tabIndex]="901"
            ariaLabel="Change Issue"
            (clickLink)="editService$.doEdit('issue', '')">
            <icon-edit-outlined></icon-edit-outlined>
          </aria-link-button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Vehicle -->
  <ng-container>
    <div
      class="situation-piece"
      [ngClass]="{
        disabled: !(workingVehicle$ | async).year && !(activeVehicle$ | async)
      }"
    >
      <div class="issue-icon">
        <app-img
          src="assets/images/icons/car-small.svg"
          classNames="icon"
          alt="Vehicle info"
        ></app-img>
      </div>
      <div class="piece-title small-text bold uppercase">
        Vehicle:
      </div>
      <div class="flex-to-col" *ngIf="!(activeVehicle$ | async)">
        <div
          *ngIf="(workingVehicle$ | async).year"
          class="rounded-icon"
          (click)="
            editService$.editVehicleSection(true, _workingVehicle, 'years')
          "
        >
          {{ (workingVehicle$ | async).year }}

          <aria-link-button
            [tabIndex]="903"
            ariaLabel="Edit vehicle year"
            (clickLink)=" editService$.editVehicleSection(true, _workingVehicle, 'years')">
              <app-img
                src="assets/images/icons/edit small white.svg"
                classNames="edit-icon"
                alt="Edit vehicle year">
              </app-img>
          </aria-link-button>
        </div>
        <div
          *ngIf="(workingVehicle$ | async).make"
          class="rounded-icon"
          (click)="
            editService$.editVehicleSection(true, _workingVehicle, 'makes')
          "
        >
          {{ (workingVehicle$ | async).make }}

          <aria-link-button
            [tabIndex]="904"
            ariaLabel="Edit vehicle make"
            (clickLink)="editService$.editVehicleSection(true, _workingVehicle, 'makes')">
              <app-img
                src="assets/images/icons/edit small white.svg"
                classNames="edit-icon"
                alt="Edit vehicle make">
              </app-img>
          </aria-link-button>

        </div>
        <div
          *ngIf="(workingVehicle$ | async).model"
          class="rounded-icon"
          (click)="
            editService$.editVehicleSection(true, _workingVehicle, 'models')
          "
        >
          {{ (vehicleModelDescription$ | async) }}

          <aria-link-button
            [tabIndex]="905"
            ariaLabel="Edit vehicle model"
            (clickLink)="editService$.editVehicleSection(true, _workingVehicle, 'models')">
              <app-img
                src="assets/images/icons/edit small white.svg"
                classNames="edit-icon"
                alt="Edit vehicle model">
              </app-img>
          </aria-link-button>

        </div>
      </div>

      <div
        class="piece-heading small-text vehicle-label"
        *ngIf="!(workingVehicle$ | async).year || (activeVehicle$ | async)"
      >
        {{ (activeVehicle$ | async) || 'Which vehicle?' }}
      </div>

      <ng-container
        *ngIf="!(currentEditing$ | async) && (activeVehicle$ | async) && (isVehicleChangeAllowed$ | async)"
      >
        <div class="piece-edit edit-vehicle-button">
          <aria-link-button
            (clickLink)="editService$.doEdit('vehicle')"
            ariaLabel="Edit vehicle"
            [tabIndex]="906">
            <icon-edit-outlined></icon-edit-outlined>
          </aria-link-button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Tow to -->
  <ng-container *ngIf="needsTow$ | async">
    <div
      class="situation-piece"
      [ngClass]="{ disabled: !(towLocation$ | async)?.address }"
    >
      <div class="issue-icon">
        <app-img
          src="assets/images/icons/tow-small.svg"
          classNames="icon"
          alt="Towing location"
        ></app-img>
      </div>
      <div class="piece-title small-text bold uppercase">
        Tow to:
      </div>
      <div class="piece-heading small-text tow-to-label">
        {{ (towLocation$ | async)?.address || 'Location' }}
      </div>
      <app-img
        *ngIf="(towLocation$ | async).isAar"
        classNames="aaa-aproved"
        src="assets/images/AAA Approved.svg"
        alt="AAA Approved"
      ></app-img>

      <ng-container
        *ngIf="!(currentEditing$ | async) && (towLocation$ | async)?.address"
      >
        <div class="piece-edit">
          <aria-link-button
            (clickLink)="editService$.doEdit('tow-location')"
            ariaLabel="Edit tow location"
            [tabIndex]="907">
            <icon-edit-outlined></icon-edit-outlined>
          </aria-link-button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Special Assistance -->
  <ng-container>
      <div class="situation-piece"
      [ngClass]="{ disabled: !specialAssistanceDetails }">
        <div class="issue-icon">
          <app-img
            src="assets/images/icons/special-assistance.svg"
            classNames="icon"
            alt="Special Assistance"
          ></app-img>
        </div>
        <div class="piece-title small-text bold uppercase">
          Special <br>Assistance:
        </div>
        <div class="piece-heading small-text special_assistance-value">
          {{ specialAssistanceDetails || '—' }}
        </div>

          <div class="piece-edit">
            <aria-link-button [tabIndex]="908"
              ariaLabel="Edit Special Assistance"
              (clickLink)="handleSpecialAssistanceEdit()">
              <icon-edit-outlined></icon-edit-outlined>
            </aria-link-button>
          </div>
      </div>
  </ng-container>
</div>
