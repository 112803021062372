import { createAction, props } from '@ngrx/store'
import { createAsyncConstants } from 'src/app/shared/utils'
import { ReviewPagination } from './review.types'

export const REVIEWS_LOAD = createAsyncConstants('REVIEWS_LOAD')

export const requestReviews = createAction(
  REVIEWS_LOAD.REQUEST,
  props<{ payload: string }>()
)

export const completeReviews = createAction(
  REVIEWS_LOAD.SUCCESS,
  props<{ payload: ReviewPagination }>()
)
