import { IndexedCollection, PayloadedAction } from 'src/app/shared/types'
import { CLUB_SUPPORT } from './clubs.actions'

export interface ClubState {
  clubs: IndexedCollection<Club>
}

export interface Club {
  name: string
  hasTrackingSupport?: boolean
}

export function generateInitialState() {
  return {
    clubs: {},
  }
}

export function reducer(
  state: ClubState = generateInitialState(),
  action: PayloadedAction
) {
  switch (action.type) {
    // TODO move business logic to effects
    case CLUB_SUPPORT.SUCCESS:
      const result = { ...state }
      const name = action.payload.name

      const club: Club = result.clubs[name] || { name }
      club.hasTrackingSupport = action.payload.hasTrackingSupport

      result.clubs[name] = club

      return result
    default:
      return state
  }
}
