import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../ui.types'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss'],
})
export class IframeDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  close() {
    this.data.close()
  }

  showAbTestSwitcher() {
    return environment.clientId === 'drr-web.qa' && this.data?.params?.ariaLabel === 'Terms and conditions content'
  }
}
