<div class="dialog">
  <h1 mat-dialog-title>Alert</h1>

  <div mat-dialog-content>
    <p class="font-size-medium">
      If you have been in an accident please follow state laws
      <br class="show-desktop">
      and guidelines for requesting emergency assistance.
    </p>
  </div>
  <div mat-dialog-actions>
    <app-link-button class="show-mobile" href="tel: 911" (clickLink)="onClose()">CALL 9-1-1</app-link-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
