import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import events from 'src/app/modules/tagging/events'

@Component({
  selector: 'app-call-status-verification-component',
  templateUrl: './call-status-verification.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class CallStatusErrorDialogComponent {
  pageType = events.dashboard.CALL_STATUS_ERROR_PAGE_TYPE

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private taggingService: TaggingService,
  ) {
    this.taggingService.setPageEvent(
      events.dashboard.CALL_STATUS_ERROR_PROMPT,
      events.dashboard.CALL_STATUS_ERROR_PAGE_TYPE
    )
  }

  onClose() {
    this.data.close()
  }

  onCallAAAClick() {
    this.onClose()
  }
}
