import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectMobileNumberAuth} from '../../ui/ui.selectors';
import { zipCodeMask } from '../minimal-credentials/minimal-credentails.constants';

@Component({
  selector: 'app-member-name',
  templateUrl: './member-name.component.html',
  styleUrls: ['../minimal-credentials/minimal-credentials.component.scss'],
})
export class MemberNameComponent {
  @Input() controls
  @Output() useMembershipNumberEmitter: EventEmitter<any> = new EventEmitter<any>()

  zipCodeMask = zipCodeMask

  isMobileNumberAuth$: Observable<boolean> = this.store$.pipe(
    select(selectMobileNumberAuth)
  )

  constructor(private store$: Store) {}

  setUseMembershipNumber(event) {
    this.useMembershipNumberEmitter.emit(event)
  }
}
