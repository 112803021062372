<div class="dialog dialog-error cancel-feedback-option">
  <h1 mat-dialog-title>
    {{data.title}}
  </h1>

  <div id="dialog-content" mat-dialog-content>
    <app-safehtml [content]="data.content" *ngIf="data.content"></app-safehtml>
  </div>

  <ng-container *ngIf="data?.isCallAaaButtonShown">
    <div class="cancel-feedback-option_secondary-content">
      <app-call-aaa-button [clickEventPage]="pageType"
        (buttonClick)="onCallAAAClick()"
        [ariaLabel]="'Contact us'">
      </app-call-aaa-button>
    </div>
  </ng-container>
  <div class="cancel-feedback-option_secondary-content">
    <app-button
      inverted="true"
      type="button"
      (click)="data.close()"
      aria-label="close"
    >
      Close
    </app-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
