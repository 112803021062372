import { Injectable } from '@angular/core'

import { ModelType, ModelTypesResponse, VehicleMakes, VehicleModels, VehicleYears } from './vehicle.types'
import { XHRService } from '../../shared/services/xhr.service'
import {
  VehicleMakesSchema,
  VehicleModelsSchema,
  VehicleYearsSchema,
} from './vehicle.model'
import { generateVehicleSlug } from 'src/app/shared/utils'
import { IndexedCollection } from 'src/app/shared/types'
import { VehicleUtils } from './vehicle.utils'
import { RequestOptions } from 'src/app/shared/services/xhr.utils'

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  devOptions: RequestOptions = {}
  constructor(
    private xhr: XHRService,
    private vehicleUtils: VehicleUtils,
    ) {}

  async getYears() {
    const response = await this.xhr.request<VehicleYears>(
      'vehicles/years',
      this.devOptions
    )
    await VehicleYearsSchema.validate(response)
    return response
  }

  async getMakes(year: number) {
    const response = await this.xhr.request<VehicleMakes>(
      `vehicles/makes/${year}`,
      this.devOptions
    )
    await VehicleMakesSchema.validate(response)
    return response
  }

  async getModels(year: number, make: string) {
    if (make.includes('Motorhome/')) {
      make = this.vehicleUtils.normalizeMotorHome(make) as string
    }
    const encodedMake = this.vehicleUtils.uriEncode(make)
    const response = await this.xhr.request<VehicleModels>(
      `vehicles/models/${year}/${encodedMake}`,
      this.devOptions
    )
    await VehicleModelsSchema.validate(response)
    return response
  }

  async getModelTypes({ year, make, model }): Promise<IndexedCollection<ModelType[]>> {
    if (make.includes('Motorhome/') || model.includes('Motorhome/')) {
      [make, model] = this.vehicleUtils.normalizeMotorHome([make, model])
    }
    const [encodedMake, encodedModel] = this.vehicleUtils.uriEncode([make, model])

    const response = await this.xhr.request<ModelTypesResponse>(
      `vehicles/types/${year}/${encodedMake}/${encodedModel}`,
      this.devOptions
    )
    // await VehicleModelTypesSchema.validate(response)
    const slug = generateVehicleSlug({ year, make, model })
    return {
      [slug]: response.types
    }
  }
}
