<div class="tab-view">

  <ng-container *ngFor="let tabItem of tabItems">
    <div class="tab-view__button"
      [ngClass]="{ 'active': tabItem.label === (activeTab$ | async) }"
      (click)="changeActiveTab(tabItem.label)">

      <ng-container [ngSwitch]="tabItem.icon">
        <icon-car-tilt *ngSwitchCase="'icon-car-tilt'"
          [color]="tabItem.label === (activeTab$ | async) ? tabItem.color : iconColor.default"></icon-car-tilt>
        <icon-home-solid *ngSwitchCase="'icon-home-solid'"
          [color]="tabItem.label === (activeTab$ | async) ? tabItem.color : iconColor.default"></icon-home-solid>
      </ng-container>

      <span>{{ tabItem.label }}</span>
    </div>
  </ng-container>

</div>
