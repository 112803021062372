import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-custom-message-dialog-component',
  templateUrl: './custom-message-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class CustomMessageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onClose() {
    this.data.close()
  }
}
