import { IndexedCollection, PayloadedAction } from '../../shared/types'
import {
  PaceSetterNotesContainer,
  PaceSetterOptionsIdentifier,
  SET_CLEAR_ISSUE,
  SET_PACE_SETTER_NOTES,
  SET_PACE_SETTER_OPTIONS,
  SET_PACE_SETTER_OPTIONS_ADDITIONAL_REQUIREMENT,
  SET_PACE_SETTER_SITUATION,
  SET_PACE_SETTER_STEP,
} from './issue.actions'
import { CANCEL_EDITING_REQUEST } from 'src/app/shared/actions/shared.actions'

import {
  PaceSetterCode,
  PaceSetterDetails,
  PACE_SETTER_SITUATION_TYPES,
  PaceSetterGroup,
  PaceSetterSituation,
} from './issue.types'
import { defaultOptions, PACE_SETTER_OPTION_TEMPLATES } from './issue.utils'

export interface PaceSetterState {
  activeSituation: PaceSetterSituation
  activeCode: PaceSetterCode | null
  details: IndexedCollection<PaceSetterDetails>
  stepIndex: number
  selectedTires?: any
  damagedParts?: any
}

export const initialState: PaceSetterState = {
  activeSituation: null,
  activeCode: null,
  details: {},
  selectedTires: null,
  stepIndex: 0,
}

export function reducer(
  state: PaceSetterState = initialState,
  action: PayloadedAction &
    PaceSetterOptionsIdentifier & { index?: number } & PaceSetterNotesContainer
) {
  switch (action.type) {
    case SET_PACE_SETTER_SITUATION: {
      const details = { ...state.details }

      // TODO move to selector/effect
      if (action.payload && !details[action.payload.name]) {
        const options =
          PACE_SETTER_OPTION_TEMPLATES[action.payload.name.toUpperCase()] ||
          defaultOptions
        const detailName = options ? options.name : null

        // Initialize each list in each group with an empty array
        const selectedGroups: PaceSetterGroup[] = options.detailGroups.map(
          (group) => ({
            lists: (
              (group && group.lists) ||
              (group && group.options) ||
              []
            ).map(() => ({ members: [], additional: [] })),
          })
        )

        details[action.payload.name] = {
          name: detailName,
          defaultPaceSetterCode: options.defaultCode,
          selectedGroups,
        }
      }

      const activeCode =
        details[action.payload.name].defaultPaceSetterCode || state.activeCode

      return {
        ...state,
        activeSituation: action.payload || null,
        activeCode,
        details,
        selectedTires:
          action.payload.name.toUpperCase() ===
          PACE_SETTER_SITUATION_TYPES.FLAT_TIRE
            ? state.selectedTires
            : null,
        stepIndex: 0,
      }
    }
    case SET_PACE_SETTER_OPTIONS: {
      const { groupIndex, listIndex, selection } = action
      const details = { ...state.details }
      let activeCode = state.activeCode
      if (state.activeSituation) {
        // TODO move to selector/effect
        const detailKey = state.activeSituation.name

        if (details[detailKey]) {
          details[detailKey].selectedGroups[groupIndex].lists[
            listIndex
          ].members = selection
          activeCode =
            (selection.length === 1 && selection[0]) ||
            details[detailKey].defaultPaceSetterCode ||
            state.activeCode
        }
      }

      return {
        ...state,
        activeCode,
        details,
      }
    }
    case SET_PACE_SETTER_OPTIONS_ADDITIONAL_REQUIREMENT: {
      const { groupIndex, listIndex, selection } = action
      const details = { ...state.details }

      if (state.activeSituation) {
        const detailKey = state.activeSituation.name

        if (details[detailKey]) {
          details[detailKey].selectedGroups[groupIndex].lists[
            listIndex
          ].additional = selection
        }
      }

      return {
        ...state,
        details
      }
    }

    case SET_PACE_SETTER_NOTES: {
      const details = { ...state.details }
      // TODO move selector/effect
      if (state.activeSituation) {
        const detailKey = state.activeSituation.name
        details[detailKey] = {
          ...details[detailKey],
          notes: action.notes,
        }
      }

      return {
        ...state,
        details,
      }
    }
    case SET_PACE_SETTER_STEP:
      return {
        ...state,
        stepIndex: +action.index,
      }
    case CANCEL_EDITING_REQUEST: {
      return {
        ...initialState,
      }
    }
    case SET_CLEAR_ISSUE: {
      return {
        ...state,
        activeSituation: null,
        activeCode: null,
      }
    }
    default:
      return state
  }
}
