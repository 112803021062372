<div class="white-label-credentials entry">
    <form
        id="signon-form"
        (ngSubmit)="checkFieldsAndSubmit()"
        class="white-label-credentials_form"
        [ngrxFormState]="formState"
    >

        <ng-template formMode></ng-template>

        <ngx-recaptcha2
          #captchaElement
          [siteKey]="siteKey"
          (ready)="handleCaptchaReady()"
          (success)="handleCaptchaSuccess($event)"
          (error)="handleCaptchaError()"
          (expire)="handleExpire()"
          [useGlobalDomain]="false"
          type="checkbox"
          [ngModel]="captcha"
          [ngModelOptions]="{ standalone: true }">
        </ngx-recaptcha2>

        <div class="form-error text-error small-text" *ngIf="captchaError">
            You must confirm you are not a robot.
        </div>

        <app-button [disabled]="(isSigningIn$ | async)" type="submit" [loading]="(isSigningIn$ | async) === true"
            [tabIndex]="(12 + tabIndexPosition)">
            Confirm
        </app-button>


    </form>

    <div class="white-label-credentials_terms">
        By selecting Confirm, you accept the
        <aria-link-button
          (clickLink)="openTerms()"
          [tabIndex]="(24 + tabIndexPosition)"
          class="terms-and-conditions__link"
        >
          Terms and Conditions
        </aria-link-button>
    </div>

    <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
        <div class="privacy-policy">
            <app-clickable-link (clickLink)="openPrivacyPolicy()" class="privacy-policy__link"
                [tabIndex]="(25 + tabIndexPosition)">
                Privacy Policy
            </app-clickable-link>
        </div>
    </ng-container>
</div>
