<div class="dialog">
  <h1 mat-dialog-title>Alert</h1>

  <div class="dialog-content">
    <p class="text-message">The destination address is incomplete. Please select a destination containing at least a street name.</p>
    <app-link-button (clickLink)="onClose()" 
      [ariaLabel]="'dismiss this message'">
      Dismiss
    </app-link-button>
  </div>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
