import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import events from 'src/app/modules/tagging/events'

import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-cant-remember-id-dialog-component',
  templateUrl: './cant-remember-id-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class CantRememberIdDialogComponent {
  public pageType = events.shared.LOST_MEMBERSHIP_PAGE_TYPE

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onClose() {
    this.data.close()
  }
}
