import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsRapUser, selectModeConfiguration } from 'src/app/modules/auth/auth.selectors';
import { VendorConfigurationMode } from 'src/app/modules/auth/mode-configuration.types';
import { selectActivePaceSetterStep } from 'src/app/modules/issue/issue.selectors';
import { PaceSetterSituation } from 'src/app/modules/issue/issue.types';
import events from 'src/app/modules/tagging/events';
import { TaggingService } from 'src/app/modules/tagging/tagging.service';
import { openPromptDialog } from 'src/app/modules/ui/ui.actions';
import { selectProblemTooltip } from 'src/app/modules/ui/ui.selectors';
import { PromptDialogTypes } from 'src/app/modules/ui/ui.types';
import { AAAStore } from 'src/app/store/root-reducer';
import { environment } from 'src/environments/environment';
import { isMobileApp } from '../../shared/utils/app-detect'
import { AppVersion } from '../utils/app-version';

@Component({
  selector: 'app-legal-footer',
  templateUrl: './legal-footer.component.html',
  styleUrls: ['./legal-footer.component.scss']
})

export class LegalFooterComponent {
  appVersion: string
  appEnv: string | boolean = environment.description
  displayVersion: boolean = environment.displayVersion
  displayEnv = Boolean(environment.description)
  isDesktop: boolean
  rapConfig: VendorConfigurationMode

  activeStep$: Observable<number> = this.store$.pipe(
    select(selectActivePaceSetterStep)
  )
  problemTooltip$: Observable<PaceSetterSituation | null> = this.store$.pipe(
    select(selectProblemTooltip)
  )
  modeConfiguration$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )
  isRapUser$ = this.store$.pipe(select(selectIsRapUser))


  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private appVersionObj: AppVersion,
  ) {
    this.appVersion = appVersionObj.get()
    this.modeConfiguration$.subscribe((configData: VendorConfigurationMode) => this.rapConfig = configData)
  }

  openPrivacyPolicy() {
    this.taggingService.setClickEvent(
      events.shared.PRIVACY_POLICY_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )

    if (this.rapConfig) {
      this.store$.dispatch(
        openPromptDialog({
          payload: {
            type: PromptDialogTypes.IFRAME_DIALOG,
            panelClass: 'iframe-dialog',
            params: {
              ariaLabel: 'Privacy policy content',
              url: this.rapConfig.privacyPolicy,
            },
          },
        })
      )
    } else {
      window.open('https://www.aaa.com/privacy')
    }
  }

  openTerms($event) {
    $event.preventDefault()
    $event.stopPropagation()

    this.taggingService.setClickEvent(
      events.shared.TERMS_AND_CONDITIONS_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )

    const termsConditionsUrl = this.rapConfig?.termsConditions
      ? this.rapConfig.termsConditions
      : 'https://www.aaa.com/AAA/common/drrsmsterms/terms.html'

    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.IFRAME_DIALOG,
          panelClass: 'iframe-dialog',
          params: {
            ariaLabel: 'Terms and conditions content',
            url: termsConditionsUrl,
          },
        },
      })
    )
  }

  isWebApp() {
    return !isMobileApp()
  }



}
