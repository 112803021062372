import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import events from 'src/app/modules/tagging/events'
import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-call-cancel-dialog',
  templateUrl: './call-cancel.component.html',
  styleUrls: ['./call-cancel.component.scss'],
})
export class CallCancelDialogComponent {
  pageType = events.shared.CALL_CANCEL_CONFIRM_PAGE_TYPE

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onClose() {
    this.data.close()
  }
}
