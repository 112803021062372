import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { setOpenMapModal, setEnterAnAddress } from 'src/app/modules/location/tow-location/tow-location.actions'
import { AAAStore } from 'src/app/store/root-reducer'
import { Store } from '@ngrx/store'
import { isMobileSize } from 'src/app/shared/utils/windowSize'

@Component({
  selector: 'app-save-members-money-dialog-component',
  templateUrl: './save-members-money-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class SaveMembersMoneyDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>
  ) { }

  onSubmit() {
    this.store$.dispatch(setOpenMapModal({ payload: true }))
    this.onClose()
  }

  onEnterYourAddress() {
    this.store$.dispatch(setEnterAnAddress({ payload: true}))
    this.onClose()
  }

  onClose() {
    this.data.close()
  }
}
