import { AgmMap } from '@agm/core'
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { GoogleCoordinates } from 'src/app/modules/location/google-geocode/types'
import { DEFAULT_LAT, DEFAULT_LNG, } from 'src/app/modules/location/location.constants'
import { GoogleLocationMarker, LocationWithMarker, MAP_BOUNDS_PADDING } from 'src/app/modules/location/location.types'

import { cloneDeep } from 'lodash'
import {
  DEFAULT_BREAKDOWN_MARKER,
  DEFAULT_TRUCK_MARKER,
  MarkerDetails,
  MessageDialogTypes,
  PromptDialogTypes,
  USER_LOCATION_MARKER,
} from 'src/app/modules/ui/ui.types'
import { CustomMarkerComponent } from '../../ui/custom-marker/custom-marker.component'
import { select, Store } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { openMessageDialog, openPromptDialog } from '../../ui/ui.actions'
import { isCoordsEquals, LocationUtils } from '../location.utils'
import { ZoneEventEmitter } from '../../../zone-event-emitter'
import { ensureLocationServices, LOCATION_TYPE, setBreakdownLocationRequest } from '../location.actions'
import { TaggingService } from '../../tagging/tagging.service'
import events from '../../tagging/events'
import { selectAarPreview, selectIsEVstation } from '../aar/aar.selectors'
import { setAarPreview, setBreakdownLocationCenter, setHomeCenter } from '../aar/aar.actions'
import { FacilitiesDisplay } from '../aar/aar.types';
import { selectIsRapUser } from '../../auth/auth.selectors';
import { getCookie } from '../../../shared/utils/cookies';
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import {NON_AAR_TOWING_NAMES} from '../tow-location/tow-location.actions'
import { selectIsLocationSearchFocus, selectIsShopsNearHome } from '../../ui/ui.selectors'

declare let google: any

export const DEFAULT_MIN_ZOOM = 4
export const DEFAULT_MAX_MAP_ZOOM = 17

export enum MapViews {
  BREAKDOWN_LOCATION = 'BREAKDOWN_LOCATION',
  TOWING_DESTINATION = 'TOWING_DESTINATION',
  SERVICE_TRACKER = 'SERVICE_TRACKER',
}

@Component({
  selector: 'app-map-improvement',
  templateUrl: './map-improvement.component.html',
  styleUrls: ['./map-improvement.component.scss'],
})
export class MapImprovementComponent extends AbstractResponsiveComponent implements OnInit {
  @ViewChild(AgmMap) agmMap: AgmMap
  @ViewChild('truckMarkerComponent', { static: false })
  truckMarkerComponent: CustomMarkerComponent

  @ViewChild('towDestinationMarkerComponent', { static: false })
  towDestinationMarkerComponent: CustomMarkerComponent

  private _userLocation
  private shouldZoom = false
  private shouldMove = true
  private mapDragged = false
  private shouldRecenter = false
  private mapClassInterval = null

  private coords: GoogleCoordinates = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
  }
  defaultZoom$: BehaviorSubject<number> = new BehaviorSubject<number>(
    DEFAULT_MIN_ZOOM
  )
  nativeMap: google.maps.Map
  MAX_MAP_ZOOM = DEFAULT_MAX_MAP_ZOOM
  aarShopsLocation: Array<LocationWithMarker> = []
  loaded = false
  selectedLocation = null
  _breakdownMarker: GoogleLocationMarker
  _currentBreakdownMarker: GoogleLocationMarker
  _truckMarker = null
  _towDestinationMarker = null
  DEFAULT_INDEX_THRESHOLD = 100
  rapAppId = null

  defaultUserLocationData: MarkerDetails = {
    ...USER_LOCATION_MARKER,
  }

  @Input() view = MapViews.BREAKDOWN_LOCATION
  @Input() fitMapBounds = true
  @Input() tabIndexPosition = 0
  @Input() static = false
  @Input() showActionButtons = false
  @Input() preventTouch = true
  @Input() isLoading = false
  @Input() showSelectedTowDestination = false
  @Input() enableTowDestination = false
  @Input() mapBoundsPadding: typeof MAP_BOUNDS_PADDING = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }

  @Input() get userLocation() {
    return this._userLocation
  }
  set userLocation(value) {
    this._userLocation = value
  }

  @Input() get center(): GoogleCoordinates {
    return this.coords
  }
  set center(coords) {
    this.coords = coords || {
      lat: DEFAULT_LAT,
      lng: DEFAULT_LNG,
    }

    if (!this.mapDragged) {
      setTimeout(() => this.handleDebouncedCenter(coords), 0);
    } else {
      this.handleDebouncedCenter(coords)
    }
  }

  @Input() get breakdownMarker(): GoogleLocationMarker {
    return this._breakdownMarker
  }
  set breakdownMarker(value: GoogleLocationMarker) {
    this._currentBreakdownMarker = value
    const previous = { ...this._breakdownMarker }
    this.currentCoords = { ...this._currentBreakdownMarker }

    if (!this.mapDragged) {
      setTimeout(() => this.handleDebouncedMarker(previous, value), 50);
    } else {
      this.handleDebouncedMarker(previous, value)
    }
  }
  @Input() breakdownMarkerRender: MarkerDetails = DEFAULT_BREAKDOWN_MARKER

  _facilitiesDisplay = null
  @Input() get facilitiesDisplay(): FacilitiesDisplay {
    return this._facilitiesDisplay
  }
  set facilitiesDisplay(facilitiesDisplay: FacilitiesDisplay) {
    this._facilitiesDisplay = facilitiesDisplay
    this.aarShopsLocation = facilitiesDisplay?.markers || []
    this.determineSelectedShop()
    this.fitBounds()
  }

  @Input() blockOverlay = false

  selectIsEVstation$: Observable<boolean> = this.store$.pipe(select(selectIsEVstation))
  aarPreviewId$: Observable<number> = this.store$.pipe(select(selectAarPreview))
  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))
  isLocationSearchFocus$ = this.store$.pipe(select(selectIsLocationSearchFocus))
  isShopNearHome$: Observable<boolean> = this.store$.pipe(select(selectIsShopsNearHome))

  mapMarkersRef: QueryList<CustomMarkerComponent>
  @ViewChildren(CustomMarkerComponent)
  get mapMarkers(): QueryList<CustomMarkerComponent> {
    return this.mapMarkersRef
  }
  set mapMarkers(value: QueryList<CustomMarkerComponent>) {
    this.mapMarkersRef = value
    if (value && value.length > 0) {
      this.chooseZoomingStrategy()
    }
  }

  @Input() get towDestinationMarker(): GoogleLocationMarker {
    return this._towDestinationMarker
  }

  set towDestinationMarker(location: GoogleLocationMarker) {
    this._towDestinationMarker = location
    this.determineSelectedShop()
    this.towDestinationMarkerChange.emit(location)
  }

  @Input() get truckMarker(): GoogleLocationMarker {
    return this._truckMarker
  }

  set truckMarker(location: GoogleLocationMarker) {
    this._truckMarker = location
    this.determineSelectedShop()
    this.truckMarkerChange.emit(location)
  }
  @Input() truckMarkerRender: MarkerDetails = DEFAULT_TRUCK_MARKER

  referencesValue: LocationWithMarker[] = []
  @Input() get references(): LocationWithMarker[] {
    return this.referencesValue
  }
  set references(value: LocationWithMarker[]) {
    this.referencesValue = value?.length > 0 ? [...value].reverse() : []
  }

  @Input() showReferences = false

  @Input() hasLocationAccess = false
  @Input() hasDeniedGpsAccess = false
  @Input() isValidBreakdownLocation = false

  @Output() markerClicked = new EventEmitter()
  @Output() locationChanged = new EventEmitter()
  @Output() mapDrag: EventEmitter<GoogleCoordinates>
  @Output() mapClick: EventEmitter<GoogleCoordinates>
  @Output() towDestinationMarkerChange: EventEmitter<any> =
    new EventEmitter<any>()
  @Output() truckMarkerChange: EventEmitter<any> = new EventEmitter<any>()
  @Output() adjustLocationClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() adjustPinClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() searchAreaClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() useCurrentLocation = new EventEmitter()

  private currentCoords = { ...this.breakdownMarker }

  ngOnChanges(changes) {
    // this is a hack to make sure the map will fit the bounds of the markers
    const breakdownMarker = changes.breakdownMarker
    if (
      (this.view === MapViews.TOWING_DESTINATION &&
        changes.towDestinationMarker) ||
      (this.view === MapViews.BREAKDOWN_LOCATION &&
        breakdownMarker &&
        !isCoordsEquals(
          breakdownMarker.currentValue,
          breakdownMarker.previousValue
        ))
    ) {
      this.chooseZoomingStrategy()
      return null
    }

    if (this.view !== MapViews.SERVICE_TRACKER) {
      return null
    }

    const truckMarkerRenderChange = changes.truckMarkerRender
    if (this.truckMarkerComponent && truckMarkerRenderChange) {
      this.truckMarkerComponent.refreshMarker()
      this.chooseZoomingStrategy()
    }

    const towDestinationMarker = changes.towDestinationMarker
    if (this.towDestinationMarkerComponent && towDestinationMarker) {
      this.towDestinationMarkerComponent.refreshMarker()
      this.chooseZoomingStrategy()
    }
  }

  get currentMapZoom() {
    if (this.nativeMap) {
      return this.nativeMap.getZoom()
    }
  }

  constructor(
    private element: ElementRef,
    private store$: Store<AAAStore>,
    private ngZone: NgZone,
    public taggingService: TaggingService,
    public locationUtils: LocationUtils,
  ) {
    super()
    this.mapDrag = new ZoneEventEmitter<GoogleCoordinates>(ngZone)
    this.mapClick = new ZoneEventEmitter<GoogleCoordinates>(ngZone)
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.aarPreviewId$.subscribe((aarId: number) => {
        this.determineSelectedShop(aarId)
      }),
      this.isRapUser$.subscribe((isRapUser) => {
        this.rapAppId = isRapUser ? getCookie('AAA_AppId') : null
      })
    )
  }

  ngAfterViewInit() {
    this.bootstrapMap()
  }

  markerClick() {
    this.markerClicked.emit()
  }

  _dragListener = () => {
    if (this.mapDrag) {
      this.handleDragEndEvent()
    }
  }

  _clickListener = () => {
    if (this.view === MapViews.BREAKDOWN_LOCATION) {
      this.handleClickEvent()
    }
  }

  private bootstrapMap() {
    const mapSubscription = this.agmMap.mapReady.subscribe((map) => {
      this.nativeMap = map
      this.nativeMap.setOptions({
        scrollwheel: false,
        keyboardShortcuts: false,
      })
      this.nativeMap.addListener('dragend', this._dragListener)
      this.nativeMap.addListener('click', this._clickListener)
    })

    this.subscriptions.push(mapSubscription)

    this.mapClassInterval = setInterval(() => {
      const _labels = document.getElementsByClassName('gmnoprint')
      if (_labels.length) {
        clearInterval(this.mapClassInterval)
        this.handleAccessibilityElement(_labels)
      }
    }, 300)
  }

  private chooseZoomingStrategy() {
    if (
      !this.nativeMap ||
      !this.shouldMove ||
      !this.breakdownMarker ||
      !this.breakdownMarker.lat ||
      !this.breakdownMarker.lng
    ) {
      return
    }

    if (this.view !== MapViews.TOWING_DESTINATION || !this.loaded) {
      this.handleMapFocus(this.breakdownMarker)
    }
  }

  private handleDragEndEvent() {
    this.mapDragged = true
    if (!this.shouldMove) {
      return
    }

    this.shouldZoom = false
    const coords = this.nativeMap.getCenter()

    const lat = coords.lat()
    const lng = coords.lng()

    if (lat === 0 || lng === 0) {
      return
    }

    this.currentCoords['lat'] = lat
    this.currentCoords['lng'] = lng
    this.mapDrag.emit({ lat, lng })
  }

  private handleClickEvent() {
    if (!this.shouldMove) {
      return
    }

    this.shouldZoom = true
    const coords = this.nativeMap.getCenter()

    const lat = coords.lat()
    const lng = coords.lng()
    this.currentCoords['lat'] = lat
    this.currentCoords['lng'] = lng

    this.mapClick.emit({ lat, lng })
  }

  private fitBounds() {
    if(this.facilitiesDisplay?.fitAll === false && this.currentMapZoom !== DEFAULT_MIN_ZOOM) {
      return
    }
    if (this.view !== MapViews.BREAKDOWN_LOCATION) {
      setTimeout(() => {
        const bounds = new google.maps.LatLngBounds()
        this.mapMarkersRef.forEach((marker) => {
          if (
            !isNaN(marker?.locationValue?.lat) &&
            !isNaN(marker?.locationValue?.lng)
          ) {
            const coords = new google.maps.LatLng({
              lat: +marker.locationValue.lat,
              lng: +marker.locationValue.lng,
            })
            bounds.extend(coords)
          }
        })

        const mapBoundsPadding = {
          ...MAP_BOUNDS_PADDING,
          ...this.mapBoundsPadding
        }

        this.nativeMap.fitBounds(bounds, mapBoundsPadding)
        this.nativeMap.panToBounds(bounds, mapBoundsPadding)
      }, 300)
    }
  }

  private handleMapFocus(_breakdownMarker) {
    this.loaded = true

    const center = new google.maps.LatLng(
      _breakdownMarker.lat,
      _breakdownMarker.lng
    )

    this.nativeMap.panTo(center)
    const cityZoom = 9

    if (this.shouldZoom) {
      // zoom directly to user coords or cityZoom lv if address is not complete
      this.customZoomfn('in', this.showActionButtons ? cityZoom : this.MAX_MAP_ZOOM)
    }

    this.fitBounds()
  }

  customZoomfn(type = 'in', currentZoom) {
    // if there is no breakdown marker, we fallback to the default location
    // to enable the user to zoom in and out
    const locations = cloneDeep(this.currentCoords || this.breakdownMarker || this.coords)

    const center = new google.maps.LatLng(locations.lat, locations.lng)
    const newZoom = type === 'in' ? currentZoom + 1 : currentZoom - 1
    if (this.view === MapViews.BREAKDOWN_LOCATION) {
      this.nativeMap.setCenter(center)
    }
    this.nativeMap.setZoom(newZoom)
  }

  handleStop(stop: boolean) {
    this.shouldMove = !stop
    const currentZoom = this.nativeMap.getZoom()
    this.nativeMap.setOptions({
      draggable: !stop,
      maxZoom: stop ? currentZoom : DEFAULT_MAX_MAP_ZOOM,
      minZoom: stop ? currentZoom : DEFAULT_MIN_ZOOM,
    })

    if (stop) {
      this.shouldRecenter = true
    }
  }

  public handleDebouncedCenter(coords) {
    this.coords = coords || {
      lat: DEFAULT_LAT,
      lng: DEFAULT_LNG,
    }

    if (this.view === MapViews.BREAKDOWN_LOCATION) {
      this.centerToCoords(this.coords)
      if (!this.isValidBreakdownLocation) {
        return
      }
      if (
        !coords ||
        (coords.lat === DEFAULT_LAT && coords.lng === DEFAULT_LNG)
      ) {
        this.defaultZoom$.next(DEFAULT_MIN_ZOOM)
      } else {
        this.defaultZoom$.next(DEFAULT_MAX_MAP_ZOOM)
      }
    }
  }

  private handleDebouncedMarker(prev, current) {
    if (!isCoordsEquals(prev, current)) {
      this._breakdownMarker = { ...current }
      if (this.view === MapViews.BREAKDOWN_LOCATION) {
        this.shouldZoom = true
        this.chooseZoomingStrategy()
      }
    }
  }

  private centerToCoords(coords: GoogleCoordinates) {
    if (!coords.lat || !coords.lng) {
      return
    }

    const center = new google.maps.LatLng(coords.lat, coords.lng)
    this.nativeMap.panTo(center)
  }

  getAarMarkerRender(shop: GoogleLocationMarker) {
    return {
      label: shop.name || 'AAR Location',
      draggable: false,
      offsetY: 44,
    }
  }

  getReferenceMarkerRender(reference: GoogleLocationMarker) {
    return {
      label: reference.name || 'Reference',
      draggable: false,
      offsetY: 44,
      showAddress: true,
      address: reference.address,
    }
  }

  getTowMarkerRender(): MarkerDetails {
    return {
      label: 'Towing Destination',
      draggable: false,
      offsetY: 40,
    }
  }

  handleAarClick(preview: any) {
    const id = preview.location?.id || preview.aarId
    if (this.showSelectedTowDestination || this.enableTowDestination) {
      this.store$.dispatch(setAarPreview({ payload: { id } }))
      if (preview?.name !== NON_AAR_TOWING_NAMES.CUSTOM && !this.showSelectedTowDestination && this.isMobile) {
        this.store$.dispatch(openPromptDialog({
          payload: {
            type: PromptDialogTypes.SHOP_DETAILS,
            params: { id }
          }
        }))
      }
      this.determineSelectedShop(id)
    }
  }

  determineSelectedShop(previewId?: number) {
    this.selectedLocation = null
    const selectedId = previewId || this.towDestinationMarker?.aarId
    if (!this.aarShopsLocation || !selectedId) {
      return
    }
    const selectedLocationIndex = this.findIndexDestinationById(selectedId)
    this.selectedLocation = selectedLocationIndex !== -1 ? selectedLocationIndex : null
  }

  findIndexDestinationById = (id) =>
    this.aarShopsLocation.findIndex((shop) => shop.location.id === id)

  handleReferenceClick(preview: LocationWithMarker) {
    // just to debugging purposes when mapdebug=y
    console.log(
      'Debugging Google Result:',
      preview.marker.address,
      preview.marker.lat,
      preview.marker.lng
    )
  }

  handleFindMyLocation() {
    this.store$.dispatch(ensureLocationServices())
    this.useCurrentLocation.emit()
  }

  handleAdjustLocation() {
    this.adjustLocationClick.emit(true)
    this.taggingService.setClickEvent(
      events.location.LOCATION_ADJUST_LOCATION_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )
  }

  handleAdjustPin() {
    this.adjustPinClick.emit(true)
  }

  handleSearchArea() {
    this.searchAreaClick.emit(this.locationUtils.convertToMapState(this.nativeMap))
    this.taggingService.setClickEvent(
      events.towTo.DESTINATION_SEARCH_AREA_CLICK,
      events.towTo.DESTINATION_PAGE_TYPE
    )
  }

  help() {
    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: MessageDialogTypes.LOCATION_SERVICES_REQUIRED,
        },
      })
    )
  }

  private handleAccessibilityElement(nodes: any = []) {
    for (let index = 0; index < nodes.length; index++) {
      const element = nodes[index]

      if (!element.setAttribute) {
        continue
      }

      element.setAttribute('aria-hidden', 'true')
      element.setAttribute('tabindex', -1)
      element.setAttribute('disabled', true)

      // recursive handle child elements
      if (element.childNodes) {
        this.handleAccessibilityElement(element.childNodes)
      }
    }
  }

  /**
   * Set location to the current user coords
   */
  locateUser() {
    if (!this.userLocation) {
      return
    }
    this.store$.dispatch(
      setBreakdownLocationRequest({
        payload: this.userLocation,
        meta: { locationType: LOCATION_TYPE.GPS_LOCATION },
      })
    )

    // center user coords
    this.centerToCoords(this.userLocation)
    this._breakdownMarker = { ...this.userLocation }

    const lat = this._breakdownMarker.lat
    const lng = this._breakdownMarker.lng

    // notify location changed
    this.mapClick.emit({
      lat,
      lng,
    })

    this.currentCoords['lat'] = lat
    this.currentCoords['lng'] = lng
    this.taggingService.setClickEvent(
      events.location.LOCATE_ME,
      events.location.LOCATION_PAGE_TYPE
    )

    this.handleDragEndEvent()
  }

  isSameLocation = () => this.userLocation['lat'] === this.currentCoords['lat']
    && this.userLocation['lng'] === this.currentCoords['lng']

}
