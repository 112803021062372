import { StepperComponent } from './stepper/stepper.component'
import { StepComponent } from './stepper/step.component'
import { AuthSecurityWrapperComponent } from '../modules/auth/auth-security-wrapper/auth-security-wrapper.component'

import { PanelComponent } from './layouts/panel/panel.component'
import { SplitPanelComponent } from './layouts/split-panel/split-panel.component'
import { ExpandingPanelComponent } from './layouts/expanding-panel/expanding-panel.component'
import { SummaryComponent } from './summary/summary.component'
import { SplashscreenComponent } from './splashscreen/splashscreen.component'

import { AgentLoginComponent } from './layouts/agent-login/agent-login.component'

import { MinimalCredentialsComponent } from '../modules/auth/minimal-credentials/minimal-credentials.component'
import { EntitlementInfoComponent } from './entitlement-info/entitlement-info.component'
import { FormErrorComponent } from './controls/form-error/form-error.component'
import { FormErrorSummaryComponent } from './controls/form-error-summary/form-error-summary.component'
import { BannerComponent } from './banner/banner.component'
import { ButtonComponent } from './controls/button/button.component'
import { CancelRequestButtonComponent } from './controls/cancel-request/cancel-request.component'
import { CheckboxComponent } from './controls/checkbox/checkbox.component'
import { ClickableLinkComponent } from './controls/clickable-link/clickable-link.component'
import { DraggerComponent } from './controls/dragger/dragger.component'
import { ExpandingInputComponent } from './controls/expanding-input/expanding-input.component'
import { HomeLocationButtonComponent } from './controls/home-location-button/home-location-button.component'
import { ImageComponent } from './controls/image/image.component'
import { LinkButtonComponent } from './controls/link-button/link-button.component'
import { RadioSquareComponent } from './controls/radio-square/radio-square.component'
import { SelectOrTextComponent } from './controls/select-or-text/select-or-text.component'
import { WhiteLabelCredentialsComponent } from './layouts/white-label-credentials/white-label-credentials.component'
import {
  VinMileageCredentialsComponent
} from './whitelabel-credentials-modes/vin-mileage-credentials/vin-mileage-credentials.component'
import {
  CarRentalCredentialsComponent
} from './whitelabel-credentials-modes/car-rental-credentials/car-rental-credentials.component'
import {
  SubscriberNumberCredentialsComponent
} from './whitelabel-credentials-modes/subscriber-number-credentials/subscriber-number-credentials.component'
import { SelectComponent } from './controls/select/select.component'
import { AriaLinkButtonComponent } from './controls/aria-link/aria-link-button.component'
import { SkipToContentComponent } from './controls/skip-to-content/skip-to-content.component'
import { WelcomeHeaderComponent } from './welcome-header/welcome-header.component'
import { LegalFooterComponent } from './legal-footer/legal-footer.component'
import {
  ThirdPartyCookiesInstructionsComponent
} from './third-party-cookies-instructions/third-party-cookies-instructions.component'
import { LoginFailAssistantComponent } from '../modules/auth/login-fail-assitant/login-fail-assistant.component'
import { MembershipNumberComponent } from '../modules/auth/login-forms/membership-number.component';
import { MemberNameComponent } from '../modules/auth/login-forms/member-name.component';
import { PhoneNumberComponent } from '../modules/auth/login-forms/phone-number.component';
import { ReviewCardComponent } from '../modules/location/aar/review/review-card.component'
import { ReviewListComponent } from '../modules/location/aar/review/review-list.component'
import { ReviewsCountComponent } from './reviews-count/reviews-count.component'
import { TowLocationSelectedComponent } from '../modules/location/tow-location-selected/tow-location-selected.component'
import { GoogleGeocodeService } from '../modules/location/google-geocode/google-geocode.service'
import { LAZY_MAPS_API_CONFIG } from '@agm/core'
import { GoogleMapsConfig } from '../google-maps-config'
import { ChargingLevelComponent } from '../modules/location/ev-station/charging-level/charging-level.component';
import {
  ChargingLevelListComponent
} from '../modules/location/ev-station/charging-level-list/charging-level-list.component';
import { EvStationNotesComponent } from '../modules/location/ev-station/station-notes/station-notes.component';
import { ElectricVehicleComponent } from '../modules/location/ev-station/electric-vehicle/electric-vehicle.component'
import { EvStationConnectorTypeComponent } from '../modules/location/ev-station/connector-type/connector-type.component'
import {
  EvStationConnectorTypeListComponent
} from '../modules/location/ev-station/connector-type-list/connector-type-list.component'
import { BackButtonComponent } from './back-button/back-button.component'
import { StepperProgressBarComponent } from './stepper-progress-bar/stepper-progress-bar.component'
import { BumpOutAnimationComponent } from './stepper-progress-bar/bump-out-animation/bump-out-animation.component'
import { TowLocationPromoV2Component } from '../modules/location/tow-location-promo-v2/tow-location-promo-v2.component'
import { FacilityStatusComponent } from './facility-status/facility-status.component';
import { FacilityNextDateComponent } from './facility-next-date/facility-next-date.component';
import { SeeMoreButtonComponent } from './see-more-button/see-more-button.component';
import { LogoutComponent } from '../modules/auth/logout/logout.component';
import { AbTestSwitcherComponent } from './ab-test-switcher/ab-test-switcher.component'
import { TowLocationBannerComponent } from '../modules/location/tow-location-banner/tow-location-banner.component'

export function sharedComponents() {
  return [
    StepperComponent,
    StepComponent,
    AuthSecurityWrapperComponent,
    ExpandingPanelComponent,
    PanelComponent,
    SplitPanelComponent,
    SummaryComponent,
    SplashscreenComponent,
    MinimalCredentialsComponent,
    MembershipNumberComponent,
    MemberNameComponent,
    PhoneNumberComponent,
    EntitlementInfoComponent,
    FacilityNextDateComponent,
    FacilityStatusComponent,
    FormErrorSummaryComponent,
    FormErrorComponent,
    BannerComponent,
    ButtonComponent,
    TowLocationPromoV2Component,
    ImageComponent,
    DraggerComponent,
    ExpandingInputComponent,
    LinkButtonComponent,
    ClickableLinkComponent,
    HomeLocationButtonComponent,
    RadioSquareComponent,
    CheckboxComponent,
    SelectOrTextComponent,
    CancelRequestButtonComponent,
    AgentLoginComponent,
    WhiteLabelCredentialsComponent,
    VinMileageCredentialsComponent,
    CarRentalCredentialsComponent,
    SubscriberNumberCredentialsComponent,
    SelectComponent,
    AriaLinkButtonComponent,
    SkipToContentComponent,
    WelcomeHeaderComponent,
    LegalFooterComponent,
    LoginFailAssistantComponent,
    ThirdPartyCookiesInstructionsComponent,
    ReviewCardComponent,
    ReviewListComponent,
    ReviewsCountComponent,
    TowLocationSelectedComponent,
    ChargingLevelComponent,
    ChargingLevelListComponent,
    EvStationNotesComponent,
    ElectricVehicleComponent,
    EvStationConnectorTypeComponent,
    EvStationConnectorTypeListComponent,
    BackButtonComponent,
    SeeMoreButtonComponent,
    StepperProgressBarComponent,
    BumpOutAnimationComponent,
    LogoutComponent,
    AbTestSwitcherComponent,
    TowLocationBannerComponent
  ]
}

export function sharedProviders() {
  return [
    GoogleGeocodeService,
    { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig }
  ]
}
