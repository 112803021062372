import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { SessionService } from './session.service'
import { CHECK_SESSION_TIMEOUT, START_SESSION_TIMEOUT, startSessionTimeout } from './session.actions'
import { selectLastInteraction } from './session.selectors'
import { selectIsSecure, selectIsSignedIn } from '../auth/auth.selectors'
import { IndexedCollection, PayloadedAction } from '../../shared/types'
import { openMessageDialog } from '../ui/ui.actions'
import { Dialog, MessageDialogTypes } from '../ui/ui.types'
import { selectActiveDialogs } from '../ui/ui.selectors'
import { selectSessionTimeoutInterval, selectAlertSessionTimeoutInterval } from '../ui/timeout/timeout.selectors'
import { MatDialog } from '@angular/material/dialog'
import { AuthSecurityWrapperService } from '../auth/auth-security-wrapper/auth-security-wrapper.service'
import { DateTime } from 'luxon';

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private sessionService: SessionService,
    private authSecurityService: AuthSecurityWrapperService,
    private dialogService: MatDialog
  ) {}

  handleRefresh$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType('@ngrx/effects/init'),
        take(1),
        switchMap(() => [startSessionTimeout()])
      )
  )

  handleStartSessionTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(START_SESSION_TIMEOUT),
      withLatestFrom(
        this.store$.select(selectIsSignedIn),
        this.store$.select(selectIsSecure)
      ),
      filter(
        ([_, signedIn, isSecure]: [PayloadedAction, boolean, boolean]) =>
          signedIn && !isSecure
      ),
      tap(() => this.sessionService.startSessionTimeout())
    ),
    { dispatch: false }
  )

  handleCheckSessionTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CHECK_SESSION_TIMEOUT),
      withLatestFrom(
        this.store$.select(selectLastInteraction),
        this.store$.select(selectActiveDialogs),
        this.store$.select(selectSessionTimeoutInterval),
        this.store$.select(selectAlertSessionTimeoutInterval),
      ),
      switchMap(
        ([_, lastInteraction, dialogs, sessionTimeoutInterval, alertTimeoutInterval]: [
          PayloadedAction,
          number,
          IndexedCollection<Dialog>,
          number,
          number,
        ]) => {
          const now = DateTime.local().toMillis()
          const actions = []
          if (now - lastInteraction >= sessionTimeoutInterval) {
            this.sessionService.stopSessionTimeout()
            this.dialogService.closeAll()
            // https://github.com/clevertech/aaa-drr-v2/pull/1869/files
            this.authSecurityService.forceLogout()
            this.sessionService.clearStateSessionTimeOut()
          } else if (
            !dialogs[MessageDialogTypes.SESSION_TIMEOUT] &&
            now - lastInteraction >= alertTimeoutInterval
          ) {
            actions.push(
              openMessageDialog({
                payload: {
                  type: MessageDialogTypes.SESSION_TIMEOUT,
                  disableClose: true,
                },
              })
            )
          }
          return actions
        }
      )
    )
  )
}
