import {
  CANCEL_EDITING_REQUEST,
  CANCEL_REQUEST,
} from 'src/app/shared/actions/shared.actions'
import { FSA } from 'src/app/shared/types'
import {
  ADVISORIES,
  RESET_ADVISORIES,
  SET_ADVISORY_CLUB_SEEN,
} from './advisories.actions'
import { Advisory } from './advisories.types'

export interface AdvisoriesState {
  hasSeenAdvisories?: boolean
  advisories?: Array<Advisory>
}

export const initialState = {
  advisories: [],
  // https://app.zenhub.com/workspaces/aaa-drr-v2-5de5538749a5b40cd52dbbde/issues/clevertech/aaa-drr-v2/2282
  // Given this ticket, we only want to show any kind of advisories modal only once.
  hasSeenAdvisories: false,
}

export function reducer(state: AdvisoriesState = initialState, action: FSA) {
  switch (action.type) {
    case ADVISORIES.SUCCESS: {
      return {
        ...state,
        advisories: action.payload.advisories,
      }
    }
    case SET_ADVISORY_CLUB_SEEN: {
      return {
        ...state,
        hasSeenAdvisories: true,
      }
    }
    case CANCEL_REQUEST:
    case CANCEL_EDITING_REQUEST:
    case RESET_ADVISORIES: {
      return {
        ...state,
        advisories: [],
        hasSeenAdvisories: false,
      }
    }
    default:
      return state
  }
}
