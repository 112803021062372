import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable } from 'rxjs'
import events from 'src/app/modules/tagging/events'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import { DialogData } from 'src/app/modules/ui/ui.types'
import { AbstractComponent } from 'src/app/shared/abstract.component'
import { AAAStore } from 'src/app/store/root-reducer'
import { AdditionalLocationInfoFormState } from '../../../forms/forms.reducer'
import { selectAdditionalLocationInfoForm } from '../../../forms/forms.selectors'

@Component({
  selector: 'app-location-additional-info',
  templateUrl: './location-additional-info.component.html',
  styleUrls: [
    '../../shared-dialog.component.scss',
    './location-additional-info.component.scss',
  ],
})
export class LocationAdditionalInfoComponent
  extends AbstractComponent
  implements OnInit {
  form$: Observable<FormGroupState<AdditionalLocationInfoFormState>> =
    this.store$.pipe(select(selectAdditionalLocationInfoForm))
  form: FormGroupState<AdditionalLocationInfoFormState>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService
  ) {
    super()
  }

  ngOnInit() {
    this.form$.subscribe((form) => {
      this.form = form
    })

    this.taggingService.setAutomatedEvent(
      events.location.LOCATION_ADDITIONAL_INFO_PROMPT,
      events.location.LOCATION_PAGE_TYPE
    )
  }

  handleCommentForm(form: FormGroupState<AdditionalLocationInfoFormState>) {
    if (!form.isValid) {
      return null
    }

    this.taggingService.setClickEvent(
      events.location.LOCATION_ADDITIONAL_INFO_CONFIRM_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )

    return this.data.close()
  }

  onClose() {
    return this.data.close()
  }
}
