import { VehicleEditState } from './vehicle.reducer'
import { VendorConfigurationMode } from '../auth/mode-configuration.types'
import { Injectable } from '@angular/core'
import { Vehicle } from '../auth/eligibility.types'
import { VehicleDriveTypes } from './vehicle.types'
import { VehicleData } from '../member/member.types'

export const isAWD = (vehicle: VehicleEditState | VehicleData) => vehicle.driveType === VehicleDriveTypes.ALL_WHEEL_DRIVE || vehicle.driveType === VehicleDriveTypes.FOUR_WHEEL_DRIVE
export const getModelDescription = (vehicle: VehicleEditState) => vehicle?.driveType
    ? `${vehicle.model} (${isAWD(vehicle) ? 'AWD/4WD' : vehicle.driveType})`
    : vehicle?.model

@Injectable({
  providedIn: 'root',
})
export class VehicleUtils {

  isRestrictModel = (rapConfig: VendorConfigurationMode, vehicle: Vehicle) => {
    if(!rapConfig.restrictModels) {
      return false
    }
    const restrictedMake = Object.keys(rapConfig.restrictModels).find(make => make.toLowerCase() === vehicle.make.toLowerCase())
    const model = rapConfig.restrictModels[restrictedMake]?.find(model => vehicle.model.toLowerCase().indexOf(model.toLowerCase()) > -1)
    return model !== undefined
  }



  normalizeMotorHome = (str: string | string[]) => {
    if (typeof str === 'string') {
      return str.replace(/\//g, '-');
    } else {
      return str.map(s => s.replace(/\//g, '-'));
    }
  }

  uriEncode = (str: string | string[]) => {
    if (typeof str === 'string') {
      return encodeURIComponent(str);
    } else {
      return str.map(s => encodeURIComponent(s));
    }
  }
}
