<div class="wrapper" #mapWrapper aria-hidden="true" role="none" tabindex="-1">
  <map-touch-layer
    (stopped)="handleStop($event)"
    [blockOverlay]="blockOverlay"
    [preventTouch]="preventTouch">
    <agm-map
      id="map"
      [disableDefaultUI]="true"
      [gestureHandling]="static || blockOverlay ? 'none' : 'greedy'"
      [zoom]="defaultZoom$ | async"
      [maxZoom]="MAX_MAP_ZOOM"
      [latitude]="center.lat"
      tabindex="-1"
      [longitude]="center.lng">
      <ng-container [ngSwitch]="view">
        <ng-container *ngSwitchCase="'BREAKDOWN_LOCATION'">
          <ng-container *ngIf="userLocation?.lat && userLocation.lng && loaded">
            <app-custom-marker
              [location]="userLocation"
              [data]="defaultUserLocationData"
              (markerClicked)="markerClick()"
              [tabIndex]="tabIndexPosition + 2"
            >
              <app-img src="assets/images/blue-dot.jpg"></app-img>
            </app-custom-marker>
          </ng-container>
          <!-- red pins (plural) for all references -->
          <ng-container *ngIf="showReferences">
            <app-custom-marker
              *ngFor="let reference of referencesValue; let referenceIndex = index"
              [location]="reference.marker"
              (markerTouchStart)="handleReferenceClick(reference)"
              [agmFitBounds]="true"
              [data]="getReferenceMarkerRender(reference.marker)"
              id="map-pin-aar-{{ referencesValue.length - referenceIndex - 1 }}"
              [tabIndex]="(tabIndexPosition + 1) + referenceIndex"
            >
              <icon-aar-location-pin
                [selected]="reference.marker.address.startsWith(breakdownMarker?.address)"
                [index]="(tabIndexPosition + 1) + referenceIndex"
              ></icon-aar-location-pin>
            </app-custom-marker>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'TOWING_DESTINATION'">
          <ng-container *ngIf="breakdownMarker?.lat && breakdownMarker.lng" class="breakdown-pin">
            <app-custom-marker
              id="breakdown-pin"
              [location]="breakdownMarker"
              [data]="breakdownMarkerRender"
              [tabIndex]="(tabIndexPosition - 200) + (referencesValue.length || 0)">
              <icon-breakdown-location-pin [small]="true"></icon-breakdown-location-pin>
            </app-custom-marker>
          </ng-container>

          <ng-container *ngFor="let shop of aarShopsLocation; let shopIndex = index;">
            <app-custom-marker id="map-pin-aar-{{ aarShopsLocation.length - shopIndex - 1 }}"
              [location]="shop.marker"
              [data]="getAarMarkerRender(shop.marker)"
              (markerTouchStart)="handleAarClick(shop)"
              [tabIndex]="(tabIndexPosition - DEFAULT_INDEX_THRESHOLD) + (selectedLocation === shopIndex ? aarShopsLocation.length + shopIndex : 0)">
              <icon-ev-station-pin
                *ngIf="shop.location.id !== towDestinationMarker?.aarId && selectIsEVstation$ | async"
                id="icon-aar-pin-{{aarShopsLocation.length - shopIndex}}"
                [selected]="selectedLocation === shopIndex">
              </icon-ev-station-pin>
              <icon-aar-destination-pin id="icon-aar-pin-{{aarShopsLocation.length - shopIndex}}"
              *ngIf="shop.location.id !== towDestinationMarker?.aarId && !(selectIsEVstation$ | async)"
                [isSelected]="selectedLocation === shopIndex"
                [isCOR]="shop.location.isCOR"
                [rapAppId]="rapAppId">
              </icon-aar-destination-pin>
            </app-custom-marker>
          </ng-container>

          <ng-container *ngIf="towDestinationMarker?.lat && towDestinationMarker.lng">
            <app-custom-marker
              #towDestinationMarkerComponent
              [location]="towDestinationMarker"
              [data]="getTowMarkerRender()"
              (markerTouchStart)="handleAarClick(towDestinationMarker)"
              [tabIndex]="(tabIndexPosition + 3) + (aarShopsLocation.length || 0)">

              <icon-ev-station-pin
                *ngIf="towDestinationMarker.aarId && (selectIsEVstation$ | async)"
                id="icon-aar-pin-ev-selected"
                selected="true">
              </icon-ev-station-pin>
              <icon-aar-destination-pin
                id="icon-aar-pin-aar-selected"
                isSelected="true"
                *ngIf="towDestinationMarker.aarId && !(selectIsEVstation$ | async)"
                [rapAppId]="rapAppId">
              </icon-aar-destination-pin>
              <icon-tow-location-pin
                class="tow-location-destination-marker"
                [small]="true"
                [isSelected]="selectedLocation === null"
                *ngIf="!towDestinationMarker.aarId">
              </icon-tow-location-pin>
            </app-custom-marker>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'SERVICE_TRACKER'">
          <ng-container *ngIf="breakdownMarker?.lat && breakdownMarker.lng">
            <app-custom-marker
              id="breakdown-pin"
              [location]="breakdownMarker"
              [data]="breakdownMarkerRender"
              [tabIndex]="tabIndexPosition + 2">
              <icon-breakdown-location-pin></icon-breakdown-location-pin>
            </app-custom-marker>
          </ng-container>

          <ng-container *ngIf="towDestinationMarker">
            <app-custom-marker
              #towDestinationMarkerComponent
              [location]="towDestinationMarker"
              [data]="getTowMarkerRender()"
              [panOnPlace]="false"
              [panOnMove]="false"
              [tabIndex]="tabIndexPosition + 2"
            >
              <icon-tow-location-pin class="service-tracker-destination-marker"></icon-tow-location-pin>
            </app-custom-marker>
          </ng-container>

          <ng-container *ngIf="truckMarker">
            <app-custom-marker
              #truckMarkerComponent
              [location]="truckMarker"
              [data]="truckMarkerRender"
              [tabIndex]="tabIndexPosition + 2"
            >
              <icon-truck-location-pin [type]="truckMarkerRender.truckType"></icon-truck-location-pin>
            </app-custom-marker>
          </ng-container>
        </ng-container>
      </ng-container>
    </agm-map>
  </map-touch-layer>

  <ng-container *ngIf="view === 'BREAKDOWN_LOCATION'">
    <icon-breakdown-location-pin
      id="breakdown-pin"
      class="custom-marker"
      [ngClass]="{'blocked': blockOverlay}"
      role="figure"
      aria-labelledby="breakdown-pin"
    >
    </icon-breakdown-location-pin>

    <ng-container *ngIf="userLocation?.lat && userLocation.lng && loaded && !preventTouch && !blockOverlay" >
      <div class="bottom-actions-controls">
         <button
           aria-label="locate-user"
           (click)="locateUser()"
           [tabIndex]="tabIndexPosition + 5">
           <icon-locate color="white"></icon-locate>
         </button>
       </div>
     </ng-container>
  </ng-container>

  <ng-container *ngIf="!isMobile">
    <div class="buttons-holder" [ngClass]="{ 'custom-bottom': view === 'TOWING_DESTINATION'}">
      <button id="zoom-in-btn"
        aria-label="zoom in"
        (click)="customZoomfn('in',  nativeMap.getZoom())"
        [tabIndex]="tabIndexPosition + 5">
        +
      </button>
      <button id="zoom-out-btn"
        aria-label="zoom out"
        (click)="customZoomfn('out',  nativeMap.getZoom())"
        [tabIndex]="tabIndexPosition + 5"
        >
        -
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="view === 'BREAKDOWN_LOCATION' && showActionButtons">
    <div [ngClass]="{'bottom-actions-vertical': blockOverlay, 'bottom-actions-horizontal': !blockOverlay}" class="bottom-actions">
      <div id="find-my-location_container" [hidden]="(isLocationSearchFocus$ | async) && hasDeniedGpsAccess">
        <app-button
          id="find-my-location"
          class="find-my-location"
          *ngIf="!isValidBreakdownLocation"
          [disabled]="hasDeniedGpsAccess || isLoading"
          [loading]="isLoading"
          (buttonClick)="handleFindMyLocation()"
          [color]="'primary-yellow'"
          [tabIndex]="tabIndexPosition + 7"
          [hasShadow]="true"
          type="submit">
          Find My Location
        </app-button>
        <span class="tooltiptext" *ngIf="hasDeniedGpsAccess && !isValidBreakdownLocation">
          <p>Cannot access your location.</p>
          <a (click)="help()">Learn more</a>
        </span>
      </div>
      <app-button
        id="adjust-pin"
        class="adjust-pin"
        *ngIf="blockOverlay && hasDeniedGpsAccess"
        (buttonClick)="handleAdjustPin()"
        [color]="'primary-yellow'"
        [tabIndex]="tabIndexPosition + 7"
        [hasShadow]="true"
        type="submit">
        Adjust Pin
      </app-button>
      <app-button
        class="adjust-location"
        *ngIf="isValidBreakdownLocation"
        (buttonClick)="handleAdjustLocation()"
        [color]="'primary-no-uppercase'"
        [tabIndex]="tabIndexPosition + 7"
        [hasShadow]="true"
        type="submit">
        Adjust Location
      </app-button>
    </div>
  </ng-container>

  <ng-container *ngIf="view === 'TOWING_DESTINATION' && (showActionButtons || isLoading)">
    <div class="top-actions"
      [ngClass]="{'top-80': isShopNearHome$ | async}">
      <app-button class="search-area" (buttonClick)="handleSearchArea()" [disabled]="isLoading"
        [color]="'primary-no-uppercase'" [tabIndex]="tabIndexPosition + 7" [hasShadow]="true" type="submit">
        <ng-container *ngIf="!isLoading; else loading">
          <i class="fa-solid fa-magnifying-glass left-icon-button" alt="Search this area"></i>
          Search this area
        </ng-container>
        <ng-template #loading>
          <i class="fa-solid fa-spinner fa-spin"></i> Loading facilities
        </ng-template>
      </app-button>
    </div>
  </ng-container>

  <ng-container *ngIf="showSelectedTowDestination && view === 'TOWING_DESTINATION'">
    <ng-container *ngIf="isShopNearHome$ | async">
      <div class="top-tabs">
        <app-tab-view></app-tab-view>
      </div>
    </ng-container>
    <div class="bottom-selected-shop">
      <app-tow-location-selected [tabIndex]="tabIndexPosition + 8">
      </app-tow-location-selected>
    </div>
  </ng-container>
</div>
