import { BatteryQuote } from './quotes.types'
import { IndexedCollection, PayloadedAction } from 'src/app/shared/types'
import { BATTERY_QUOTES, BATTERY_QUOTES_NOTIFIED } from './quotes.action'

export interface AAAQuotesServicesState {
  battery: IndexedCollection<Array<BatteryQuote>>
  notified?: {
    [id: string]: boolean
  }
}
export const initialState: AAAQuotesServicesState = {
  battery: null,
  notified: null,
}

export function reducer(
  state: AAAQuotesServicesState = initialState,
  action: PayloadedAction
) {
  switch (action.type) {
    case BATTERY_QUOTES.SUCCESS:
      return {
        ...state,
        battery: action.payload,
      }
    case BATTERY_QUOTES.FAILURE:
      return {
        ...state,
        battery: null,
      }
    case BATTERY_QUOTES_NOTIFIED:
      return {
        ...state,
        notified: action.payload,
      }
    default:
      return state
  }
}
