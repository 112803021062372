import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import events from 'src/app/modules/tagging/events'
import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-cancel-feedback.component',
  templateUrl: './cancel-feedback-option.component.html',
  styleUrls: [
    '../shared-dialog.component.scss',
    './cancel-feedback-option.component.scss',
  ],
})
export class CancelFeedbackOptionComponent {
  pageType = events.shared.PRE_SUBMISSION_CANCEL_SURVEY

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onCallAAAClick() {
    this.data.close()
  }
}
