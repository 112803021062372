import { PayloadedAction } from '../../../shared/types'
import {
  SET_ALERT_SESSION_TIMEOUT_INTERVAL,
  SET_CALL_STATUS_INTERVAL,
  SET_CHECK_SESSION_TIMEOUT_INTERVAL,
  SET_CREATE_CALL_TIMEOUT,
  SET_FIRST_CALL_STATUS_TIMEOUT,
  SET_GET_ACTIVE_CALLS_TIMEOUT,
  SET_SESSION_TIMEOUT_INTERVAL
} from '../timeout/timeout.actions'


export interface TimeoutState {
  callStatusInterval: number
  sessionTimeoutInterval: number
  checkSessionTimeoutInterval: number
  alertSessionTimeoutInterval: number
  createCallTimeout: number
  firstCallStatusTimeout: number
  getActiveCallsTimeout: number
}

export const initialState: TimeoutState = {
  callStatusInterval: 60000,
  sessionTimeoutInterval: 10 * 60 * 1000,
  checkSessionTimeoutInterval: 30 * 1000,
  alertSessionTimeoutInterval: 9 * 60 * 1000,
  createCallTimeout: 60 * 1000,
  firstCallStatusTimeout: 15 * 1000,
  getActiveCallsTimeout: 15 * 1000,
}

export function reducer(
  state: TimeoutState = initialState,
  action: PayloadedAction
) {
  switch (action.type) {
    case SET_CALL_STATUS_INTERVAL:
      return {
        ...state,
        callStatusInterval: action.payload,
      }
    case SET_SESSION_TIMEOUT_INTERVAL:
      return {
        ...state,
        sessionTimeoutInterval: action.payload,
      }
    case SET_CHECK_SESSION_TIMEOUT_INTERVAL:
      return {
        ...state,
        checkSessionTimeoutInterval: action.payload,
      }
    case SET_ALERT_SESSION_TIMEOUT_INTERVAL:
      return {
        ...state,
        alertSessionTimeoutInterval: action.payload,
      }
    case SET_FIRST_CALL_STATUS_TIMEOUT:
      return {
        ...state,
        firstCallStatusTimeout: action.payload,
      }
    case SET_GET_ACTIVE_CALLS_TIMEOUT:
      return {
        ...state,
        getActiveCallsTimeout: action.payload,
      }
    case SET_CREATE_CALL_TIMEOUT:
      return {
        ...state,
        createCallTimeout: action.payload,
      }
    default:
      return state
  }
}
