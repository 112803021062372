import { PayloadedAction } from 'src/app/shared/types'
import { GenericCoordinates } from '../location/location.types'
import { ROUTE_DISTANCE_LOAD } from './route-distance.actions'
import { RouteDistanceResponse } from './route-distance.types'

export interface RouteDistancesState {
  routesDistance: Array<RouteDistance>
  routesAvailable: boolean
}

export interface RouteDistance {
  origin: GenericCoordinates
  destination: GenericCoordinates
  distanceInMiles: number
}

export const initialState: RouteDistancesState = {
  routesDistance: [],
  routesAvailable: false,
}

export function reducer(
  state: RouteDistancesState = initialState,
  action: PayloadedAction<RouteDistanceResponse>
): RouteDistancesState {
  switch (action.type) {
    case ROUTE_DISTANCE_LOAD.SUCCESS:
      return {
        ...state,
        routesDistance: [...state.routesDistance, action.payload],
        routesAvailable: true,
      }
    case ROUTE_DISTANCE_LOAD.FAILURE:
      return {
        ...state,
        routesAvailable: false,
      }

    default:
      return state
  }
}
