<div class="dialog">
  <div mat-dialog-title>
    <div class="main-title-container">
      <app-img src="assets/images/aaabattery.JPG" class="icon" alt="Battery Quotes"></app-img>
      <h1> Battery Quotes </h1>
    </div>
    <div class="main-subtitle-container">
      <p> 3-Year Free Replacement Limited Warranty</p>
      <div *ngIf="vehicle$ | async as vehicle" class="line">
        <div class="small-text">{{ vehicle | vehicle }}</div>
      </div>
    </div>
  </div>

  <div class="divider"></div>

  <div mat-dialog-content class="tall">
    <ng-container *ngFor="let quotes of batteryQuotes$ | async">

      <div class="main-list">
        <div class="row-info main-row">
          <span class="title">
            Engine:
          </span>
          <span class="value-engine">
            {{ quotes?.cengine }}
          </span>
        </div>

        <div class="row-info main-row">

            <span class="title">
              Part #:
            </span>
            <span class="value-part">
              {{ quotes?.ccustomfit }}
            </span>

            <span class="title custom-margin-left">
              CCA:
            </span>
            <span class="value-cca">
              {{ quotes?.cca }}
            </span>
        </div>

        <div class="sub-list">
          <div class="row-info">
            <div class="title">
              Member Price:
            </div>
            <span class="value-price-member">
              {{ quotes?.MemberPrice && quotes?.MemberPrice !== 'N/A' ? (quotes?.MemberPrice  | currency) : 'N/A' }} *
            </span>
          </div>
          <div class="row-info">
            <span class="title">
            Your Member Savings:
            </span>
            <span class="value-savings">
              {{ quotes?.MemberPrice && quotes?.MemberPrice !== 'N/A' ? ((quotes?.NonMemberPrice - quotes?.MemberPrice) | currency) : 'N/A' }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="disclaimer">
      <p>
        *Price does not include sales/EPA taxes and possible labor charges. Inventory availability varies, if your battery is unavailable a battery test and jump start or tow will be provided.
      </p>
    </div>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
