import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from '../../../tagging/tagging.service'
import events from '../../../tagging/events'
import { GPS_LOCATION_DISTANCE_WARN_LIMIT } from '../../../location/location.constants'

@Component({
  selector: 'gps-distance-warning-dialog-component',
  templateUrl: './gps-distance-warning-dialog.component.html',
  styleUrls: [
    '../shared-dialog.component.scss',
    'gps-distance-warning-dialog.component.scss',
  ],
})
export class GpsDistanceWarningDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private taggingService: TaggingService
  ) {
    this.taggingService.setAutomatedEvent(events.location.LOCATION_5_MILES_AWAY_COORDINATES, events.location.LOCATION_PAGE_TYPE)
  }

  DISTANCE_LIMIT = GPS_LOCATION_DISTANCE_WARN_LIMIT

  onClose() {
    this.data.close()
  }

  onContinue() {
    this.data.submit()
    this.onClose()
  }
}
