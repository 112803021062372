export default {
  PASSENGERS_PAGE_TYPE: 'Passengers',
  PASSENGERS_PAGE_PROMPT: 'Passengers screen displayed',
  PASSENGERS_COUNT_SELECT: 'Passengers count',
  PASSENGERS_NEXT_CLICK: 'Passengers next',
  DESTINATION_PAGE_TYPE: 'Tow destination',
  DESTINATION_PAGE_PROMPT: 'Tow destination screen displayed',
  DESTINATION_HOME_CLICK: 'Home tow location',
  DESTINATION_MORE_AARS_CLICK: 'Request more AAA',
  DESTINATION_MANUAL_INPUT: 'Tow location manually entered',
  DESTINATION_AAR_SELECT: 'Tow to this location',
  DESTINATION_DISTANCE_WARNING: 'tow over 50 miles',
  DESTINATION_DISTANCE_ALERT: 'tow over 100 miles',
  DESTINATION_DISTANCE_LIMIT: 'tow over 100 miles call AAA',
  DESTINATION_NEXT_CLICK: 'Tow Location next',
  DESTINATION_SEARCH_AREA_CLICK: 'Search this area',
  DESTINATION_SUGGESTED_SHOP_CLICK: 'Suggested shop selected',
  DESTINATION_SEE_MORE_SUGGESTIONS_CLICK: 'See more suggestions selected',
  DESTINATION_COUPON_APPLIED: 'Destination Coupon Applied',
  DESTINATION_DISCOUNT_APPLIED: 'Destination Discount Applied',
  DESTINATION_TAB_ITEM: 'Destination Tab Item'
}
