import { AgmCoreModule } from '@agm/core'
import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router'
import { NgbRatingModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap'
import { TextMaskModule } from 'angular2-text-mask'
import { NgrxFormsModule } from 'ngrx-forms'
import { NgxCaptchaModule } from 'ngx-captcha'
import { ResponsiveModule } from 'ngx-responsive'

import { MapTouchLayerComponent } from '../modules/location/map-touch-layer/map-touch-layer.component'
import { MapComponent } from '../modules/location/map/map.component'
import { MapImprovementComponent } from '../modules/location/map-improvement/map-improvement.component'

// UI
import { UiComponents } from '../modules/ui'
import { SharedDirectives } from './directives'
// Icons
import { icons } from './icons'
import { AgentLoginComponent } from './layouts/agent-login/agent-login.component'
import { MemberEligibilityComponent } from './member-eligibility/member-eligibility.component'
import { SharedPipes } from './pipes'
// Shared declarations
import { FormModules } from './shared-bootstraps'
// Components and providers
import {
  sharedComponents,
  sharedProviders
} from './shared.components.declarations'
import { FormModeDirective } from './layouts/white-label-credentials/form-mode.directive'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button'
import { BackButtonComponent } from './back-button/back-button.component'
import { TowLocationPromoV2Component } from '../modules/location/tow-location-promo-v2/tow-location-promo-v2.component'
import { TowLocationItemComponent } from '../modules/location/tow-location-item/tow-location-item.component';
import { SeeMoreButtonComponent } from './see-more-button/see-more-button.component';

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiVersion: '3.46'
    }),
    CommonModule,
    RouterModule,
    ResponsiveModule.forRoot(),
    MatDialogModule,
    FormModules(),
    NgbTypeaheadModule,
    NgrxFormsModule,
    NgxCaptchaModule,
    TextMaskModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    NgbRatingModule,
  ],
  declarations: [
    sharedComponents(),
    SharedDirectives(),
    SharedPipes(),
    UiComponents(),
    MapComponent,
    MapTouchLayerComponent,
    MapImprovementComponent,
    TowLocationItemComponent,
    icons,
    AgentLoginComponent,
    MemberEligibilityComponent,
    FormModeDirective,
    BackButtonComponent,
    SeeMoreButtonComponent,
    TowLocationPromoV2Component,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ResponsiveModule,
    MatDialogModule,
    sharedComponents(),
    FormModules(),
    SharedDirectives(),
    SharedPipes(),
    UiComponents(),
    MapComponent,
    MapTouchLayerComponent,
    MapImprovementComponent,
    TowLocationItemComponent,
    icons,
    NgrxFormsModule,
    NgxCaptchaModule,
    TextMaskModule,
    MatDividerModule,
    NgbRatingModule,
  ],
  providers: [sharedProviders()],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
