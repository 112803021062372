<div class="dialog">
  <h1 mat-dialog-title>
    Special Assistance
  </h1>
  <div mat-dialog-content
    class="modal-form">

    <div class="checkboxes-container">
      <app-checkbox
        name="hearingImpaired"
        (checkedChange)="driveDirectionsChange($event)"
        [checked]="hearingImpairedDetails">I'm hearing impaired.</app-checkbox>
    </div>

  </div>

  <div mat-dialog-actions
    class="buttons-row">
    <app-button (buttonClick)="handleSpecialAssistanceSubmit()">
      Confirm
    </app-button>
  </div>
  <button class="mat-dialog-close"
    aria-label="dismiss this message"
    (click)="onClose()">
    <icon-close></icon-close>
  </button>
</div>