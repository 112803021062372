import { createAction, props } from '@ngrx/store'
import { createAsyncConstants } from '../../shared/utils'
import {
  AgentSettingsParams,
  AuthMethods,
  AuthRequestParams,
  AuthResponse,
  MembershipNumberAuthRequestParams,
  RapAuthFailure,
  RapAuthResponse,
  SearchInfoSigninParams,
  SearchSigninParams,
  SecureAuthRequestParams,
  SetAuthParams,
  SetRapAuthParams,
} from './auth.types'

export const AUTH = createAsyncConstants('AUTH')
export const AUTH_VAS = createAsyncConstants('AUTH_VAS')
export const AUTH_RENTAL = createAsyncConstants('AUTH_RENTAL')
export const AUTH_OEM = createAsyncConstants('AUTH_OEM')
export const AUTH_RESET = 'AUTH_RESET'
export const SET_AUTH = 'SET_AUTH'
export const SET_RAP_AUTH = 'SET_RAP_AUTH'
export const AUTH_OEM_VALIDATION = 'AUTH_OEM_VALIDATION'
export const SET_AUTH_METHOD = 'SET_AUTH_METHOD'
export const SET_SECURE_PARAMS = 'SET_SECURE_PARAMS'
export const SET_ENCRYPTED_MEMBER_NUMBER = 'SET_ENCRYPTED_MEMBER_NUMBER'
export const AUTH_NAME_SEARCH = createAsyncConstants('AUTH_NAME_SEARCH')
export const AUTH_NAME_SEARCH_INFO = createAsyncConstants('AUTH_NAME_SEARCH_INFO')
export const AUTH_PHONE_NUMBER = createAsyncConstants('AUTH_PHONE_NUMBER')
export const SET_IS_AGENT = 'SET_IS_AGENT'
export const SET_AGENT_SETTINGS = 'SET_AGENT_SETTINGS'
export const SET_AGENT_FIRST_NAME = 'SET_AGENT_FIRST_NAME'
export const SET_AGENT_LAST_NAME = 'SET_AGENT_LAST_NAME'
export const FIRST_MATCH_FROM_SEARCH = 'FIRST_MATCH_FROM_SEARCH'
export const INCREMENT_ERROR_COUNT = 'INCREMENT_ERROR_COUNT'
export const SET_CLICKED_USE_ANOTHER_AUTH_FORM = 'SET_CLICKED_USE_ANOTHER_AUTH_FORM'
export const SET_LAST_SEARCH_ID = 'SET_LAST_SEARCH_ID'

// start ARR:POC - Agent settings actions commits the agent settings to state.
export const setAgent = createAction(
  SET_IS_AGENT,
  props<{ payload: boolean }>()
)

export const setAgentSettings = createAction(
  SET_AGENT_SETTINGS,
  props<{ payload: AgentSettingsParams }>()
)

export const setAgentFirstName = createAction(
  SET_AGENT_FIRST_NAME,
  props<{ payload: string }>()
)

export const setAgentLastName = createAction(
  SET_AGENT_LAST_NAME,
  props<{ payload: string }>()
)
// end ARR:POC

export const assignSecureParams = createAction(
  SET_SECURE_PARAMS,
  props<{ payload: SecureAuthRequestParams }>()
)

export const assignEncryptedMemberNumberParams = createAction(
  SET_ENCRYPTED_MEMBER_NUMBER,
  props<{ payload: MembershipNumberAuthRequestParams }>()
)

export const requestAuth = createAction(
  AUTH.REQUEST,
  props<{ payload: AuthRequestParams }>()
)

export const authSuccess = createAction(
  AUTH.SUCCESS,
  props<{ payload: AuthResponse }>()
)

export const notifyAuthFailure = createAction(AUTH.FAILURE)
export const notifyOemAuthFailure = createAction(AUTH_OEM.FAILURE)
export const notifyRentalAuthFailure = createAction(AUTH_RENTAL.FAILURE)
export const notifyVasAuthFailure = createAction(AUTH_VAS.FAILURE)

export const setAuth = createAction(
  SET_AUTH,
  props<{ payload: SetAuthParams }>()
)

export const setRapAuth = createAction(
  SET_RAP_AUTH,
  props<{ payload: SetRapAuthParams }>()
)

export const setAuthMethod = createAction(
  SET_AUTH_METHOD,
  props<{ payload: AuthMethods }>()
)

export const resetAuth = createAction(AUTH_RESET)

export const authNameSearchRequest = createAction(
  AUTH_NAME_SEARCH.REQUEST,
  props<{ payload: SearchSigninParams }>()
)





export const authVasRequest = createAction(
  AUTH_VAS.REQUEST,
  props<{ payload: AuthRequestParams }>()
)
export const authVasSuccess = createAction(
  AUTH_VAS.SUCCESS,
  props<{ payload: AuthResponse }>()
)
export const notifyAuthVasFailure = createAction(
  AUTH_VAS.FAILURE
)


export const authRentalRequest = createAction(
  AUTH_RENTAL.REQUEST,
  props<{ payload: AuthRequestParams }>()
)
export const authRentalSuccess = createAction(
  AUTH_RENTAL.SUCCESS,
  props<{ payload: AuthResponse }>()
)
export const notifyAuthRentalFailure = createAction(
  AUTH_RENTAL.FAILURE,
)
export const authOemValidation = createAction(
  AUTH_OEM_VALIDATION,
  props<{ payload: {
    response: RapAuthResponse,
    method: AuthMethods,
  } }>()
)
export const authOemRequest = createAction(
  AUTH_OEM.REQUEST,
  props<{ payload: AuthRequestParams }>()
)
export const authOemSuccess = createAction(
  AUTH_OEM.SUCCESS,
  props<{ payload: RapAuthResponse }>()
)
export const notifyRapAuthFailure = createAction(
  AUTH_OEM.FAILURE,
  props<{ payload: RapAuthFailure }>()
)

export const authNameSearchSuccess = createAction(
  AUTH_NAME_SEARCH.SUCCESS,
  props<{ payload: AuthResponse }>()
)

export const notifyAuthNameSearchFailure = createAction(
  AUTH_NAME_SEARCH.FAILURE
)

export const authNameSearchInfoRequest = createAction(
  AUTH_NAME_SEARCH_INFO.REQUEST,
  props<{ payload: SearchInfoSigninParams }>()
)

export const authNameSearchInfoSuccess = createAction(
  AUTH_NAME_SEARCH_INFO.SUCCESS,
  props<{ payload: AuthResponse }>()
)

export const notifyAuthNameSearchInfoFailure = createAction(
  AUTH_NAME_SEARCH_INFO.FAILURE
)

export const authPhoneNumberRequest = createAction(
  AUTH_PHONE_NUMBER.REQUEST,
  props<{ payload: string }>()
)

export const authPhoneNumberFailure = createAction(
  AUTH_PHONE_NUMBER.FAILURE
)

export const firstMatchFromSearch = createAction(
  FIRST_MATCH_FROM_SEARCH,
  props<{ payload: AuthResponse }>()
)

export const incrementErrorCount = createAction(INCREMENT_ERROR_COUNT)

export const setClickedUseAnotherForm = createAction(SET_CLICKED_USE_ANOTHER_AUTH_FORM)

export const setLastSearchId = createAction(
  SET_LAST_SEARCH_ID,
  props<{ payload: string }>()
)
