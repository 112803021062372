<div class="dialog">
  <h1 mat-dialog-title>
    You will need to be logged in to continue
  </h1>

  <div class="dialog-content">
    <app-minimal-credentials
      [displayCaptcha]="true"
      [displayNotMember]="false"
      [tabIndexPosition]="1000">
    </app-minimal-credentials>
  </div>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
