export const getAllCookies = (): Object =>
  document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .reduce((accumulator, cookie) => {
      const [key, value] = cookie.split('=')
      accumulator[key] = value
      return accumulator
    }, {})

export const getCookie = (cookieName: string): string => {
  const sanitizedCookieName = cookieName.replace(/-/g, '\\-')
  const matches = new RegExp(`${sanitizedCookieName}=[\\w-]+`, 'g').exec(
    document.cookie
  )
  if (!matches) {
    return ''
  }

  return matches[0].split('=')[1]
}

export const setCookie = (cookieName: string, cookieValue: string): void => {
  document.cookie = `${cookieName}=${cookieValue}; path=/;`
}
