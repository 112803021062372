import { DateTime } from 'luxon';

export function getMemberSinceYears(joinYearDate: string) {
  // backend can return the joinDate with two or four digits 😱
  const fullYear = DateTime.local().year
  if (joinYearDate.length === 1 || joinYearDate.length === 2) {
    return fullYear - 2000 - Number(joinYearDate)
  } else if (joinYearDate.length === 4) {
    return fullYear - Number(joinYearDate)
  } else {
    return null
  }
}

export function getMemberGreetings(joinYearDate: string) {
  const years = getMemberSinceYears(joinYearDate)
  if (years > 0) {
    return `Thank you for being a valued AAA member for ${years} year${years === 1 ? '' : 's'}!`
  }
  return 'Thank you for being a valued AAA member!'
}
