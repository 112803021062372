import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import {
  StoreRouterConnectingModule,
  DefaultRouterStateSerializer,
} from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/main.module').then((m) => m.MainModule),
      },
    ],
  },
  {
    path: 'healthz',
    loadChildren: () =>
      import('./modules/health/health.module').then((m) => m.HealthModule),
  },
]

@NgModule({
  imports: [
    StoreModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      stateKey: 'router',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
