import { Component, Input, OnInit } from '@angular/core'
import { OperatingDay } from '../types'
import { FacilityUtils } from '../../modules/location/aar/aar.utils';
import { DatetimeUtils } from '../utils/datetime';

@Component({
  selector: 'app-facility-next-date',
  template: '{{nextAction}}{{dayOfWeek | titlecase | slice:0:4}} {{time}}'
})
export class FacilityNextDateComponent implements OnInit {
  @Input() operatingDays: OperatingDay[]
  nextAction = 'a'
  dayOfWeek = ''
  time = ''
  isOpen: boolean

  constructor(
    private facilityUtils: FacilityUtils,
    private datetimeUtils: DatetimeUtils,
  ) {
  }

  ngOnInit(): void {
    this.isOpen = this.facilityUtils.isOpen(this.operatingDays)
    this.setNextAction()
    this.setDayOfWeek()
    this.setTime()
  }

  setNextAction = () => {
    this.nextAction = this.isOpen ? 'Closes' : 'Opens'
  }

  setDayOfWeek = () => {
    this.dayOfWeek = this.isOpen ? '' : ` ${this.getNextOpenDayOfWeek()}`
  }

  setTime = () => {
    let time
    if (this.isOpen) {
      const dayOfWeek = this.datetimeUtils.getDayOfWeek()
      time = this.getOperatingDay(dayOfWeek).close
    } else {
      const nextDayOfWeek = this.getNextOpenDayOfWeek()
      time = this.getOperatingDay(nextDayOfWeek).open
    }
    this.time = this.normalizeTime(time)
  }

  getNextOpenDayOfWeek = () => {
    const dayOfWeek = this.datetimeUtils.getDayOfWeek()
    const operatingDay = this.getOperatingDay(dayOfWeek)
    let nextWorkingDays
    if(operatingDay) {
      nextWorkingDays = this.datetimeUtils.getNextWorkingDays(operatingDay.open)
    } else {
      nextWorkingDays = this.datetimeUtils.getDaysOfWeekFrom('Tomorrow')
    }
    const operatingDays = this.operatingDays.map(od => od.day.toUpperCase())
    return nextWorkingDays.filter(dayOfWeek => operatingDays.includes(dayOfWeek))[0]
  }

  getOperatingDay = (dayOfWeek: string) => this.operatingDays.filter(od => od.day.toUpperCase() === dayOfWeek)[0]

  normalizeTime = (time: string) => time.replace(':00', '')
}
