import { Component, Input } from '@angular/core';
import { phoneMask, zipCodeMask } from '../minimal-credentials/minimal-credentails.constants';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['../minimal-credentials/minimal-credentials.component.scss'],
})
export class PhoneNumberComponent {
  @Input() controls

  zipCodeMask = zipCodeMask

  phoneNumberMask = phoneMask

  constructor() {}

}
