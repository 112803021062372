export interface CallDetailParams {
  callId: string,
  callDate: string,
  callToken: string,
  servicingClub: string
}

export interface DriverImage {
  width: number
  height: number
  url: string
}

export interface DriverInfoParams {
  driverId: string
  facilityId: string
  clubId: string
}

export interface DriverInfo {
  driverId?: string
  firstName: string
  lastName: string
  initial: string
  facilityName?: string
  facilityPhone?: string
  images: Array<DriverImage>
}

export enum EXTERNAL_SERVICES {
  GLYMPSE = 'GLYMPSE',
  BRINGG = 'BRINGG',
  APPOINTMENT_ASSISTANT = 'APPOINTMENT ASSISTANT',
}

export interface MessageStatus {
  title: string,
  description: string,
  active?: boolean,
}
