import { Component, Input, OnInit } from '@angular/core'
import { PaceSetterDetails } from 'src/app/modules/issue/issue.types'
import { AAAStore } from 'src/app/store/root-reducer'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import {
  selectActivePaceSetterDetails,
  selectActivePaceSetterSituation,
  selectNeedsTow,
} from 'src/app/modules/issue/issue.selectors'
import {
  selectBreakdownLocationAddress,
  selectLandMarkLocationName,
  displayLandmarkAddress,
  selectHighwayExit,
  selectSpecialAssistance,
  selectLocationClubRequested,
} from 'src/app/modules/location/location.selectors'
import { PromptDialogTypes } from 'src/app/modules/ui/ui.types'
import { selectTowLocationAddress } from 'src/app/modules/location/tow-location/tow-location.selectors'
import { TowLocation } from 'src/app/modules/location/location.types'
import { selectMemberActiveVehicle } from 'src/app/modules/member/member.selectors'
import { selectVehicleModelDescription, selectWorkingVehicle } from 'src/app/modules/vehicle/vehicle.selectors'
import { VehicleEditState } from 'src/app/modules/vehicle/vehicle.reducer'
import { formatVehicle } from '../pipes/vehicle.pipe'
import { constructDetailsSummary } from 'src/app/modules/issue/issue.utils'
import { map, withLatestFrom } from 'rxjs/operators'
import { EditService } from '../services/edit.service'
import { currentEditStepLocation } from 'src/app/modules/wizard/wizard.selectors'
import { AbstractComponent } from 'src/app/shared/abstract.component'
import { selectIsRapUser, selectIsVehicleChangeAllowed } from 'src/app/modules/auth/auth.selectors'
import { openPromptDialog } from 'src/app/modules/ui/ui.actions'

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent extends AbstractComponent implements OnInit {
  constructor(
    public editService$: EditService,
    private store$: Store<AAAStore>
  ) {
    super()
  }
  @Input() details: PaceSetterDetails

  _workingVehicle: VehicleEditState

  currentEditing$: Observable<any> = this.store$.pipe(
    select(currentEditStepLocation)
  )

  isEditing = false
  specialAssistanceDetails: string

  activeSituation$: Observable<any> = this.store$.pipe(
    select(selectActivePaceSetterSituation)
  )
  activeSituationDetails$: Observable<any> = this.store$.pipe(
    select(selectActivePaceSetterDetails),
    withLatestFrom(
      this.activeSituation$,
      this.store$.pipe(select(selectIsRapUser)),
    ),
    map(([details, active, isRapUser]: [PaceSetterDetails, PaceSetterDetails, boolean]) => ({
      icon: active ? active['icon'] : '',
      name: constructDetailsSummary(details, isRapUser),
    }))
  )
  needsTow$: Observable<boolean> = this.store$.pipe(select(selectNeedsTow))
  workingVehicle$: Observable<VehicleEditState> = this.store$.pipe(
    select(selectWorkingVehicle)
  )
  activeVehicle$: Observable<any> = this.store$.pipe(
    select(selectMemberActiveVehicle),
    map((vehicle) => formatVehicle(vehicle))
  )
  selectBreakdownLocationAddress$: Observable<string> = this.store$.pipe(
    select(selectBreakdownLocationAddress)
  )
  selectBreakdownLocationLandmark$: Observable<string> = this.store$.pipe(
    select(selectLandMarkLocationName)
  )
  selectDisplayLandmark$: Observable<boolean> = this.store$.pipe(
    select(displayLandmarkAddress)
  )
  towLocation$: Observable<TowLocation> = this.store$.pipe(
    select(selectTowLocationAddress)
  )
  vehicleModelDescription$: Observable<string> = this.store$.pipe(
    select(selectVehicleModelDescription)
  )
  isVehicleChangeAllowed$: Observable<boolean> = this.store$.pipe(
    select(selectIsVehicleChangeAllowed)
  )
  isLocationClubRequested$: Observable<boolean> = this.store$.pipe(
    select(selectLocationClubRequested)
  )
  highWayExit$ = this.store$.pipe(select(selectHighwayExit))

  ngOnInit() {
    this.subscriptions.push(
      this.workingVehicle$.subscribe((vehicle: VehicleEditState) => {
        this._workingVehicle = vehicle
      }),
      this.store$.pipe(select(selectSpecialAssistance)).subscribe((driverDirections) => {
        this.specialAssistanceDetails = driverDirections
      })
    )
  }

  handleSpecialAssistanceEdit() {
    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.SPECIAL_ASSISTANCE_DIALOG,
        },
      })
    )
  }

}


