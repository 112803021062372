import {
  CALL_STATUS,
  SET_ACTIVE_CALL_STATUS,
  DRIVER_INFO,
  CALL_STATUS_RESET,
  ACTIVE_CALL_STATUS,
  SET_TOW_DESTINATION_COORDINATES
} from './call-status.actions'
import { AAACallStatus } from '../calls.types'
import { ADD_CALL, CALL_CANCEL, RESET_ACTIVE_CALLS_CALLED } from '../calls.actions';
import { IndexedCollection, PayloadedAction } from 'src/app/shared/types'
import { generateCallId } from '../calls.utils'

export interface AAACallsStatusesState {
  data: IndexedCollection<AAACallStatus>
  activeCallStatus: string
  canceledCalls: Array<string>
  drivers: {}
  activeCallCalled: boolean
}

export const initialState: AAACallsStatusesState = {
  data: {},
  activeCallStatus: '',
  drivers: {},
  canceledCalls: [],
  activeCallCalled: false
}
export function reducer(
  state: AAACallsStatusesState = initialState,
  action: PayloadedAction
) {
  switch (action.type) {
    case ADD_CALL.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [generateCallId(
            action.payload.response.callId,
            action.payload.response.callDate
          )]: {
            ...action.payload.response,
            ...action.payload.data,
            // duplicate some data for temporary call
            towDestination: action.payload.data.towing,
            pacesetterCode: action.payload.data.situationCodes.pacesetterCode,
          },
        },
      }
    case ACTIVE_CALL_STATUS.SUCCESS:
      return {
        ...state,
        activeCallCalled: true,
      }
    case RESET_ACTIVE_CALLS_CALLED:
      return {
        ...state,
        activeCallCalled: false,
      }
    case CALL_STATUS.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      }
    case CALL_CANCEL.SUCCESS:
      // TODO move business logic to effects/selector
      const newData = { ...state.data }
      const callId = generateCallId(
        action.payload.callId,
        action.payload.callDate
      )
      delete newData[callId]
      return {
        ...state,
        data: { ...newData },
        canceledCalls: [...state.canceledCalls, callId],
        activeCallStatus: '',
      }
    case SET_ACTIVE_CALL_STATUS:
      return {
        ...state,
        activeCallStatus: action.payload.id,
      }
    case DRIVER_INFO.SUCCESS:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          [action.payload.driverId]: action.payload,
        },
      }
    case SET_TOW_DESTINATION_COORDINATES:
      return {
        ...state,
        data: {
          ...state.data,
          [state.activeCallStatus]: {
            ...state.data[state.activeCallStatus],
            towDestination: {
              ...state.data[state.activeCallStatus]?.towDestination,
              latitude: action.payload.lat,
              longitude: action.payload.lng,
            }
          }
        }
      }
    case CALL_STATUS_RESET:
      return {
        ...initialState,
        canceledCalls: state.canceledCalls,
      }
    default:
      return state
  }
}
