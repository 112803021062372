import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { RouteGuardService } from './route-guard.service'

@Injectable()
export class IsAuthorizedRouteGuard implements CanActivate {
  constructor(private routeGuardService: RouteGuardService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<boolean> {
    return this.routeGuardService.isPathAllowed().pipe(
      map((isAllowed) => {
        if (!isAllowed) {
          this.routeGuardService.redirectToLogin(route.queryParams)
        } else {
          this.routeGuardService.redirectToServiceTracker()
        }

        return isAllowed
      })
    )
  }
}
