import { createFeatureSelector, createSelector, defaultMemoize } from '@ngrx/store'

import { IndexedCollection } from 'src/app/shared/types'
import { concatAddress, concatAddressLine1, concatAddressLine2, } from 'src/app/shared/utils/concatAddress'
import { DatetimeUtils, convertToDateOrNull, hourToDate } from 'src/app/shared/utils/datetime'
import { PACE_SETTER_SITUATION_CODES, PaceSetterCode, } from '../../issue/issue.types'
import { selectAARData, selectExistsAarForVehicle } from '../../location/aar/aar.selectors'
import { GoogleCoordinates } from '../../location/google-geocode/types'
import {
  selectBreakdownLocation,
  selectIsBreakdownLocationValid,
  selectLocationClub,
} from '../../location/location.selectors'
import { BreakdownLocation, GoogleLocationMarker, TowLocation, } from '../../location/location.types'
import { compareAddresses } from '../../location/location.utils'
import { NON_AAR_TOWING_NAMES } from '../../location/tow-location/tow-location.actions'
import { selectIsMemberVehicleValid, selectMemberActiveVehicle, } from '../../member/member.selectors'
import { Vehicle, VehicleData } from '../../member/member.types'
import { AAACallStatus, AAATracking, BreakdownLocationParams, CALL_STATUS_CODES, } from '../calls.types'
import { generateCallId } from '../calls.utils'
import { AAACallsStatusesState } from './call-status.reducer'
import { DriverInfo, EXTERNAL_SERVICES } from './call-status.types'
import { selectNeedsTow } from '../../issue/issue.selectors';
import { selectIsPacesetterOverridden } from '../../servicing-club/servicing-club.selectors'
import { PACE_SETTER_OVERRIDE_TYPES } from '../../servicing-club/servicing-club.types'
import { CALLS_STATUSES_MESSAGES_FIXTURE } from '../../../shared/fixtures/calls-status.fixtures'
import { selectCallTowing } from '../calls.selectors'

const stateKey = 'callsStatuses'

const datetimeUtils: DatetimeUtils = new DatetimeUtils()

export const selectCallsStatusesState = createFeatureSelector<AAACallsStatusesState>(stateKey)

export const selectActiveCallStatusId = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState) => state.activeCallStatus
)

export const createSelectDefaultActiveCallStatusId = createSelector(
  selectActiveCallStatusId,
  (activeCallStatusId) =>
    defaultMemoize(
      (indexedCallStatus: IndexedCollection<AAACallStatus>): string | null =>
        getDefaultActiveCall(indexedCallStatus, activeCallStatusId)
    )
)

// FIXME: deprecated
export const selectCanceledCallStatus = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState): Array<string> => state.canceledCalls
)

export const selectCallsStatusesData = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState): IndexedCollection<AAACallStatus> => state.data
)

export const selectHasAvailableCall = createSelector(
  selectCallsStatusesData,
  // Need to verify if cancelled calls are excluded.
  (calls: IndexedCollection<AAACallStatus>): boolean =>
    Object.keys(calls).length > 0
)

export const selectFollowingCallsStatusId = createSelector(
  selectCallsStatusesData,
  selectActiveCallStatusId,
  (callStatuses, activeCallStatusId): string => {
    const remainingCalls = Object.values(callStatuses).filter(
      (call) =>
        generateCallId(call.callId, call.callDate) !== activeCallStatusId
    )

    return remainingCalls.length > 0
      ? generateCallId(remainingCalls[0].callId, remainingCalls[0].callDate)
      : null
  }
)

export const selectDrivers = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState): IndexedCollection<DriverInfo> => state.drivers
)

export const selectActiveCallStatus = createSelector(
  selectCallsStatusesData,
  selectActiveCallStatusId,
  (
    callsStatuses: IndexedCollection<AAACallStatus>,
    activeCallStatusId: string
  ): AAACallStatus => {
    const activeCallStatus = callsStatuses[activeCallStatusId]
    if (!activeCallStatus) {
      return null
    }

    return { ...activeCallStatus }
  }
)

export const selectEta = createSelector(
  selectActiveCallStatus,
  (callStatus: AAACallStatus): Date => {
    if (!callStatus) {
      return null
    }
    const driverData = callStatus.driverData
    if (driverData?.etaUTC) {
      return convertToDateOrNull(driverData.etaUTC)
    } else if (driverData?.eta) {
      return hourToDate(driverData.eta)
    } else if (callStatus.pta) {
      return convertToDateOrNull(callStatus.pta)
    } else {
      return null
    }
  }
)

export const selectCanCancelActiveCall = createSelector(
  selectActiveCallStatus,
  (activeCallStatus: AAACallStatus): boolean => {
    if (activeCallStatus === null) {
      return false
    }

    switch (activeCallStatus.callStatus) {
      case CALL_STATUS_CODES.OL:
      case CALL_STATUS_CODES.OS:
      case CALL_STATUS_CODES.UT:
      case CALL_STATUS_CODES.TW:
        return false
      default:
        return true
    }
  }
)

export const selectDriverInfo = createSelector(
  selectActiveCallStatus,
  selectDrivers,
  (call, drivers): DriverInfo => drivers[call?.driverData?.id] || null
)

export const selectDriverLocation = createSelector(
  selectActiveCallStatus,
  (activeCallStatus): GoogleCoordinates => {
    if (!activeCallStatus) {
      return null
    }

    const { driverData } = activeCallStatus
    if (
      !driverData ||
      (driverData.latitude === '0' && driverData.longitude === '0')
    ) {
      return null
    }

    return {
      lat: Number(driverData.latitude),
      lng: Number(driverData.longitude),
    }
  }
)

export const selectCallPaceSetterCode = createSelector(
  selectActiveCallStatus,
  (activeCallStatus): PaceSetterCode['paceSetterCode'] =>
    activeCallStatus?.pacesetterCode
)

// TODO fix duplicate of selectTowLocationAddress in tow-location.selectors.ts
export const selectCallTowLocation = createSelector(
  selectActiveCallStatus,
  selectAARData,
  (activeCallStatus, aars): null | TowLocation => {

    // Undefined || null tow destination
    if(!Boolean(activeCallStatus?.towDestination)) {
      return null
    }

    const destination = activeCallStatus.towDestination
    // The server will sometimes return a different structure, so get the address from one type or the other.
    // update: just confirmed with Randy, that facility is not part of any response, so it's going to be remove
    const address = destination.fullAddress
      ? destination.fullAddress
      : concatAddress(
        destination,
        destination.landmark || destination.name || destination.location || ''
      )

    const isAar =
      destination.isAar ||
      (destination.name !== NON_AAR_TOWING_NAMES.CUSTOM &&
        destination.name !== NON_AAR_TOWING_NAMES.HOME) ||
      Boolean(aars.find(compareAddresses(address)))

    return {
      ...destination,
      address,
      isAar,
    }
  }
)

export const selectCallVehicle = createSelector(
  selectActiveCallStatus,
  (activeCallStatus): null | VehicleData => activeCallStatus?.vehicle || null
)

export const selectActiveBreakdownMarkerData = createSelector(
  selectActiveCallStatus,
  (callState: AAACallStatus): GoogleLocationMarker => {
    if (!callState?.breakdownLocation) {
      return null
    }
    // TODO investigate this curveball.
    const streetName =
      (callState.breakdownLocation as BreakdownLocation).streetName ||
      (callState.breakdownLocation as BreakdownLocationParams).street
    const postalCode =
      (callState.breakdownLocation as BreakdownLocation).postalCode ||
      (callState.breakdownLocation as BreakdownLocationParams).zip

    const { latitude, longitude, state, city, streetNumber, location } =
      callState.breakdownLocation
    return {
      lat: Number(latitude),
      lng: Number(longitude),
      addressLine1: concatAddressLine1(streetNumber, streetName, location),
      addressLine2: concatAddressLine2(city, state, postalCode, true),
    }
  }
)

export const selectCallTowDestinationMarkerData = createSelector(
  selectCallTowLocation,
  (destination: TowLocation): GoogleLocationMarker => {
    if (!destination) {
      return null
    }
    return {
      lat: Number(destination.latitude),
      lng: Number(destination.longitude),
      address: destination.address,
    }
  }
)

const isValidExternalProvider = (provider: string) => Object.keys(EXTERNAL_SERVICES)
  .map(key => EXTERNAL_SERVICES[key])
  .includes(provider.toUpperCase())

export const selectExternalService = createSelector(
  selectActiveCallStatus,
  (activeCallStatus: AAACallStatus): null | AAATracking =>
    activeCallStatus?.tracking?.provider && isValidExternalProvider(activeCallStatus.tracking.provider)
      ? activeCallStatus.tracking
      : null
)

export const selectExternalServiceUrl = createSelector(
  selectExternalService,
  (tracking: AAATracking): null | string => tracking?.url ? tracking.url : null
)

export const selectActiveCallServingClub = createSelector(
  selectActiveCallStatus,
  (activeCallStatus: AAACallStatus): null | string =>
    activeCallStatus?.servicingClub || null
)

export const selectIsActiveBatteryCall = createSelector(
  selectActiveCallStatus,
  selectIsPacesetterOverridden,
  (activeCallStatus: AAACallStatus, isPacesetterCodeOverridden) => activeCallStatus?.pacesetterCode && (
    activeCallStatus.pacesetterCode === PACE_SETTER_SITUATION_CODES.L302 ||
    isPacesetterCodeOverridden(activeCallStatus.pacesetterCode, PACE_SETTER_OVERRIDE_TYPES.BATTERY_ISSUE)) ||
    null
)

export const selectHasPostalCodeChanged = createSelector(
  selectBreakdownLocation,
  selectIsBreakdownLocationValid,
  selectActiveCallStatus,
  (breakdownLocation, isBreakdownLocationValid, activeCallStatus): boolean =>
    isBreakdownLocationValid &&
    !!activeCallStatus &&
    breakdownLocation?.postalCode !==
      ((activeCallStatus.breakdownLocation as BreakdownLocation)?.postalCode ||
        (activeCallStatus.breakdownLocation as BreakdownLocationParams)?.zip)
)

export const selectHasVehicleChanged = createSelector(
  selectMemberActiveVehicle,
  selectIsMemberVehicleValid,
  selectActiveCallStatus,
  (activeVehicle, isMemberVehicleValid, activeCallStatus): boolean =>
    isMemberVehicleValid &&
    !!activeCallStatus &&
    !!activeCallStatus.vehicle &&
    (activeCallStatus.vehicle.year !== activeVehicle?.year ||
      activeCallStatus.vehicle.make !== activeVehicle?.make ||
      activeCallStatus.vehicle.model !== activeVehicle?.model)
)

export const selectHasClubChanged = createSelector(
  selectLocationClub,
  selectActiveCallStatus,
  (club, activeCallStatus): boolean =>
    !!activeCallStatus &&
    Boolean(club) && club !== activeCallStatus.servicingClub
)

export const selectActiveCallsCalled = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState): boolean => state.activeCallCalled
)

export const selectIsDestinationSearchRequired = createSelector(
  selectBreakdownLocation,
  selectMemberActiveVehicle,
  selectExistsAarForVehicle,
  selectNeedsTow,
  selectActiveCallStatus,
  (
    breakdownLocation: BreakdownLocation,
    activeVehicle: Vehicle,
    existsAarForVehicleSlug: boolean,
    needsTow: boolean,
    activeCall: AAACallStatus,
  ) => Boolean(breakdownLocation) &&
    needsTow &&
    Boolean(activeVehicle) &&
    !existsAarForVehicleSlug &&
    !activeCall
)

export const selectIsTrackingInfoAvailable = createSelector(
  selectActiveCallStatus,
  (activeCall: AAACallStatus): boolean => Boolean(
    activeCall
    && (
      activeCall.tracking?.url
      || (activeCall.driverData && activeCall.driverData.latitude && activeCall.driverData.longitude)
    )
  )
)

export const selectIsDisplayTime = createSelector(
  selectEta,
  selectExternalServiceUrl,
  selectActiveCallStatus,
  (eta: Date, externalUrl: string, activeCall: AAACallStatus): boolean => {
    if (!activeCall || !eta || externalUrl) {
      return false
    }

    switch (activeCall.callStatus) {
      case CALL_STATUS_CODES.OL:
      case CALL_STATUS_CODES.OS:
      case CALL_STATUS_CODES.UT:
      case CALL_STATUS_CODES.TW:
        return false
      default:
        return true
    }
  }
)

export const selectDisplayEta = createSelector(
  selectEta,
  selectIsDisplayTime,
  (eta: Date, isDisplayTime: boolean): Date => isDisplayTime ? eta : null
)

export const selectIsCallStatusEnRoute = createSelector(
  selectActiveCallStatus,
  (activeCall: AAACallStatus): boolean => activeCall?.callStatus === CALL_STATUS_CODES.ER
)


export const selectMessageByCallStatus = createSelector(
  selectActiveCallStatus,
  (activeCall) => {
    if (!activeCall?.callStatus) {
      return null
    }
    switch (activeCall.callStatus) {
      case CALL_STATUS_CODES.ER: // En route
        return {
          title: 'Your service provider is driving to your location.',
          description: 'Please plan to meet your service provider at your vehicle’s breakdown location.',
        }
      case CALL_STATUS_CODES.TW: // Towing
        return {
          title: 'Tow',
          description: 'Rest easy during your towing service. We appreciate your patience and thank you for choosing AAA.',
        }
      case CALL_STATUS_CODES.OS: // On Sight
      case CALL_STATUS_CODES.OL: // On Location
        return {
          title: `We've arrived.`,
          description: 'Please meet your service provider at your vehicle’s breakdown location.',
        }
      default: // Call Received and others
        return {
          title: 'Your request has been received.',
          description: 'We will notify you once your request has been assigned to a service provider.',
        }
    }

  }
)

export const selectDisplayArrivalTime = createSelector(
  selectEta,
  (eta: Date): number => datetimeUtils.getDateDifference(eta)
);

export const selectMessageByCallStatusTimeline = createSelector(
  selectActiveCallStatus,
  selectNeedsTow,
  selectCallTowing,
  (activeCall, needsTow, callTowing) => {
    if (!activeCall?.callStatus) {
      return null
    }
    return getAllCallStatusesMessages(activeCall.callStatus, needsTow || Boolean(callTowing))
  }
)

function getAllCallStatusesMessages(activeCall: CALL_STATUS_CODES, needsTow: boolean) {
  switch (activeCall) {
    case CALL_STATUS_CODES.ER: // En route
      return CALLS_STATUSES_MESSAGES_FIXTURE(CALL_STATUS_CODES.ER, needsTow)
    case CALL_STATUS_CODES.OS: // On Sight
    case CALL_STATUS_CODES.OL: // On Location
      return CALLS_STATUSES_MESSAGES_FIXTURE(CALL_STATUS_CODES.OL, needsTow)
    case CALL_STATUS_CODES.TW: // Towing
      return CALLS_STATUSES_MESSAGES_FIXTURE(CALL_STATUS_CODES.TW, needsTow)
    default: // Call Received and others
      return CALLS_STATUSES_MESSAGES_FIXTURE(CALL_STATUS_CODES.RE, needsTow)
  }
}
function getDefaultActiveCall(
  indexedCallStatuses: IndexedCollection<AAACallStatus>,
  activeCallStatusId: string
): string | null {
  const isActiveCall = (
    indexedStatuses: IndexedCollection<AAACallStatus>,
    id: string
  ) =>
    indexedStatuses[id].callStatus !== CALL_STATUS_CODES.CP &&
    indexedStatuses[id].callStatus !== CALL_STATUS_CODES.HD &&
    indexedStatuses[id].callStatus !== CALL_STATUS_CODES.CL &&
    indexedStatuses[id].callStatus !== CALL_STATUS_CODES.CA &&
    indexedStatuses[id].callStatus !== CALL_STATUS_CODES.XX
  if (
    activeCallStatusId &&
    indexedCallStatuses?.[activeCallStatusId] &&
    isActiveCall(indexedCallStatuses, activeCallStatusId)
  ) {
    return activeCallStatusId
  } else {
    const activeCalls = Object.keys(indexedCallStatuses || {}).filter((id) =>
      isActiveCall(indexedCallStatuses, id)
    )
    if (activeCalls.length) {
      return activeCalls[0]
    } else {
      return null
    }
  }
}

