import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from, Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { PayloadedAction } from 'src/app/shared/types'
import { AAAStore } from 'src/app/store/root-reducer'
import { completeReviews, REVIEWS_LOAD } from './review.actions'
import { ReviewService } from './review.service'
import { ReviewPagination } from './review.types'

@Injectable()
export class ReviewEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private reviewService: ReviewService,
  ) { }

  loadReviews$ = createEffect(
    (): Observable<
      | ReturnType<typeof completeReviews>
    > =>
      this.actions$.pipe(
        ofType(REVIEWS_LOAD.REQUEST),
        switchMap((action: PayloadedAction<string>) =>
          from(this.reviewService.getReviews(action.payload, {limit: 5})).pipe(
            map((reviewPagination: ReviewPagination) =>
              completeReviews({payload: reviewPagination})
            )
          )
        )
      )
  )
}
