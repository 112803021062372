import { createFeatureSelector, createSelector, defaultMemoize } from '@ngrx/store'
import { IndexedCollection } from 'src/app/shared/types'
import { selectModeConfiguration } from '../auth/auth.selectors'
import { VendorConfigurationMode } from '../auth/mode-configuration.types'
import { selectMemberActiveVehicle } from '../member/member.selectors'
import { VehicleData } from '../member/member.types'
import { VehicleEditState, VehicleState } from './vehicle.reducer'
import { ModelType, VehicleEditMetadata } from './vehicle.types'
import { isAWD } from './vehicle.utils'

const stateKey = 'vehicle'

export const selectVehicleState = createFeatureSelector<VehicleState>(stateKey)

export const selectVehicleStep = createSelector(
  selectVehicleState,
  (state: VehicleState): string => state.step
)

export const selectIsVehicleYearsStep = createSelector(
  selectVehicleStep,
  (step: string): boolean => step === 'years'
)

export const selectVehicleYears = createSelector(
  selectVehicleState,
  selectModeConfiguration,
  (state: VehicleState, modeConfiguration: VendorConfigurationMode): string[] => {
    const sortedYears = state.years.sort((y1, y2) => Number(y2) - Number(y1))
    if (modeConfiguration && modeConfiguration.numberVehicleYears) {
      return sortedYears.slice(0, modeConfiguration.numberVehicleYears)
    }
    return sortedYears
  }
)

export const selectVehicleMakes = createSelector(
  selectVehicleState,
  selectModeConfiguration,
  (state: VehicleState, modeConfiguration: VendorConfigurationMode): string[] => {
    if(modeConfiguration?.makes?.length) {
      return modeConfiguration.makes
    }
    return state.makes;
  }
)

export const selectVehicleModels = createSelector(
  selectVehicleState,
  (state: VehicleState): string[] => state.models
)

export const selectVehicleModelTypes = createSelector(
  selectVehicleState,
  (state: VehicleState): IndexedCollection<ModelType[]> => state.types
)

export const createGetVehicleTypesByModel = createSelector(
    selectVehicleModelTypes,
    (modelTypes: IndexedCollection<ModelType[]>) => defaultMemoize(
      (slug: string): ModelType[] => modelTypes[slug]
    )
  )

export const selectWorkingVehicle = createSelector(
  selectVehicleState,
  (state: VehicleState): VehicleEditState => state.workingVehicle
)

export const selectEditMetadata = createSelector(
  selectVehicleState,
  (state: VehicleState): VehicleEditMetadata => state.editMetadata
)

export const selectIsVehicleValid = createSelector(
  selectMemberActiveVehicle,
  (activeVehicle: VehicleData): boolean => {
    if (!activeVehicle) {
      return false
    }

    const { year, make, model, color } = activeVehicle

    return Boolean(year) && Boolean(make) && Boolean(model) && Boolean(color)
  }
)

export const selectIsVehicleYearEditing = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.editMetadata?.step === 'years'
)

export const selectVehicleModelDescription = createSelector(
  selectWorkingVehicle,
  workingVehicle => workingVehicle?.driveType
      ? `${workingVehicle.model} (${isAWD(workingVehicle) ? 'AWD/4WD' : workingVehicle.driveType})`
      : workingVehicle?.model
)
