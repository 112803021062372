<div class="splashscreen" [ngClass]="{hidden: hidden}">

  <ng-container *ngIf="!isRapUser">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 92">
      <g fill="#003893" fill-rule="evenodd">
        <path
          d="M0 35.118C0 17.327 22.977 2.8 51.22 2.8c28.403 0 51.38 14.527 51.38 32.318 0 17.956-22.977 32.482-51.38 32.482C22.977 67.6 0 53.074 0 35.118zM29.04 9.982l9.735 33.787L48.986 5.575c-7.18.326-14.041 1.795-19.945 4.407zm19.468 14.037l-5.267 19.75h10.692l-5.425-19.75zm15.158 19.75l9.255-34.114c-6.064-2.448-12.925-3.917-20.106-4.08l10.851 38.194zm-34.785 0l-5.266-19.75-5.265 19.75h10.531zm60.793 3.917c2.553-3.753 3.99-7.997 3.99-12.568 0-8.65-5.266-16.322-13.882-21.872l9.892 34.44zM78.826 43.77L73.4 24.02l-5.265 19.75h10.69zm1.115 3.917H67.017L65.9 51.931l3.031 10.118c4.946-1.468 9.413-3.753 13.084-6.527l-2.075-7.836zM33.828 62.05l-3.83-14.363H17.233l-1.117 4.08c4.468 4.571 10.531 8.162 17.712 10.447v-.164zM55.05 47.686H42.124l-4.148 15.67c4.148.98 8.616 1.469 13.244 1.469 2.872 0 5.585-.163 8.297-.653L55.05 47.686zM21.86 13.9C13.881 19.285 8.935 26.794 8.935 35.118c0 4.57 1.436 8.651 3.83 12.406L21.858 13.9z" />
        <path
          d="M146.894 4.485c-4.3-7.41-26.91-2.578-55.094 10.47 1.433 1.129 2.865 2.417 3.98 3.706 21.656-9.343 38.375-12.244 41.879-6.122 3.98 6.927-11.784 24.163-37.102 41.238l.32.323c30.89-20.297 50.794-41.24 46.017-49.615M12.847 85.572c-2.393-4.341 2.71-12.539 12.916-22.342-.797-.163-1.594-.483-2.392-.805-.16 0-.16-.161-.16-.161l-2.39-.964C8.54 72.71 2.321 82.356 5.191 87.5c3.509 5.947 18.657 4.018 38.908-3.537l-.158-.321c-16.266 5.626-28.226 6.75-31.095 1.93"
          stroke="#003893" />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="isRapUser">
    <app-img
      src="assets/images/{{appIdLogo.fileName}}.svg"
      classNames="{{appIdLogo.className}}"
      alt="{{appIdLogo.alt}}"
      id="{{appIdLogo.className}}"
      width="149"
    ></app-img>
  </ng-container>

  <div class="loading" [ngClass]="{
    'cancelled': step === 0
  }">
    <div class="loading_inner checking" [ngClass]="{
      'done': step === 3 || (stepLoaded$ | async)
    }"></div>
  </div>
</div>
