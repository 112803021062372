import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core'
import { FormControlState, FormGroupControls } from 'ngrx-forms'

@Component({
  selector: 'app-form-error-summary',
  templateUrl: './form-error-summary.component.html',
  styleUrls: ['./form-error-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormErrorSummaryComponent<T> implements OnChanges {
  @Input() controls: FormGroupControls<T>
  hasAnyErrors = false

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['controls']) {
      this.hasAnyErrors = this.findAnyErrors()
    }
  }

  findAnyErrors() {
    return Object.values(this.controls).some(
      (control: FormControlState<any>) => {
        if (!control) {
          return false
        }

        return (
          control.errors &&
          control.isTouched &&
          Object.values(control.errors).length > 0
        )
      }
    )
  }
}
