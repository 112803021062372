<div class="dialog">
  <h1 mat-dialog-title>Roadside Request Cancelled</h1>

  <div mat-dialog-content>
    <p>
      We're sorry if you're having trouble. If you need additional assistance, please call:
    </p>
  </div>
  <div mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      (buttonClick)="onClose()"
      [ariaLabel]="'Contact us'">
    </app-call-aaa-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
