import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AuthState } from './auth.reducer'
import { AgentSettingsParams, AuthMethods } from './auth.types'
import { Eligibility } from './eligibility.types'
import { VendorConfigurationMode } from './mode-configuration.types'

const stateKey = 'auth'

export const selectAuthState = createFeatureSelector<AuthState>(stateKey)

export const selectAccessToken = createSelector(
  selectAuthState,
  (state: AuthState): string => state.accessToken
)

export const selectIsRapTokenAccess = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.isRapTokenAccess
)

export const selectAuthMethod = createSelector(
  selectAuthState,
  (state: AuthState): AuthMethods => state.method
)

export const selectAuthClub = createSelector(
  selectAuthState,
  (state: AuthState): string => state.club
)

export const selectIsSecure = createSelector(
  selectAuthState,
  (state: AuthState): boolean => (state ? state.isSecure : false)
)

export const selectIsSignedIn = createSelector(
  selectAuthState,
  // TODO: Need to handle expiresIn + token renew endpoint strategies.
  (state: AuthState): boolean => Boolean(state.accessToken)
)

// start ARR:POC - The agent selectors
export const selectAgentSettings = createSelector(
  selectAuthState,
  (state: AuthState): AgentSettingsParams => state.agentSettings || {}
)

export const selectIsAgent = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.isAgent
)
// end ARR:POC

export const selectModeConfiguration = createSelector(
  selectAuthState,
  (state: AuthState): VendorConfigurationMode => (state && state.modeConfig ? state.modeConfig : null)
)

export const selectAllowAddVehicle = createSelector(
  selectModeConfiguration,
  (config: VendorConfigurationMode): boolean => (config ? config.allowAddVehicle !== false : true)
)

export const selectAllowEditVehicle = createSelector(
  selectModeConfiguration,
  (config: VendorConfigurationMode): boolean => (config ? config.allowEditVehicle !== false : true)
)

export const selectIsVehicleChangeAllowed = createSelector(
  selectAllowAddVehicle,
  selectAllowEditVehicle,
  (allowAdd: boolean, allowEdit: boolean): boolean => allowAdd || allowEdit
)

export const selectCallCenterTowMileage = createSelector(
  selectModeConfiguration,
  (config: VendorConfigurationMode): number => (config && config.callCenterTowMileage ? config.callCenterTowMileage : null)
)

export const selectEligibility = createSelector(
  selectAuthState,
  (state: AuthState): Eligibility => (state ? state.eligibility : null)
)

export const selectIsRapEligible = createSelector(
  selectEligibility,
  (eligibility: Eligibility): boolean => eligibility ? eligibility.eligible : false
)

export const selectMaxTowMileLimit = createSelector(
  selectEligibility,
  (eligibility: Eligibility): number => {
    const KEY = 'MAX_TOW_MILE_LIMIT'
    const tows = eligibility?.entitlement?.filter(e => e.type === 'TOW')
      .filter(t => t.policyInformation?.map(p => p.key).includes(KEY))
    if (tows && tows.length) {
      const policies = tows[0].policyInformation?.filter(p => p.key === KEY)
      if(policies && policies.length) {
        const value = Number(policies[0].value)
        return isNaN(value) ? 0 : value
      }
    }
    return 0
  }
)

/**
 * Select button call text.
 *
 * @returns 'call' when RAP user and 'CALL AAA' when member
 */
export const selectCallText = createSelector(
  selectAuthState,
  (state: AuthState): string => {
    if (state?.modeConfig) {
      return 'CALL'
    }

    return 'CALL AAA'
  }
)

export const selectIsRapUser = createSelector(
  selectModeConfiguration,
  (config: VendorConfigurationMode): boolean => !!config
)

export const selectMobileHeaderText = createSelector(
  selectIsRapUser,
  (isRapUser: boolean): string => {
    if (isRapUser) {
      return 'Please enter your details to get started with our 24 hr road service.'
    }

    return 'Please enter your AAA details to get started with our 24 hr road service.'
  }
)

export const selectAuthErrorCount = createSelector(
  selectAuthState,
  (state: AuthState): number => state.errorCount
)

export const selectIsUseAnotherFormClickedAfterError = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.isUseAnotherFormClickedAfterError
)

export const selectLastSearchId = createSelector(
  selectAuthState,
  (state: AuthState): string => state.lastSearchId
)
