import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from '../../../tagging/tagging.service'
import events from 'src/app/modules/tagging/events'

@Component({
  selector: 'tow-distance-warning-dialog-component',
  templateUrl: './tow-distance-warning-dialog.component.html',
  styleUrls: ['./tow-distance-warning-dialog.component.scss'],
})
export class TowDistanceWarningDialogComponent {
  pageType: String = events.towTo.DESTINATION_PAGE_TYPE

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private tagging: TaggingService
  ) {}

  onSubmit() {
    this.onClose()
  }

  onClose() {
    this.data.close()
  }

  onCallAAAClick() {
    this.tagging.setClickEvent(events.towTo.DESTINATION_DISTANCE_LIMIT, this.pageType)
  }
}
