import { Component, ViewEncapsulation, Input } from '@angular/core'

@Component({
  styleUrls: ['./step.component.scss'],
  templateUrl: './step.component.html',
  selector: 'app-step',
  encapsulation: ViewEncapsulation.None,
})
export class StepComponent {
  @Input() stepId: string
  @Input() stepTitle: string
  @Input() stepStatus: string
  @Input() stepUrl: string
  @Input() tabIndex = 0

  constructor() {}
}
