import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { select, Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { selectIsEVstation } from 'src/app/modules/location/aar/aar.selectors'
import events from 'src/app/modules/tagging/events'
import { AbstractComponent } from 'src/app/shared/abstract.component'
import { RAP_EV_TOW_LIMITATION_STRICT, RAP_TOW_LIMITATION_STRICT } from 'src/app/shared/utils/messages'
import { AAAStore } from 'src/app/store/root-reducer'
import { DialogData } from '../../ui.types'

@Component({
  selector: 'rap-tow-mileage-message-dialog-component',
  templateUrl: './rap-tow-mileage-message-dialog.component.html',
  styleUrls: ['./rap-tow-mileage-message-dialog.component.scss'],
})
export class RapTowMileageMessageDialogComponent extends AbstractComponent {
  pageType = events.towTo.DESTINATION_PAGE_TYPE
  message: string

  isEvStationSubscription: Subscription = this.store$.pipe(
    select(selectIsEVstation)
  ).subscribe((isEvStation) => {
    this.message = isEvStation ? RAP_EV_TOW_LIMITATION_STRICT : RAP_TOW_LIMITATION_STRICT
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
  ) {
    super()
  }

  ngOnInit(): void {
    this.addSubscription(this.isEvStationSubscription)
  }

  onClose() {
    this.data.close()
  }
}
